import React, { useState, useEffect } from 'react';
import { PulseLoader } from 'react-spinners';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from 'reactstrap';
import find from 'lodash/find';
import { FaPen, FaCalendar, FaPlus } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import Translate from '../../Locale/Translate';

import { datesAreSameDay, STANDARD_DATE_FORMAT } from '../../utils/dateUtils';

const AntallKontroller = (props) => {
  const {
    dato,
    kontroller,
    getKontroller,
    postKontroller,
    updateKontroller,
    dateChange,
    loading,
  } = props;

  const [open, setOpen] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [kontrollerInput, setKontrollerInput] = useState(null);
  const [valgtKontroller, setValgtKontroller] = useState(null);

  const toggle = () => {
    setOpen(!open);
    dateChange(new Date());
  };

  const onSave = () => {
    if (invalidDate || invalidNumber) {
      return null;
    }
    setOpen(false);
    if (valgtKontroller && valgtKontroller.id)
      return updateKontroller(dato, kontrollerInput, valgtKontroller.id);
    return postKontroller(dato, kontrollerInput);
  };

  useEffect(() => {
    getKontroller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const i = find(kontroller, (k) => datesAreSameDay(k.dato, dato));
    setInvalidDate(false);
    setInvalidNumber(false);
    setValgtKontroller(i);
    setKontrollerInput(i ? i.antallKontrollpunkter : '');
  }, [dato, kontroller]);

  return (
    <>
      <Button
        color="secondary"
        className="kontrollpanel-btn kontrollpanel-btn-kontroller"
        onClick={toggle}
        disabled={loading}
      >
        <FaPlus className="antall-kontroller-plus-icon" />
        <Translate
          id="antallKontroller.toggleBtn"
          defaultMessage="Angi antall kontroller"
        />
      </Button>
      <Modal isOpen={open} className="antall-kontroller">
        <ModalHeader toggle={toggle}>
          <Translate
            id="antallKontroller.header"
            defaultMessage="Antall kontroller"
          />
        </ModalHeader>
        <ModalBody className="antall-kontroller-body">
          <div className="antall-kontroller-body-form">
            <FormGroup className="antall-kontroller-kalender">
              <Datetime
                inputProps={{ id: 'antall-kontoller-dato' }}
                locale="nb"
                dateFormat={STANDARD_DATE_FORMAT}
                timeFormat={false}
                value={dato}
                disabled={loading}
                onChange={(e) => {
                  if (typeof e === 'string') {
                    setInvalidDate(true);
                  } else dateChange(e);
                }}
                className={`${invalidDate && ' invalid'}`}
                closeOnSelect
                renderInput={(p) => {
                  return (
                    <>
                      <InputGroup className="antall-kontroller-kalender">
                        <Label htmlFor="antall-kontoller-dato">
                          <Translate
                            id="antallKontroller.date"
                            defaultMessage="Dato"
                          />
                        </Label>
                        <Input {...p} />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <FaCalendar />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </>
                  );
                }}
              />

              {invalidDate && (
                <div className="invalid-datetime-feedback">
                  <Translate
                    id="antallKontroller.dateValidation"
                    defaultMessage="Dato må være i formatet {format}"
                    values={{ format: STANDARD_DATE_FORMAT }}
                  />
                </div>
              )}
            </FormGroup>
            <FormGroup className="antall-kontroller-antall">
              <InputGroup>
                <Label htmlFor="antall-kontoller">
                  <Translate
                    id="antallKontroller.number"
                    defaultMessage="Dato"
                  />
                </Label>
                <Translate
                  id="antallKontroller.numberPlaceholde"
                  defaultMessage="stk."
                >
                  {(msg) => (
                    <Input
                      id="antall-kontoller"
                      type="number"
                      value={kontrollerInput}
                      className={`${invalidDate && ' invalid'}`}
                      placeholder={msg}
                      onChange={(e) => {
                        setKontrollerInput(e.target.value);
                        if (
                          e.target.value === null ||
                          e.target.value === '' ||
                          Number(e.target.value).isNaN ||
                          Number(e.target.value) < 0
                        ) {
                          setInvalidNumber(true);
                        } else {
                          setInvalidNumber(false);
                        }
                      }}
                      disabled={loading}
                    />
                  )}
                </Translate>
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FaPen />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {invalidNumber && (
                <div className="invalid-datetime-feedback">
                  <Translate
                    id="antallKontroller.numberValidation"
                    defaultMessage="Antall kontroller må være et gyldig tall"
                  />
                </div>
              )}
            </FormGroup>
          </div>
          {loading && <PulseLoader />}
        </ModalBody>
        <ModalFooter className="antall-kontroller-footer">
          <Button
            color="primary"
            onClick={onSave}
            disabled={loading || invalidDate || invalidNumber}
          >
            <Translate id="antallKontroller.save" defaultMessage="Lagre" />
          </Button>
          <Button color="secondary" onClick={toggle} disabled={loading}>
            <Translate id="antallKontroller.cancel" defaultMessage="Avbryt" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

AntallKontroller.propTypes = {
  dato: PropTypes.shape({}),
  kontroller: PropTypes.arrayOf(PropTypes.shape({})),
  getKontroller: PropTypes.func.isRequired,
  postKontroller: PropTypes.func.isRequired,
  updateKontroller: PropTypes.func.isRequired,
  dateChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

AntallKontroller.defaultProps = {
  kontroller: [],
  dato: new Date(),
};

export default AntallKontroller;
