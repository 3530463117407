import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import BymMap from '../Common/Map/BymMap';

import './_mapInput.scss';

const MapInput = ({ value, onClick }) => {
  const marker = isEmpty(value)
    ? null
    : { position: value, showTooltip: false, title: 'Valgt posisjon' };

  return (
    <div className="enk-map-input-container" id="enk-map-input-container">
      <BymMap
        selectedPosition={marker}
        onClick={onClick}
        useMarkerCluster={false}
        laster={false}
        defaultZoom={16}
        showTooltip={false}
        scrollWheelZoom
        zoomControl
      />
    </div>
  );
};

MapInput.propTypes = {
  value: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
};

MapInput.defaultProps = {
  value: {},
};

export default MapInput;
