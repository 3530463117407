import moment from 'moment';
import momentTimezone from 'moment-timezone';

import locale_nb from 'moment/locale/nb'; // eslint-disable-line

export const STANDARD_DATE_FORMAT = 'DD.MM.YYYY';
export const STANDARD_TIME_FORMAT = 'HH:mm';

export const STANDARD_DATETIME_FORMAT = `${STANDARD_DATE_FORMAT}_${STANDARD_TIME_FORMAT}`;

export const setDefaultTimeZone = (timeZone = 'Europe/Oslo') =>
  moment.tz.setDefault(timeZone);

export const isDateInFuture = (date) =>
  moment(date).isAfter(moment(), 'minute');

export const datesAreSameDay = (d1, d2) => moment(d1).isSame(moment(d2), 'day');

export const today = () => moment();

export const yesterday = () => moment().subtract(1, 'day');

export const getLocalMachineTime = () => moment().format(); // Returns Time string formated in ISO8601

export const getNorwegianLocalTime = (timeString = '') =>
  momentTimezone.tz(
    !timeString ? getLocalMachineTime() : timeString,
    'Europe/Oslo'
  ); // Retruns moment object with timezone Oslo and correct DTS

export const getFormattedLongDate = (dato) => {
  const format = moment.ISO_8601;
  moment.updateLocale('nb', locale_nb);
  return moment(dato, format).format('dddd D. MMMM YY');
};

export const getFormattedDateTime = (dato) => {
  const format = moment.ISO_8601;
  moment.updateLocale('nb', locale_nb);
  return moment(dato, format).format('D. MMMM YYYY HH:mm');
};

export const getFormattedDate = (dato) => {
  const format = moment.ISO_8601;
  moment.updateLocale('nb', locale_nb);
  return moment(dato, format).format('DD.MM.YYYY');
};

export const getFormattedTime = (dato) => {
  const format = moment.ISO_8601;
  moment.updateLocale('nb', locale_nb);
  return moment(dato, format).format('HH:mm');
};

export const getFilterFormattedDateTime = (dt) =>
  moment(dt).format('YYYY-MM-DD HH:mm');

export const getFilterFormattedDate = (dt) => moment(dt).format('YYYY-MM-DD');

export const getStandardFilterFormattedDateTime = (dt) =>
  moment(dt).format(STANDARD_DATETIME_FORMAT);

export const getToday = () => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
};

export const getYesterday = () => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() - 1);
  return d;
};

export const getJanuary1stThisYear = () =>
  new Date(new Date().getFullYear(), 0, 1);

export const getDecember31stThisYear = () =>
  new Date(new Date().getFullYear(), 11, 31, 23, 59, 59);

export const getStartDay = (dt) => moment(dt).startOf('day').format();

export const getEndDay = (dt) => moment(dt).endOf('day').format();
