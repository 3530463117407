/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import './StatistikkPage.scss';

import moment from 'moment';
import DiagramContainer from './DiagramContainer';
import httpClient from '../../api/httpClient';
import {
  getToday,
  getYesterday,
  getFilterFormattedDateTime,
  getJanuary1stThisYear,
  getDecember31stThisYear,
} from '../../utils/dateUtils';

import { getUtsyrsloggNavnKey } from '../../utils/sporUtils';
import Linjediagram from './Linjediagram';
import StolpediagramVertikalt from './StolpediagramVertikalt';
import Tabelldiagram from './Tabelldiagram';
import Gruppevelger from './Gruppevelger';
import Filter from './Filter';
import { LocaleContext } from '../../Locale/LocaleProvider';

const StatistikkPage = ({ ...props }) => {
  const { statiskeData, history } = props;
  const localeContext = useContext(LocaleContext);

  const [synligGruppe, setSynligGruppe] = useState();

  const getEmptyFilter = () => {
    return {
      isOmradeValgt: false,
      seksjoner: [],
      kategorier: [],
      omrader: [],
      arbeidstyper: [],
    };
  };
  const [globaltFilter, setGlobaltFilter] = useState(getEmptyFilter());

  const [undertittelSeksjon, setUndertittelSeksjon] = useState();
  const [undertittelKategori, setUndertittelKategori] = useState();
  const [undertittelOmrade, setUndertittelOmrade] = useState();
  const [undertittelArbeidstype, setUndertittelArbeidstype] = useState();

  const [bydelStatistikkdata, setBydelStatistikkdata] = useState({});
  const [avvikStatistikkdata, setAvvikStatistikkdata] = useState({});
  const [
    avvikPrEntreprenorStatistikkdata,
    setAvvikPrEntreprenorStatistikkdata,
  ] = useState({});
  const [tiltakStatistikkdata, setTiltakStatistikkdata] = useState({});
  const [avviksfeiltyperStatistikkdata, setAvviksfeiltyperStatistikkdata] =
    useState({});
  const [
    avviksfeiltyperPrEntreprenorStatistikkdata,
    setAvviksfeiltyperPrEntreprenorStatistikkdata,
  ] = useState({});

  const [ressursbrukStatistikkdata, setRessursbrukStatistikkdata] = useState(
    {}
  );
  const [maskinerIDriftStatistikkdata, setMaskinerIDriftStatistikkdata] =
    useState({});
  const [kontrollerPrMndStatistikkdata, setKontrollerPrMndStatistikkdata] =
    useState({});

  const [avvikPrBydelFraDato, setAvvikPrBydelFraDato] = useState(
    getJanuary1stThisYear()
  );
  const [avvikPrBydelTilDato, setAvvikPrBydelTilDato] = useState(
    getDecember31stThisYear()
  );
  const [
    avviksfeiltyperPrEntreprenorFraDato,
    setAvviksfeiltyperPrEntreprenorFraDato,
  ] = useState(getJanuary1stThisYear());
  const [
    avviksfeiltyperPrEntreprenorTilDato,
    setAvviksfeiltyperPrEntreprenorTilDato,
  ] = useState(getDecember31stThisYear());

  const [avvikPrEntreprenorFraDato, setAvvikPrEntreprenorFraDato] = useState(
    getJanuary1stThisYear()
  );
  const [avvikPrEntreprenorTilDato, setAvvikPrEntreprenorTilDato] = useState(
    getDecember31stThisYear()
  );
  const [avvikFraDato, setAvvikFraDato] = useState(getJanuary1stThisYear());
  const [avvikTilDato, setAvvikTilDato] = useState(getDecember31stThisYear());
  const [tiltakFraDato, setTiltakFraDato] = useState(getJanuary1stThisYear());
  const [tiltakTilDato, setTiltakTilDato] = useState(getDecember31stThisYear());
  const [avviksfeiltyperFraDato, setAvviksfeiltyperFraDato] = useState(
    getJanuary1stThisYear()
  );
  const [avviksfeiltyperTilDato, setAvviksfeiltyperTilDato] = useState(
    getDecember31stThisYear()
  );

  const [ressursbrukFraDato, setRessursbrukFraDato] = useState(
    getJanuary1stThisYear()
  );
  const [ressursbrukTilDato, setRessursbrukTilDato] = useState(
    getDecember31stThisYear()
  );

  const [maskinerIDriftFraDato, setMaskinerIDriftFraDato] = useState(
    getYesterday()
  );
  const [maskinerIDriftTilDato, setMaskinerIDriftTilDato] = useState(
    getToday()
  );

  const [kontrollerPrMndFraDato, setKontrollerPrMndFraDato] = useState(
    getJanuary1stThisYear()
  );
  const [kontrollerPrMndTilDato, setKontrollerPrMndTilDato] = useState(
    getDecember31stThisYear()
  );

  useEffect(() => {
    console.log(`history: ${history.location.pathname}`);
    if (history.location.pathname.endsWith('/statistikk')) return;

    const preselectedGruppe = history.location.pathname.replace(
      '/statistikk/',
      ''
    );
    if (!preselectedGruppe) return;

    onGruppeChanged(preselectedGruppe);
  });

  const getFilterFromParamOrState = (paramFilter) => {
    return {
      seksjonId: paramFilter ? paramFilter.seksjoner : globaltFilter.seksjoner,
      kategoriId: paramFilter
        ? paramFilter.kategorier
        : globaltFilter.kategorier,
      omrade: paramFilter ? paramFilter.omrader : globaltFilter.omrader,
      arbeidstype: paramFilter
        ? paramFilter.arbeidstyper
        : globaltFilter.arbeidstyper,
    };
  };

  const konverterGuidTilFeiltype = (id) => {
    return localeContext.currentLocale.messages[`feiltype.${id}`] || id;
  };
  const konverterTabelldataGuidTilFeiltype = (tabelldata) => {
    return tabelldata.map((rad) => {
      const newRad = {};
      Object.keys(rad).forEach((key) => {
        if (key === 'bydel') {
          newRad[key] = rad[key];
          return;
        }

        const newKey = konverterGuidTilFeiltype(key);
        newRad[newKey] = rad[key];
      });

      return newRad;
    });
  };

  const konverterStatistikkdataFeiltypeGuidTilTekst = (
    data,
    ignoreKolonnenavn
  ) => {
    if (!data) return data;

    if (data.statistikk && data.statistikk.typer) {
      data.statistikk.typer.forEach((element, index, array) => {
        if (element.toLowerCase() !== ignoreKolonnenavn.toLowerCase()) {
          array[index] = konverterGuidTilFeiltype(element);
        }
      });

      data.statistikk.elementer = konverterTabelldataGuidTilFeiltype(
        data.statistikk.elementer
      );
    }

    if (data.tabell && data.tabell.kolonner) {
      data.tabell.kolonner.forEach((element, index, array) => {
        if (element.toLowerCase() !== ignoreKolonnenavn.toLowerCase()) {
          array[index] = konverterGuidTilFeiltype(element);
        }
      });

      data.tabell.tabelldata = konverterTabelldataGuidTilFeiltype(
        data.tabell.tabelldata
      );
    }

    return data;
  };

  const konverterStatistikkdataArbeidstypeGuidTilTekst = (
    data,
    ignoreKolonnenavn
  ) => {
    if (!data) return data;

    if (data.tabell && data.tabell.kolonner) {
      data.tabell.kolonner.forEach((element, index, array) => {
        const utstyrsloggKey = getUtsyrsloggNavnKey(element);
        if (element.toLowerCase() !== ignoreKolonnenavn.toLowerCase())
          array[index] =
            localeContext.currentLocale.messages[utstyrsloggKey] ||
            utstyrsloggKey;
      });

      data.tabell.tabelldata = data.tabell.tabelldata.map((rad) => {
        const newRad = {};
        Object.keys(rad).forEach((key) => {
          const utstyrsloggKey = getUtsyrsloggNavnKey(key);
          const newKey =
            key.toLowerCase() !== ignoreKolonnenavn.toLowerCase()
              ? localeContext.currentLocale.messages[utstyrsloggKey] ||
                utstyrsloggKey
              : key;
          newRad[newKey] = rad[key];
        });

        return newRad;
      });
    }
    return data;
  };

  const resetStatistikkdata = () => {
    setBydelStatistikkdata({});
    setAvvikPrEntreprenorStatistikkdata({});
    setAvvikStatistikkdata({});
    setTiltakStatistikkdata({});
    setAvviksfeiltyperStatistikkdata({});
    setAvviksfeiltyperPrEntreprenorStatistikkdata({});
    setRessursbrukStatistikkdata({});
    setMaskinerIDriftStatistikkdata({});
    setKontrollerPrMndStatistikkdata({});
  };

  const loadStatistikkAvvikPrBydel = (fra, til, filter) => {
    if (!erSynlig('avvik')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    setBydelStatistikkdata({ loading: true });
    httpClient
      .getStatistikkAvvikPrBydel(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(filter)
      )
      .then((data) => {
        setBydelStatistikkdata(data);
      });
  };

  const loadStatistikkAvvikPrEntreprenor = (fra, til, filter) => {
    if (!erSynlig('avvik')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    setAvvikPrEntreprenorStatistikkdata({ loading: true });
    httpClient
      .getStatistikkAvvikPrEntreprenor(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(filter)
      )
      .then((data) => {
        setAvvikPrEntreprenorStatistikkdata(data);
      });
  };

  const loadStatistikkAvvik = (fra, til, filter) => {
    if (!erSynlig('avvik')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    setAvvikStatistikkdata({ loading: true });
    httpClient
      .getStatistikkAvvik(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(filter)
      )
      .then((data) => {
        setAvvikStatistikkdata(data);
      });
  };

  const loadStatistikkTiltakPrEntreprenor = (fra, til, filter) => {
    if (!erSynlig('drift')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    setTiltakStatistikkdata({ loading: true });
    httpClient
      .getStatistikkTiltakPrEntreprenor(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(filter)
      )
      .then((data) => {
        setTiltakStatistikkdata(data);
      });
  };

  const loadStatistikkAvviksfeiltypePrBydel = (fra, til, filter) => {
    if (!erSynlig('avvik')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    setAvviksfeiltyperStatistikkdata({ loading: true });
    httpClient
      .getStatistikkAvviksfeiltypePrBydel(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(filter)
      )
      .then((data) => {
        // oversett feiltyper fra GUID til tekst
        setAvviksfeiltyperStatistikkdata(
          konverterStatistikkdataFeiltypeGuidTilTekst(data, 'Bydel')
        );
      });
  };

  const loadStatistikkAvviksfeiltypePrEntreprenor = (fra, til, filter) => {
    if (!erSynlig('avvik')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    setAvviksfeiltyperPrEntreprenorStatistikkdata({ loading: true });
    httpClient
      .getStatistikkAvviksfeiltypePrEntreprenor(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(filter)
      )
      .then((data) => {
        // oversett feiltyper fra GUID til tekst
        setAvviksfeiltyperPrEntreprenorStatistikkdata(
          konverterStatistikkdataFeiltypeGuidTilTekst(data, 'Entreprenør')
        );
      });
  };

  const loadStatistikkRessursbrukPrMateriale = (fra, til, filter) => {
    if (!erSynlig('drift')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    setRessursbrukStatistikkdata({ loading: true });
    httpClient
      .getStatistikkRessursbrukPrMateriale(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(filter)
      )
      .then((data) => {
        setRessursbrukStatistikkdata(data);
      });
  };

  const loadStatistikkMaskinerIfDriftPrBydel = (fra, til, filter) => {
    if (!erSynlig('drift')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    const currentFilter = filter || globaltFilter;

    setMaskinerIDriftStatistikkdata({ loading: true });
    httpClient
      .getStatistikkMaskinerIDriftPrBydel(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(currentFilter)
      )
      .then((data) => {
        // oversett arbeidstyper fra GUID til tekst
        setMaskinerIDriftStatistikkdata(
          konverterStatistikkdataArbeidstypeGuidTilTekst(data, 'Område')
        );
      });
  };

  const loadStatistikkKontrollerPrMnd = (fra, til, filter) => {
    if (!erSynlig('kontroller')) return;
    if (filter && !areMandatoryFiltersSet(filter)) return;

    setKontrollerPrMndStatistikkdata({ loading: true });
    httpClient
      .getStatistikkKontrollerPrMnd(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        getFilterFromParamOrState(filter)
      )
      .then((data) => {
        setKontrollerPrMndStatistikkdata(data);
      });
  };

  const eksportToCsv = (filnavn, data) => {
    const json2csvParser = new Parser({ fields: data.typer, delimiter: ';' });
    const csv = json2csvParser.parse(data.tabell.tabelldata);
    const csvBlob = new Blob(['\ufeff', `${csv}`], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(csvBlob, filnavn);
  };

  const areMandatoryFiltersSet = (filter) => {
    return (
      filter &&
      filter.kategorier.length > 0 &&
      (filter.omrader.length > 0 || filter.isOmradeValgt)
    );
  };

  const onGruppeChanged = (gruppe) => {
    if (gruppe === synligGruppe) return;

    setSynligGruppe(gruppe);
    setGlobaltFilter(getEmptyFilter());
    resetStatistikkdata();
    history.push(`/statistikk/${gruppe}`);

    // eslint-disable-next-line no-use-before-define
    loadAllStatistikkdata(getEmptyFilter());
  };

  const settGlobalDato = (fra, til) => {
    setAvvikFraDato(fra);
    setAvvikTilDato(til);
    setAvvikPrBydelFraDato(fra);
    setAvvikPrBydelTilDato(til);
    setAvvikPrEntreprenorFraDato(fra);
    setAvvikPrEntreprenorTilDato(til);
    setAvviksfeiltyperFraDato(fra);
    setAvviksfeiltyperTilDato(til);
    setAvviksfeiltyperPrEntreprenorFraDato(fra);
    setAvviksfeiltyperPrEntreprenorTilDato(til);
    setKontrollerPrMndFraDato(fra);
    setKontrollerPrMndTilDato(til);
    setRessursbrukFraDato(fra);
    setRessursbrukTilDato(til);
    setTiltakFraDato(fra);
    setTiltakTilDato(til);
    setMaskinerIDriftFraDato(fra);
    setMaskinerIDriftTilDato(moment(fra).add(1, 'day'));
  };

  const onFilterChanged = (updatedFilter) => {
    if (updatedFilter.fraDato && updatedFilter.tilDato) {
      settGlobalDato(updatedFilter.fraDato, updatedFilter.tilDato);
    }

    setGlobaltFilter(updatedFilter);

    // generer undertitler for diagrammene
    setUndertittelSeksjon(
      updatedFilter.seksjoner.map((item) => item.navn).join(', ') // 'vei, park'
    );
    setUndertittelKategori(
      updatedFilter.kategorier.map((item) => item.navn).join(', ') // 'brøyting, snødeponi'
    );
    setUndertittelOmrade(
      updatedFilter.omrader.map((item) => item.navn).join(', ') // 'Nord, Sentrum'
    );
    setUndertittelArbeidstype(
      updatedFilter.arbeidstyper.map((item) => item.navn).join(', ') // 'Strøing og salting'
    );

    // eslint-disable-next-line no-use-before-define
    loadAllStatistikkdata(updatedFilter);
  };

  const loadAllStatistikkdata = (updatedFilter) => {
    const filter = updatedFilter != null ? updatedFilter : globaltFilter;

    console.log(JSON.stringify(filter, null, 2));

    loadStatistikkAvvikPrBydel(
      (updatedFilter && updatedFilter.fraDato) || avvikPrBydelFraDato,
      (updatedFilter && updatedFilter.tilDato) || avvikPrBydelTilDato,
      filter
    );
    loadStatistikkAvvikPrEntreprenor(
      (updatedFilter && updatedFilter.fraDato) || avvikPrEntreprenorFraDato,
      (updatedFilter && updatedFilter.tilDato) || avvikPrEntreprenorTilDato,
      filter
    );
    loadStatistikkAvvik(
      (updatedFilter && updatedFilter.fraDato) || avvikFraDato,
      (updatedFilter && updatedFilter.tilDato) || avvikTilDato,
      filter
    );
    loadStatistikkTiltakPrEntreprenor(
      (updatedFilter && updatedFilter.fraDato) || tiltakFraDato,
      (updatedFilter && updatedFilter.tilDato) || tiltakTilDato,
      filter
    );
    loadStatistikkAvviksfeiltypePrBydel(
      (updatedFilter && updatedFilter.fraDato) || avviksfeiltyperFraDato,
      (updatedFilter && updatedFilter.tilDato) || avviksfeiltyperTilDato,
      filter
    );
    loadStatistikkAvviksfeiltypePrEntreprenor(
      (updatedFilter && updatedFilter.fraDato) ||
        avviksfeiltyperPrEntreprenorFraDato,
      (updatedFilter && updatedFilter.tilDato) ||
        avviksfeiltyperPrEntreprenorTilDato,
      filter
    );
    loadStatistikkRessursbrukPrMateriale(
      (updatedFilter && updatedFilter.fraDato) || ressursbrukFraDato,
      (updatedFilter && updatedFilter.tilDato) || ressursbrukTilDato,
      filter
    );
    loadStatistikkMaskinerIfDriftPrBydel(
      (updatedFilter && updatedFilter.fraDato) || maskinerIDriftFraDato,
      (updatedFilter &&
        updatedFilter.fraDato &&
        moment(updatedFilter.fraDato).add(1, 'day')) ||
        maskinerIDriftTilDato,
      filter
    );
    loadStatistikkKontrollerPrMnd(
      (updatedFilter && updatedFilter.fraDato) || kontrollerPrMndFraDato,
      (updatedFilter && updatedFilter.tilDato) || kontrollerPrMndTilDato,
      filter
    );
  };

  const getUndertittel = (
    postfix,
    inklSeksjon = true,
    inklKategori = true,
    inklOmrade = true,
    inklArbeidstype = false
  ) => {
    let undertittel = '';
    if (inklSeksjon)
      undertittel +=
        undertittelSeksjon ||
        localeContext.currentLocale.messages['statistikk.header.seksjon.vei']; // inntil flere valg åpnes for seksjon
    if (inklKategori)
      undertittel += ` / ${
        undertittelKategori ||
        localeContext.currentLocale.messages[
          'statistikk.header.filter.kategorier.alle-elementer'
        ]
      }`;
    if (inklOmrade)
      undertittel += ` / ${
        undertittelOmrade ||
        localeContext.currentLocale.messages[
          'statistikk.header.filter.omrader.alle-elementer'
        ]
      }`;
    if (inklArbeidstype)
      undertittel += ` / ${
        undertittelArbeidstype ||
        localeContext.currentLocale.messages[
          'statistikk.header.filter.type-arbeid.alle-elementer'
        ]
      }`;

    return `${undertittel} / ${postfix}`;
  };

  const erSynlig = (diagramtype) => {
    return (
      !synligGruppe || synligGruppe === null || synligGruppe === diagramtype
    );
  };

  const getPakrevdFilterbeskrivelse = (tillatKunEttOmrade) => {
    const beskrivelse = [];
    if (globaltFilter.kategorier.length === 0)
      beskrivelse.push(
        localeContext.currentLocale.messages[
          'statistikk.header.filter.kategorier.tittel'
        ]
      );
    if (globaltFilter.omrader.length === 0)
      beskrivelse.push(
        localeContext.currentLocale.messages[
          'statistikk.header.filter.omrader.tittel'
        ]
      );
    if (
      tillatKunEttOmrade &&
      globaltFilter.omrader.length > 0 &&
      globaltFilter.omrader[0].id === '00000000-0000-0000-0000-000000000000'
    )
      beskrivelse.push(
        localeContext.currentLocale.messages[
          'diagram-container.ett-enkelt-omrade'
        ]
      );

    if (beskrivelse.length > 0)
      return `${
        localeContext.currentLocale.messages[
          'diagram-container.gjenstaende-filtervalg'
        ]
      } ${beskrivelse.join(', ')}`;

    return localeContext.currentLocale.messages['diagram-container.ingen-data'];
  };

  return (
    <div className="statistikkPage-wrapper">
      <Row className="statistikkPage-header">
        <Col md={{ size: 4 }}>
          <h1>
            Statistikk over
            <br />
            Drift & vedlikehold
          </h1>
        </Col>
        <Col className="statistikkPage-filter" md={{ size: 8 }}>
          <Gruppevelger
            gruppe={synligGruppe}
            onGruppeChange={onGruppeChanged}
          />
          <Filter
            statiskeData={statiskeData}
            valgtGruppe={synligGruppe}
            filter={globaltFilter}
            onFilterChanged={onFilterChanged}
          />
        </Col>
      </Row>
      <Row className="statistikkPage-charts">
        {erSynlig('drift') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={maskinerIDriftStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.antall-aktive-maskiner.tittel'
                ]
              }
              undertittel={getUndertittel(
                localeContext.currentLocale.messages[
                  'diagram-container.antall-aktive-maskiner.valg-postfix'
                ],
                true,
                false,
                true,
                true
              )}
              periodevalgformat="24t"
              datoFra={maskinerIDriftFraDato}
              onDatoFra={setMaskinerIDriftFraDato}
              onDatoTil={setMaskinerIDriftTilDato}
              onRefreshData={loadStatistikkMaskinerIfDriftPrBydel}
              onCsvExport={(filnavn) =>
                eksportToCsv(filnavn, maskinerIDriftStatistikkdata)
              }
              diagramtype="linje"
              grafdiagram={
                <Linjediagram
                  xAxisLabel={
                    localeContext.currentLocale.messages[
                      'statistikk.axis-labels.tid'
                    ]
                  }
                  yAxisLabel={
                    localeContext.currentLocale.messages[
                      'statistikk.axis-labels.antall'
                    ]
                  }
                  tooltipFormat="24h"
                  tooltipVerdiPostfix="stk"
                  data={
                    maskinerIDriftStatistikkdata &&
                    maskinerIDriftStatistikkdata.statistikk
                  }
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={
                    maskinerIDriftStatistikkdata &&
                    maskinerIDriftStatistikkdata.tabell
                  }
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
        {erSynlig('avvik') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={bydelStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.antall-avvik-pr-bydel.tittel'
                ]
              }
              undertittel={getUndertittel(
                localeContext.currentLocale.messages[
                  'diagram-container.antall-avvik-pr-bydel.valg-postfix'
                ]
              )}
              datoFra={avvikPrBydelFraDato}
              datoTil={avvikPrBydelTilDato}
              onDatoFra={setAvvikPrBydelFraDato}
              onDatoTil={setAvvikPrBydelTilDato}
              onRefreshData={loadStatistikkAvvikPrBydel}
              onCsvExport={(filnavn) =>
                eksportToCsv(filnavn, bydelStatistikkdata)
              }
              grafdiagram={
                <StolpediagramVertikalt
                  skjulteTyper={[
                    'Totalt ant. avvik',
                    'Alvorlige avvik prosent',
                  ]}
                  tooltipVerdiPostfix="stk"
                  data={bydelStatistikkdata.statistikk}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={bydelStatistikkdata.tabell}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
        {erSynlig('drift') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={tiltakStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.antall-km-utfort-tiltak.tittel'
                ]
              }
              undertittel={getUndertittel('Km', true, false, true, true)}
              datoFra={tiltakFraDato}
              datoTil={tiltakTilDato}
              onDatoFra={setTiltakFraDato}
              onDatoTil={setTiltakTilDato}
              onRefreshData={loadStatistikkTiltakPrEntreprenor}
              onCsvExport={(filnavn) =>
                eksportToCsv(filnavn, tiltakStatistikkdata)
              }
              grafdiagram={
                <StolpediagramVertikalt
                  tooltipVerdiPostfix="km"
                  data={tiltakStatistikkdata.statistikk}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={tiltakStatistikkdata.tabell}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
        {erSynlig('avvik') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={avvikPrEntreprenorStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.antall-avvik-pr-entreprenor.tittel'
                ]
              }
              undertittel={getUndertittel(
                localeContext.currentLocale.messages[
                  'diagram-container.antall-avvik-pr-entreprenor.valg-postfix'
                ]
              )}
              datoFra={avvikPrEntreprenorFraDato}
              datoTil={avvikPrEntreprenorTilDato}
              onDatoFra={setAvvikPrEntreprenorFraDato}
              onDatoTil={setAvvikPrEntreprenorTilDato}
              onRefreshData={loadStatistikkAvvikPrEntreprenor}
              onCsvExport={(filnavn) =>
                eksportToCsv(filnavn, avvikPrEntreprenorStatistikkdata)
              }
              grafdiagram={
                <StolpediagramVertikalt
                  skjulteTyper={[
                    'Totalt ant. avvik',
                    'Alvorlige avvik prosent',
                  ]}
                  tooltipVerdiPostfix="stk"
                  data={avvikPrEntreprenorStatistikkdata.statistikk}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={avvikPrEntreprenorStatistikkdata.tabell}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
        {erSynlig('drift') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={ressursbrukStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.materialforbruk-pr-entreprenor.tittel'
                ]
              }
              undertittel={getUndertittel(
                localeContext.currentLocale.messages[
                  'diagram-container.materialforbruk-pr-entreprenor.valg-postfix'
                ],
                true,
                false,
                true,
                false
              )}
              datoFra={ressursbrukFraDato}
              datoTil={ressursbrukTilDato}
              onDatoFra={setRessursbrukFraDato}
              onDatoTil={setRessursbrukTilDato}
              onRefreshData={loadStatistikkRessursbrukPrMateriale}
              onCsvExport={(filnavn) =>
                eksportToCsv(filnavn, ressursbrukStatistikkdata)
              }
              grafdiagram={
                <StolpediagramVertikalt
                  stackBars={false}
                  data={ressursbrukStatistikkdata.statistikk}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={ressursbrukStatistikkdata.tabell}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
        {erSynlig('avvik') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={avviksfeiltyperStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.type-avvik-pr-bydel.tittel'
                ]
              }
              undertittel={
                localeContext.currentLocale.messages[
                  'diagram-container.type-avvik-pr-bydel.valg-postfix'
                ]
              }
              datoFra={avviksfeiltyperFraDato}
              datoTil={avviksfeiltyperTilDato}
              onDatoFra={setAvviksfeiltyperFraDato}
              onDatoTil={setAvviksfeiltyperTilDato}
              onRefreshData={loadStatistikkAvviksfeiltypePrBydel}
              onCsvExport={(filnavn) =>
                eksportToCsv(filnavn, avviksfeiltyperStatistikkdata)
              }
              grafdiagram={
                <StolpediagramVertikalt
                  skjulteTyper={[
                    'Totalt ant. avvik',
                    'Alvorlige avvik prosent',
                  ]}
                  tooltipVerdiPostfix="stk"
                  data={avviksfeiltyperStatistikkdata.statistikk}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={avviksfeiltyperStatistikkdata.tabell}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
        {erSynlig('avvik') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={avviksfeiltyperPrEntreprenorStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.type-avvik-pr-entreprenor.tittel'
                ]
              }
              undertittel={
                localeContext.currentLocale.messages[
                  'diagram-container.type-avvik-pr-entreprenor.valg-postfix'
                ]
              }
              datoFra={avviksfeiltyperPrEntreprenorFraDato}
              datoTil={avviksfeiltyperPrEntreprenorTilDato}
              onDatoFra={setAvviksfeiltyperPrEntreprenorFraDato}
              onDatoTil={setAvviksfeiltyperPrEntreprenorTilDato}
              onRefreshData={loadStatistikkAvviksfeiltypePrEntreprenor}
              onCsvExport={(filnavn) =>
                eksportToCsv(
                  filnavn,
                  avviksfeiltyperPrEntreprenorStatistikkdata
                )
              }
              grafdiagram={
                <StolpediagramVertikalt
                  skjulteTyper={[
                    'Totalt ant. avvik',
                    'Alvorlige avvik prosent',
                  ]}
                  tooltipVerdiPostfix="stk"
                  data={avviksfeiltyperPrEntreprenorStatistikkdata.statistikk}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={avviksfeiltyperPrEntreprenorStatistikkdata.tabell}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
        {erSynlig('avvik') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={avvikStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.avvik-pr-maned.tittel'
                ]
              }
              undertittel={getUndertittel(
                localeContext.currentLocale.messages[
                  'diagram-container.avvik-pr-maned.valg-postfix'
                ]
              )}
              datoFra={avvikFraDato}
              datoTil={avvikTilDato}
              onDatoFra={setAvvikFraDato}
              onDatoTil={setAvvikTilDato}
              onRefreshData={loadStatistikkAvvik}
              onCsvExport={(filnavn) =>
                eksportToCsv(filnavn, avvikStatistikkdata)
              }
              grafdiagram={
                <StolpediagramVertikalt
                  skjulteTyper={[
                    'Totalt ant. avvik',
                    'Alvorlige avvik prosent',
                  ]}
                  tooltipVerdiPostfix="stk"
                  data={avvikStatistikkdata.statistikk}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={avvikStatistikkdata.tabell}
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
        {erSynlig('kontroller') && (
          <Col
            className="statistikkPage-chart-container"
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <DiagramContainer
              ref={React.createRef()}
              loading={kontrollerPrMndStatistikkdata.loading}
              tittel={
                localeContext.currentLocale.messages[
                  'diagram-container.antall-kontroller.tittel'
                ]
              }
              undertittel={getUndertittel(
                localeContext.currentLocale.messages[
                  'diagram-container.antall-kontroller.valg-postfix'
                ],
                true,
                false,
                false,
                false
              )}
              datoFra={kontrollerPrMndFraDato}
              datoTil={kontrollerPrMndTilDato}
              onDatoFra={setKontrollerPrMndFraDato}
              onDatoTil={setKontrollerPrMndTilDato}
              onRefreshData={loadStatistikkKontrollerPrMnd}
              onCsvExport={(filnavn) =>
                eksportToCsv(filnavn, kontrollerPrMndStatistikkdata)
              }
              diagramtype="linje"
              grafdiagram={
                <Linjediagram
                  xAxisLabel="Mnd"
                  yAxisLabel="Antall"
                  tooltipVerdiPostfix="kontroller"
                  data={
                    kontrollerPrMndStatistikkdata &&
                    kontrollerPrMndStatistikkdata.statistikk
                  }
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
              tabelldiagram={
                <Tabelldiagram
                  data={
                    kontrollerPrMndStatistikkdata &&
                    kontrollerPrMndStatistikkdata.tabell
                  }
                  ingenDataTekst={getPakrevdFilterbeskrivelse()}
                />
              }
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

StatistikkPage.propTypes = {
  statiskeData: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func })
    .isRequired,
};

export default StatistikkPage;
