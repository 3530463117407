import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FaChevronRight } from 'react-icons/fa';
import { Button } from 'reactstrap';

const BreadcrumbElement = (props) => {
  const { active, label, linkTo, history, toggleExpanded, disabled } = props;

  return (
    <li className="crumb">
      {!active ? (
        <Button
          color="link"
          onClick={() => {
            toggleExpanded();
            history.push(linkTo);
          }}
        >
          {label}
        </Button>
      ) : (
        <Button
          color="link"
          className="active"
          onClick={toggleExpanded}
          disabled={disabled}
        >
          {label}
        </Button>
      )}
      {!active && <FaChevronRight className="crumb-arrow" />}
    </li>
  );
};

BreadcrumbElement.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  linkTo: PropTypes.string,
  toggleExpanded: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

BreadcrumbElement.defaultProps = {
  active: false,
  disabled: false,
  label: null,
  linkTo: null,
};

export default withRouter(BreadcrumbElement);
