import filter from 'lodash/filter';
import { layerInfoUrl } from '../utils/mapUtils';
import { toastError } from './toast';

const ADD_KARTLAG = 'kartlag/ADD';
const REMOVE_KARTLAG = 'kartlag/REMOVE';
const RESET_KARTLAG = 'kartlag/RESET';
const GET_KARTLAG_DESCRIPTION_SUCCESS = 'kartlag/beskrivelse/SUCCESS';

export default function reducer(
  state = { valgtKartlag: [], kartlag: [] },
  action = {}
) {
  switch (action.type) {
    case ADD_KARTLAG:
      return {
        ...state,
        valgtKartlag: [...state.valgtKartlag, action.payload],
      };
    case REMOVE_KARTLAG:
      return {
        ...state,
        valgtKartlag: filter(
          state.valgtKartlag,
          (n) => n.id.toString() !== action.payload
        ),
      };
    case RESET_KARTLAG:
      return {
        ...state,
        valgtKartlag: [],
      };
    case GET_KARTLAG_DESCRIPTION_SUCCESS:
      return {
        ...state,
        kartlag: action.payload,
      };
    default:
      return state;
  }
}

export const addKartlag = (f) => (dispatch) =>
  dispatch({
    type: ADD_KARTLAG,
    payload: f,
  });

export const removeKartlag = (f) => (dispatch) =>
  dispatch({
    type: REMOVE_KARTLAG,
    payload: f,
  });

export const resetKartlag = () => (dispatch) =>
  dispatch({
    type: RESET_KARTLAG,
    payload: [],
  });

export const getKartlagDescription = () => {
  return async (dispatch) => {
    try {
      const data = await fetch(layerInfoUrl);
      const res = await data.json();
      dispatch({
        type: GET_KARTLAG_DESCRIPTION_SUCCESS,
        payload: res && res.layers,
      });
    } catch (error) {
      dispatch(toastError(error));
    }
  };
};
