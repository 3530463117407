import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import httpClient from '../api/httpClient';
import { getPersistedFilter } from '../utils/kartdataFilterUtils';

const getPersistedState = () => {
  return getPersistedFilter();
};

const devStore = () => {
  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    rootReducer,
    getPersistedState(),
    composeEnhancers(applyMiddleware(thunk))
  );
};

const productionStore = () =>
  createStore(rootReducer, getPersistedState(), applyMiddleware(thunk));

const store =
  process.env.NODE_ENV === 'development' ? devStore() : productionStore();

// set the store in httpClient only once
httpClient.store = store;
export default store;
