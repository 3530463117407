import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ConfirmModal(props) {
  const {
    show,
    tittel,
    tekst,
    bekreftKnappetekst,
    avbrytKnappetekst,
    className,
    onConfirm,
    onCancel,
    children,
  } = props;

  return (
    <div>
      <Modal
        isOpen={show}
        toggle={onCancel}
        className={className}
        autoFocus={false}
      >
        <ModalHeader toggle={onCancel}>{tittel}</ModalHeader>
        <ModalBody>
          {tekst}
          {children && (
            <>
              <br />
              {children}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onConfirm}>
            {bekreftKnappetekst}
          </Button>{' '}
          <Button color="secondary" onClick={onCancel}>
            {avbrytKnappetekst}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  tittel: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  tekst: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  bekreftKnappetekst: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  avbrytKnappetekst: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ConfirmModal.defaultProps = {
  bekreftKnappetekst: 'Ok',
  avbrytKnappetekst: 'Avbryt',
  className: null,
  children: undefined,
};

export default ConfirmModal;
