import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
import { getNorwegianLocalTime } from '../utils/dateUtils';
import {
  mapIsDirty,
  persistFilter,
  filterIsValid,
} from '../utils/kartdataFilterUtils';
import { seksjonOptions, arbeidOptions } from '../utils/sporUtils';
import { VIEWPORTCHANGED, TRACKDATASUCCESS } from './kartdata';

const UPDATE_FILTER = 'kartfilter/UPDATE';
// const VIEWPORTCHANGED = 'kartdata/VIEWPORTCHANGED';
// const TRACKDATASUCCESS = 'kartdata/SUCCESS';

const initialState = {
  prevExecutedFilter: {},
  filter: {
    datoFra: getNorwegianLocalTime(moment().subtract(1, 'day').format()),
    datoFraIsValid: true,
    datoTil: getNorwegianLocalTime(),
    datoTilIsValid: true,
    tiltak: '',
    viewport: {
      center: [],
      zoom: 0,
      bounds: {},
    },
    isDirty: true,
    isValid: true,
    seksjonOptions,
    arbeidOptions,
    driftsomrade: null,
    bydel: null,
    entreprenorOptions: [],
    maskinIdOptions: [],
    feilmeldingOptions: [],
  },
  tiltakoptions: [
    {
      value: 'alle',
      label: 'Alle',
    },
    {
      value: 'Renhold',
      label: 'Renhold',
    },
    {
      value: 'Spreder',
      label: 'Spreder',
    },
    {
      value: 'Brøyting, Spreder',
      label: 'Brøyting og Spreder',
    },
    {
      value: 'Brøyting',
      label: 'Brøyting',
    },
  ],
};

const getEntreprenorOptionsFromFeatures = (payload) => {
  return sortBy(
    reduce(
      payload,
      (res, pl) => [
        ...res,
        ...uniqBy(pl.features, 'properties.kontraktNummer').map((fe) => ({
          label: fe.properties.kontraktNummer,
          kontraktNummer: fe.properties.kontraktNummer,
          value: false,
        })),
      ],
      []
    ),
    'label'
  );
};

const getMaskinIdOptionsFromFeatures = (payload) => {
  return sortBy(
    reduce(
      payload,
      (res, pl) => [
        ...res,
        ...uniqBy(pl.features, 'properties.maskinId').map((maskin) => ({
          label: maskin.properties.maskinId,
          maskinId: maskin.properties.maskinId,
          value: false,
          kontraktNummer: maskin.properties.kontraktNummer,
        })),
      ],
      []
    ),
    'label'
  );
};

export default function reducer(state = initialState, action = {}) {
  if (!state.hydrated) {
    // eslint-disable-next-line no-param-reassign
    state = {
      ...initialState,
      filter: {
        ...initialState.filter,
        driftsomrade: state.filter.driftsomrade,
      },
      hydrated: true,
    };
  }

  switch (action.type) {
    case UPDATE_FILTER:
      // eslint-disable-next-line no-case-declarations
      const updated = {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
          isDirty: mapIsDirty(state.prevExecutedFilter, {
            ...state.filter,
            ...action.payload,
          }),
          isValid: filterIsValid({
            ...state.filter,
            ...action.payload,
          }),
        },
      };

      persistFilter(updated);
      return updated;
    case VIEWPORTCHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          viewport: action.payload,
          isDirty: mapIsDirty(state.prevExecutedFilter, {
            ...state.filter,
            viewport: action.payload,
          }),
        },
      };
    case TRACKDATASUCCESS:
      return {
        ...state,
        prevExecutedFilter: { ...state.filter },
        filter: {
          ...state.filter,
          entreprenorOptions: getEntreprenorOptionsFromFeatures(action.payload),
          maskinIdOptions: getMaskinIdOptionsFromFeatures(action.payload),
          isDirty: false,
          autoloadKartdata: false,
        },
      };
    default:
      return state;
  }
}

export const updateFilter = (filter) => (dispatch) => {
  return dispatch({
    type: UPDATE_FILTER,
    payload: filter,
  });
};
