import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { ListGroupItem, Badge } from 'reactstrap';
import Translate from '../../Locale/Translate';
import StatusBadge from '../Common/StatusBadge.tsx';
import { AVVIKTYPE_ENTREPRENOR } from '../../utils/avvikUtils';
import { avvikStatuskoder } from '../../constants/status';

const AvvikListeItem = (props) => {
  const {
    id,
    meldingsnummer,
    status,
    type,
    tidspunkt,
    adresse,
    botgrunnlag,
    behandlet,
    onItemSelected,
    selected,
    kategori,
    avviktypeId,
  } = props;

  const onClick = () => {
    if (onItemSelected) {
      onItemSelected(id);
    }
  };

  const kategorier = [
    { id: '97867e05-f508-4457-8a67-f9c8f8dd236c', class: 'vinterdrift' },
    { id: 'd761fcec-371c-4017-86fb-7bbbb30338f0', class: 'renhold' },
    { id: '0df91571-8cff-4c5f-bc1b-4adbe5cbf7d8', class: 'avfall' },
    { id: '58c6ced2-575c-41e4-a786-ca6d8554bfb3', class: 'sluk-kummer-lokk' },
    { id: '47b7699d-42ce-4754-a195-92415ca78ae6', class: 'vei-asfalt' },
    { id: '00f28ca8-6509-4c25-80bf-603ddcffbebb', class: 'konstruksjoner' },
    { id: '9f44059f-e7ef-467d-834f-3503f473019b', class: 'biler-maskiner' },
    { id: '5f494d30-5f21-44bd-98c6-46955c404ddb', class: 'traer-busker' },
    { id: 'd7fbb314-9d4b-436a-a682-dde403e0ba1c', class: 'miljoavvik' },
  ];
  const iconClass = find(kategorier, (k) => k.id === kategori);
  const isEksternInnmelder = avviktypeId === AVVIKTYPE_ENTREPRENOR;
  const isKladd = status === avvikStatuskoder.find((s) => s.key === 'Kladd').id;

  return (
    <>
      <ListGroupItem className={selected ? 'selected' : ''} onClick={onClick}>
        <div className="avvik-item">
          <div
            className={`avvik-item-icon ${
              iconClass ? iconClass.class : 'default'
            } ${botgrunnlag ? ' botgrunnlag' : ' '}`}
          >
            {/* {botgrunnlag && (
              <div className="alvorlig-indikator" aria-hidden="true" />
            )} */}
          </div>
          <div className="avvik-item-body">
            <div className="avvik-item-header">
              <div className="avvik-item-header-text">{type}</div>
            </div>
            <div className="avvik-item-time-location">
              <span className="avvik-item-location">{adresse}</span>
              <span>{tidspunkt}</span>
            </div>
            <div className="avvik-item-footer">
              <div>
                <Translate id="kontrollpanel.avvik.meldingsnummer" />{' '}
                {meldingsnummer}
              </div>
              {/* <Label check>
                <Input type="checkbox" readOnly checked={botgrunnlag} />
                Alvorlig
              </Label> */}
              <div>
                <StatusBadge
                  status={status}
                  isEksternInnmelder={isEksternInnmelder}
                />
              </div>
            </div>
          </div>
        </div>
      </ListGroupItem>
    </>
  );
};

AvvikListeItem.propTypes = {
  id: PropTypes.string.isRequired,
  meldingsnummer: PropTypes.string.isRequired,
  status: PropTypes.string,
  type: PropTypes.string.isRequired,
  tidspunkt: PropTypes.string.isRequired,
  adresse: PropTypes.string,
  botgrunnlag: PropTypes.bool.isRequired,
  behandlet: PropTypes.bool,
  onItemSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  kategori: PropTypes.string.isRequired,
};

AvvikListeItem.defaultProps = {
  status: null,
  selected: false,
  behandlet: false,
  adresse: '',
  // type: PropTypes.string.isRequired,
  // botgrunnlag: PropTypes.bool.isRequired,
  // onItemSelected: PropTypes.func.isRequired,
};

export default AvvikListeItem;
