import React from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import VarslingListeItem from './VarslingListeItem';
import Translate from '../../Locale/Translate';

const VarslingListe = (props) => {
  const { varslinger, oppdaterSisteLest, loading } = props;

  return (
    <div className="varsling-liste">
      <div className="varsling-liste-header">
        <span>
          <Translate id="varslingliste.header.text" />
        </span>
        <PulseLoader loading={loading} size={6} />
        {varslinger.some((v) => v.ulest === true) && (
          <Button color="link" onClick={() => oppdaterSisteLest()}>
            <Translate id="varslingliste.header.buttons.merk-alle-lest" />
          </Button>
        )}
      </div>
      <div className="varsling-liste-items">
        <ListGroup flush>
          {(!varslinger || varslinger.filter((v) => v.ulest).length <= 0) && (
            <ListGroupItem>
              <span>
                <Translate id="varslingliste.body.text.no-content" />
              </span>
            </ListGroupItem>
          )}
          {varslinger
            .filter((varsling) => varsling.ulest === true)
            .map((varsling) => {
              return (
                <VarslingListeItem key={varsling.id} varsling={varsling} />
              );
            })}
        </ListGroup>
      </div>
    </div>
  );
};

VarslingListe.propTypes = {
  varslinger: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  oppdaterSisteLest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default VarslingListe;
