import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Translate from '../../Locale/Translate';

import backIcon from '../../images/back-arrow.svg';
import panelIcon from '../../images/panel-icon.svg';
import plusIcon from '../../images/plus-icon.svg';
import settingsIcon from '../../images/settings-icon.svg';
import soekIcon from '../../images/soek-icon.svg';

const Shortcutbar = ({
  history,
  searchComponent,
  menuComponent,
  isOpen,
  setIsOpen,
  setSearchIsOpen,
  searchIsOpen,
  searchIsActive,
  filterIsActive,
}) => {
  const openFilter = () => {
    if (setSearchIsOpen) setSearchIsOpen(false);
    setIsOpen(true);
  };

  const openSearch = () => {
    if (setIsOpen) setIsOpen(false);
    setSearchIsOpen(true);
  };

  const onPageEvent = () => {
    if (setIsOpen) setIsOpen(false);
    if (setSearchIsOpen) setSearchIsOpen(false);
  };

  return (
    <>
      <Navbar className="bym-shortcutbar" color="white" light fixed="bottom">
        <Nav navbar>
          <NavItem>
            <Button color="white" onClick={() => history.goBack()}>
              <img src={backIcon} alt="Tilbake" />
              <Translate id="shortcut.backBtn" defaultMessage="Tilbake" />
            </Button>
          </NavItem>
          <NavItem>
            <Button color="white" onClick={() => history.push('/')}>
              <img src={panelIcon} alt="Panel" />
              <Translate id="shortcut.panelBtn" defaultMessage="Panel" />
            </Button>
          </NavItem>

          <NavItem>
            <Button
              color="white"
              onClick={() => history.push('/innmelding/ny')}
            >
              <img src={plusIcon} alt="Ny kontroll" />
              <Translate
                id="shortcut.newControlBtn"
                defaultMessage="Ny kontroll"
              />
            </Button>
          </NavItem>
          {searchComponent && (
            <NavItem>
              <Button
                color="white"
                className={`shorcutbar-btn ${
                  searchIsActive ? ' active ' : ' '
                }`}
                onClick={() => (searchIsOpen ? onPageEvent() : openSearch())}
              >
                <img src={soekIcon} alt="Filter" />
                <Translate id="shortcut.sokBtn" defaultMessage="Søk" />
              </Button>
            </NavItem>
          )}
          {menuComponent && (
            <NavItem>
              <Button
                color="white"
                className={`shorcutbar-btn ${
                  filterIsActive ? ' active ' : ' '
                }`}
                onClick={() => (isOpen ? onPageEvent() : openFilter())}
              >
                <img src={settingsIcon} alt="Filter" />
                <Translate id="shortcut.filterBtn" defaultMessage="Filter" />
              </Button>
            </NavItem>
          )}
        </Nav>
      </Navbar>
      {searchComponent && (
        <div className="search-component">
          <div
            className={`overlay overlay-bottom ${searchIsOpen && ' open'}`}
            onClick={onPageEvent}
            onKeyPress={onPageEvent}
            role="button"
            tabIndex={0}
          />
          <div className={`slider-up-menu ${searchIsOpen && ' open'}`}>
            {searchComponent}
          </div>
        </div>
      )}
      {menuComponent && (
        <div className="menu-component">
          <div
            className={`overlay overlay-right ${isOpen && ' open'}`}
            onClick={onPageEvent}
            onKeyPress={onPageEvent}
            role="button"
            tabIndex={0}
          />
          <div className={`slider-menu ${isOpen && ' open'}`}>
            {menuComponent}
          </div>
        </div>
      )}
    </>
  );
};

Shortcutbar.propTypes = {
  history: PropTypes.shape({}).isRequired,
  menuComponent: PropTypes.shape({}),
  searchComponent: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  setSearchIsOpen: PropTypes.func,
  searchIsOpen: PropTypes.bool,
  searchIsActive: PropTypes.bool,
  filterIsActive: PropTypes.bool,
};

Shortcutbar.defaultProps = {
  menuComponent: null,
  searchComponent: null,
  isOpen: null,
  setIsOpen: null,
  searchIsOpen: null,
  setSearchIsOpen: null,
  searchIsActive: false,
  filterIsActive: false,
};

export default withRouter(Shortcutbar);
