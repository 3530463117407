// eslint-disable-next-line import/prefer-default-export
export function isNullOrWhitespace(input) {
  return !input || !input.trim();
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function addThousandSeparator(string) {
  return string.toLocaleString();
  // return string.toLocaleString('nb-NO');
}
