import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import StatistikkPage from './StatistikkPage';
import {
  getStatistikkVinterdrift,
  getStatistikkAvvik,
  getStatistikkAvvikBydel,
  getStatistikkAvvikOgKontroller,
} from '../../state/statistikk';

const mapStateToProps = (state) => ({
  statiskeData: state.statiskeData,
  statistikk: state.statistikk,
  statistikkAvvik: state.statistikk.avvik,
  statistikkAvvikBydel: state.statistikk.avvikBydel,
  statistikkAvvikOgKontroller: state.statistikk.avvikOgKontroller,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStatistikkVinterdrift,
      getStatistikkAvvik,
      getStatistikkAvvikBydel,
      getStatistikkAvvikOgKontroller,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StatistikkPage);
