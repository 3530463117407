import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Calendar from 'react-calendar';
// import TimeInput from 'react-time-input';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { LocaleContext } from '../../Locale/LocaleProvider';
import { isDateInFuture } from '../../utils/dateUtils';
import { getPeriode, configs } from './KalenderPeriodeVelgerUtils';
import KalenderPeriodeVelgerTidInput from './KalenderPeriodeVelgerTidInput';
import { ReactComponent as PilIkon } from '../../images/glyphicons-pil-ned.svg';
import { ReactComponent as KalenderIkon } from '../../images/kalender-ikon.svg';
import 'react-calendar/dist/Calendar.css';

const KalenderPeriodeVelger = ({
  fra,
  til,
  velgPeriode,
  isDeaktivert,
  onChange,
  showTimepicker,
  displayDayMonthYearButtons,
  calendarId,
}) => {
  const localeContext = useContext(LocaleContext);

  const [open, setOpen] = useState(false);
  const [selectedPeriode, setSelectedPeriode] = useState({});

  const handleChange = (periode) => {
    setOpen(false);
    onChange(periode[0], periode[1]);
  };

  const toggle = () => (isDeaktivert ? setOpen(false) : setOpen(!open));

  useEffect(() => {
    setSelectedPeriode(getPeriode(fra, til || fra));
  }, [fra, til]);

  const getFormattedValue = () => {
    if (moment(fra).isValid() && moment(til).isValid()) {
      return [new Date(moment(fra).format()), new Date(moment(til).format())];
    }
  };

  const [config, setConfig] = useState(configs.day);

  return (
    <>
      <div
        className="periodevelger-container"
        id={`periodevelger-${calendarId}`}
      >
        {selectedPeriode.prevValue ? (
          <Button
            className="periodevelger-button periodevelger-secondary-button "
            type="button"
            color="link"
            disabled={isDeaktivert}
            onClick={() => handleChange(selectedPeriode.prevValue)}
          >
            <PilIkon
              className="periodevelger-ikon periodevelger-pil-forrige"
              style={{ transform: 'rotate(90deg)' }}
            />
            <span className="desktop-only">{selectedPeriode.prevLabel}</span>
          </Button>
        ) : (
          <span />
        )}

        <Button
          className="periodevelger-button periodevelger-main-button"
          type="button"
          color="link"
          disabled={isDeaktivert}
        >
          <div className="periodevelger-ikon kalender-ikon-container">
            <KalenderIkon className="kalender-ikon" />
          </div>

          <span>{selectedPeriode.label}</span>
          <PilIkon className="periodevelger-ikon periodevelger-pil-ned pil-ned-ikon" />
        </Button>
        {selectedPeriode.nextValue ? (
          <Button
            className="periodevelger-button periodevelger-secondary-button"
            type="button"
            color="link"
            onClick={() => handleChange(selectedPeriode.nextValue)}
            disabled={
              isDeaktivert || selectedPeriode.nextValue
                ? isDateInFuture(selectedPeriode.nextValue[0])
                : false
            }
          >
            <span className="desktop-only">{selectedPeriode.nextLabel}</span>
            <PilIkon
              className="periodevelger-ikon periodevelger-pil-neste"
              style={{ transform: 'rotate(-90deg)' }}
            />
          </Button>
        ) : (
          <span />
        )}
      </div>
      {showTimepicker && (
        <KalenderPeriodeVelgerTidInput
          fra={fra}
          til={til}
          onChange={onChange}
        />
      )}
      <Popover
        className="periodevelger-popover"
        placement="bottom"
        isOpen={open}
        target={`periodevelger-${calendarId}`}
        toggle={toggle}
        trigger="legacy"
      >
        <PopoverHeader />
        <PopoverBody>
          {displayDayMonthYearButtons && (
            <div className="periodevelger-knapp-gruppe">
              <Button
                color="gray-light"
                className={`periodevelger-knapp ${
                  config.name === 'day' ? ' active ' : ' '
                }`}
                onClick={() => setConfig(configs.day)}
              >
                Dag
              </Button>
              <Button
                color="gray-light"
                className={`periodevelger-knapp ${
                  config.name === 'mnd' ? ' active ' : ' '
                }`}
                onClick={() => setConfig(configs.mnd)}
              >
                Mnd
              </Button>
              <Button
                color="gray-light"
                className={`periodevelger-knapp ${
                  config.name === 'year' ? ' active ' : ' '
                }`}
                onClick={() => setConfig(configs.year)}
              >
                År
              </Button>
            </div>
          )}
          <Calendar
            {...config}
            maxDate={new Date()}
            selectRange={velgPeriode}
            onChange={handleChange}
            value={getFormattedValue()}
            locale={
              localeContext && localeContext.currentLocale
                ? localeContext.currentLocale.lCode
                : 'nb-NO'
            }
          />
        </PopoverBody>
      </Popover>
    </>
  );
};

KalenderPeriodeVelger.propTypes = {
  fra: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  til: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  velgPeriode: PropTypes.bool,
  isDeaktivert: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showTimepicker: PropTypes.bool,
  displayDayMonthYearButtons: PropTypes.bool,
  calendarId: PropTypes.string,
};

KalenderPeriodeVelger.defaultProps = {
  fra: null,
  til: null,
  velgPeriode: true,
  isDeaktivert: false,
  showTimepicker: false,
  displayDayMonthYearButtons: false,
  calendarId: 'calendarId',
};

export default KalenderPeriodeVelger;
