import React, { createContext, useEffect, useState, useContext } from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import jwtDecode from 'jwt-decode';
import {
  BYMELDINGADMIN,
  BYMENTREPRENOR,
  BYMENTREPRENORADMIN,
  BYMSERVICEAVDELING,
  BYMSERVICEAVDELINGADMIN,
  DASHBOARD,
  ENTREPRENOR,
  ENTREPRENORKONTROLLADMIN,
  FORVALTER,
  KONTROLLOR,
} from '../constants/roles';
import HttpClient from '../api/httpClient';
import oidcConfig from '../oidcConfig';
// import { Bruker } from '../../interfaces/userInterface';

export interface Bruker {
  brukerId: string;
  navn: string;
  epost: string;
  roller: string[];
}

const initBruker: Bruker = {
  brukerId: '',
  navn: '',
  epost: '',
  roller: [],
};

interface IUserContext {
  user: Bruker;
  login: () => void;
  logout: () => void;
  getName: () => string;
  getId: () => string;
  getRoles: () => string[];
  getPrimaryRoleName: () => string;
  isLoggedIn: () => boolean;
  isBymeldingAdmin: () => boolean;
  isBymServiceavdeling: () => boolean;
  isBymServiceavdelingAdmin: () => boolean;
  isBymEntreprenor: () => boolean;
  isBymEntreprenorAdmin: () => boolean;
  isEntreprenor: () => boolean;
  isKontrollor: () => boolean;
  isForvalter: () => boolean;
  isEnkAdmin: () => boolean;
  isInternBymbruker: () => boolean;
}

export const UserContext = createContext({} as IUserContext);

const UserProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const [user, setUser] = useState(initBruker);
  const auth = useAuth();

  const login = (): void => {
    auth.signinRedirect();
  };

  const logout = (): void => {
    auth.signoutRedirect();
  };

  const getName = (): string => user?.navn;

  const getId = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bymId = (auth?.user?.profile?.params as any)?.['bym-id'];
    return bymId ? String(bymId) : '';
  };

  const getToken = (): User | undefined => {
    const oidcStorage = localStorage.getItem(
      // eslint-disable-next-line prettier/prettier
      `oidc.user:${(oidcConfig as any).authority}:${(oidcConfig as any).client_id
      }`
    );
    return oidcStorage ? User.fromStorageString(oidcStorage) : undefined;
  };

  const getRoles = (): string[] => {
    const brukertoken = getToken();
    if (!brukertoken?.access_token) return [];

    const accessToken = jwtDecode<any>(brukertoken.access_token);

    return accessToken.roles;
  };

  const HarBrukerRolle = (rolle: string): boolean => {
    const roller = getRoles();
    const rolleFinnes = roller.indexOf(rolle) > -1;
    // console.log(
    //   `Rolle ${rolle} ble ${rolleFinnes ? '' : 'IKKE '}funnet i tokenet`
    // );
    return rolleFinnes; // bare debug
  };

  const isLoggedIn = (): boolean => auth?.isAuthenticated;

  const isBymeldingAdmin = (): boolean => HarBrukerRolle(BYMELDINGADMIN);

  const isBymServiceavdeling = (): boolean =>
    HarBrukerRolle(BYMSERVICEAVDELING);

  const isBymServiceavdelingAdmin = (): boolean =>
    HarBrukerRolle(BYMSERVICEAVDELINGADMIN);

  const isBymEntreprenorAdmin = (): boolean =>
    HarBrukerRolle(BYMENTREPRENORADMIN);

  const isBymEntreprenor = (): boolean => HarBrukerRolle(BYMENTREPRENOR);

  const isEntreprenor = (): boolean => HarBrukerRolle(ENTREPRENOR);
  const isKontrollor = (): boolean => HarBrukerRolle(KONTROLLOR);
  const isForvalter = (): boolean => HarBrukerRolle(FORVALTER);
  const isEnkAdmin = (): boolean => HarBrukerRolle(ENTREPRENORKONTROLLADMIN);
  const isDashboard = (): boolean => HarBrukerRolle(DASHBOARD);

  const isInternBymbruker = (): boolean =>
    isKontrollor() ||
    isForvalter() ||
    isEnkAdmin() ||
    isBymeldingAdmin() ||
    isBymServiceavdelingAdmin() ||
    isBymServiceavdeling() ||
    isDashboard();

  const getPrimaryRoleName = () => {
    // for bakoverkompatibilitet må vi returnere "primærrollen" til bruker,
    // dvs den høyeste tilgangen vedkommende har.
    if (isBymeldingAdmin()) return 'Bymelding Admin';
    if (isEnkAdmin()) return 'Entreprenørkontroll Admin';
    if (isBymServiceavdelingAdmin()) return 'Serviceavdeling admin';
    if (isBymServiceavdeling()) return 'Serviceavdeling';
    if (isBymEntreprenorAdmin()) return 'Bymelding entreprenøradmin';
    if (isBymEntreprenor()) return 'Bymelding entreprenør';
    if (isEntreprenor()) return 'Entreprenør';
    if (isKontrollor()) return 'Kontrollør';
    if (isForvalter()) return 'Forvalter';
    if (isDashboard()) return 'Dashboard';

    return 'Ukjent';
  };

  useEffect((): void => {
    if (!auth.isAuthenticated) return;

    setUser({
      brukerId: getId() ?? '',
      navn: auth.user?.profile.name ?? '',
      epost: auth.user?.profile.email ?? '',
      roller: getRoles(),
    });

    HttpClient.setDefaultCredentials(getToken()?.access_token);
  }, [auth, auth.isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        getName,
        getId,
        getRoles,
        getPrimaryRoleName,
        isLoggedIn,
        isBymeldingAdmin,
        isBymServiceavdeling,
        isBymServiceavdelingAdmin,
        isBymEntreprenor,
        isBymEntreprenorAdmin,
        isEntreprenor,
        isKontrollor,
        isForvalter,
        isEnkAdmin,
        isInternBymbruker,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

export const useUser = (): IUserContext => useContext(UserContext);
