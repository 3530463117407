import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Button } from 'reactstrap';
import map from 'lodash/map';
import last from 'lodash/last';

import { FaChevronRight } from 'react-icons/fa';
import BreadcrumbElement from './BreadcrumbElement';

import { ReactComponent as CompassIconGray } from '../../images/compass.gray.svg';
import { ReactComponent as BackIcon } from '../../images/back-arrow.svg';
import Translate from '../../Locale/Translate';

const Breadcrumb = ({
  breadcrumbs,
  expandedOnMobile,
  toggleExpanded,
  onBackClick,
}) => {
  const isSmallScreen = () => window.innerWidth < 770;
  const activeCrumnb = last(breadcrumbs);

  return (
    <Navbar
      fixed="top"
      className={`breadcrumbs-wrap ${expandedOnMobile ? 'open' : 'closed'}`}
    >
      {isSmallScreen() && activeCrumnb && activeCrumnb.displayBackBtn ? (
        // <Button
        // color="link"
        // disabled={breadcrumbs.length === 1}
        <Button color="link" className="compass-left" onClick={onBackClick}>
          <BackIcon className="icon-compass" fill="#fff" />
        </Button>
      ) : (
        <div className={`compass-left ${expandedOnMobile ? 'open' : 'closed'}`}>
          <CompassIconGray className="icon-compass" />
        </div>
      )}
      <div
        style={{
          height:
            expandedOnMobile && isSmallScreen()
              ? `${23 * breadcrumbs.length}px`
              : '23px',
          transition: 'height 0.45s ease-in-out',
          overflow: 'hidden',
        }}
      >
        <ol className="breadcrumbs">
          {map(breadcrumbs, (breadcrumb, index) => {
            return (
              <Translate
                id={`breadcrumbs.site_${breadcrumb.id}`}
                defaultMessage={breadcrumb.name}
                key={breadcrumb.id}
              >
                {(msg) => (
                  <BreadcrumbElement
                    active={index === breadcrumbs.length - 1}
                    label={msg}
                    linkTo={breadcrumb.link}
                    key={`breadcrumb_element_${breadcrumb.id}`}
                    toggleExpanded={toggleExpanded}
                    disabled={breadcrumbs.length === 1}
                  />
                )}
              </Translate>
            );
          })}
        </ol>
      </div>
      <Button
        color="link"
        disabled={breadcrumbs.length === 1}
        className={`mobile-chevron-right ${
          expandedOnMobile ? 'open' : 'closed'
        }`}
        onClick={toggleExpanded}
      >
        <FaChevronRight />
      </Button>
    </Navbar>
  );
};

Breadcrumb.propTypes = {
  expandedOnMobile: PropTypes.bool.isRequired,
  onBackClick: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Breadcrumb;
