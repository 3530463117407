import React, { useContext } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { LocaleContext } from '../../Locale/LocaleProvider';

const CheckboxGroup = (props) => {
  const { options, onChange } = props;
  const localeContext = useContext(LocaleContext);

  const localOnChange = (option) => {
    const updatedOptions = options.map((item) => {
      if (item.label === option.label) {
        return option;
      }
      return item;
    });

    if (onChange) {
      onChange(updatedOptions);
    }
  };

  return (
    <>
      {options
        .filter((o) => o.visible === undefined || o.visible === true)
        .map((option) => (
          <FormGroup check key={option.label}>
            <Label check>
              <Input
                type="checkbox"
                checked={option.value}
                onChange={(e) =>
                  localOnChange({ ...option, value: e.target.checked })
                }
              />
              {localeContext.currentLocale.messages[option.labelKey] ||
                option.label}
            </Label>
            <div
              style={{ backgroundColor: option.color }}
              className={`checkbox-legend ${option.legendClassName || ''}`}
            />
          </FormGroup>
        ))}
    </>
  );
};

export default CheckboxGroup;
