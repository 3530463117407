import React, { useEffect, useState } from 'react';
import WarningIcon from '../../images/warning-triangle.svg';
import SuccessIcon from '../../images/success-square.svg';
import InformationIcon from '../../images/information.svg';
import ErrorIcon from '../../images/error-hexagon.svg';

import httpClient from '../../api/httpClient';
import './VarselDrift.css';

interface IKlient {
  key: string;
  visningsnavn: string;
}

interface IVarsel {
  id: string;
  varslingstype: string;
  publisert: boolean;
  klienter: IKlient[];
  synligFra: string;
  synligTil: string;
  tekst: string;
}

const Varsel = (): JSX.Element => {
  const [varselData, setVarselData] = useState<IVarsel[]>([]);
  const klientKey = 'enk-klient';

  const getVarsler = async () => {
    try {
      const resp = await httpClient.getNotificationServiceData(klientKey);
      setVarselData(resp);
    } catch (e) {
      setVarselData([]);
    }
  };

  useEffect(() => {
    getVarsler();
  }, []);

  if (varselData.length > 0) {
    let varselKlasse = 'varsel';
    let ikon;

    switch (varselData[0].varslingstype) {
      case 'Advarsel':
        varselKlasse += ' advarsel';
        ikon = <img src={WarningIcon} alt="Advarsel" aria-hidden />;
        break;
      case 'Feil':
        varselKlasse += ' feil';
        ikon = <img src={ErrorIcon} alt="Feil" aria-hidden />;
        break;
      case 'FeilRettet':
        varselKlasse += ' feil-rettet';
        ikon = <img src={SuccessIcon} alt="Feil Rettet" aria-hidden />;
        break;
      default:
        // Default class and fallback
        varselKlasse += ' informasjon';
        ikon = <img src={InformationIcon} alt="Informasjon" aria-hidden />;
        break;
    }

    return (
      <section className="varsel-container">
        <div className={varselKlasse}>
          <div className="flex ">
            <div aria-hidden>{ikon}</div>
            <div
              className="text-4 hyphens-none"
              dangerouslySetInnerHTML={{ __html: varselData[0].tekst }}
            />
          </div>
        </div>
      </section>
    );
  }

  return <></>;
};

export default Varsel;
