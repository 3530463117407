import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import Translate from '../../Locale/Translate';

function Linjediagram(props) {
  const {
    data,
    xAxisLabel,
    yAxisLabel,
    tooltipFormat,
    tooltipVerdiPostfix,
    ingenDataTekst,
  } = props;

  const getLineColor = (index) => {
    const lineColors = [
      '#f9c66b',
      '#4ef8b6',
      '#ff8274',
      '#888BD1',
      '#1FCCD2',
      '#B1C913',
      '#A4B2C6',
      '#034b45',
    ];

    const modulusIndex =
      index < lineColors.length ? index : index % lineColors.length;

    return lineColors[modulusIndex];
  };

  const formatXAxisLabel = (value) => {
    return moment.utc(value).local().format('DD-HH'); // convert to local time from UTC
  };

  const formatTooltipLabel = (value) => {
    if (tooltipFormat === '24h') {
      return `Kl. ${moment.utc(value).local().format('HH:00')}`;
    }

    return value;
  };

  const formatItemValue = (value) => {
    const formattedNumber = new Intl.NumberFormat('nb-NO').format(value);
    return `${formattedNumber} ${tooltipVerdiPostfix}`;
  };

  const isNoData = () => !data || Object.keys(data).length === 0;

  if (isNoData())
    return (
      <div className="no-data h-100">
        {!ingenDataTekst && <Translate id="diagram-container.ingen-data" />}
        {ingenDataTekst && <span>{ingenDataTekst}</span>}
      </div>
    );

  return (
    <ResponsiveContainer width="99%" height={500}>
      <LineChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="kategori"
          type="category"
          label={{ value: xAxisLabel, position: 'right' }}
          tickFormatter={formatXAxisLabel}
          allowDuplicatedCategory={false}
        />
        <YAxis
          dataKey="antall"
          label={{ value: yAxisLabel, position: 'top' }}
          allowDecimals={false}
        />
        <Tooltip
          itemStyle={{ color: 'black' }}
          labelStyle={{ fontWeight: 'bold' }}
          labelFormatter={formatTooltipLabel}
          formatter={formatItemValue}
        />
        <Legend />
        {data.map((s, index) => (
          <Line
            type="monotone"
            dot={false}
            dataKey="antall"
            data={s.data}
            name={s.navn}
            key={s.navn}
            stroke={getLineColor(index)}
            strokeWidth={4}
            fill={getLineColor(index)}
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

Linjediagram.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  tooltipFormat: PropTypes.oneOf(['none', '24h']),
  tooltipVerdiPostfix: PropTypes.string,
  ingenDataTekst: PropTypes.string,
};

Linjediagram.defaultProps = {
  data: [],
  xAxisLabel: 'Tid',
  yAxisLabel: 'Antall',
  tooltipFormat: 'none',
  tooltipVerdiPostfix: '',
  ingenDataTekst: '',
};

export default Linjediagram;
