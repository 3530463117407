export const arbeidsOptionsFarger = {
  broyting: '#0071bb',
  spreder: '#d32316',
  broytingOgSpreder: '#f29f04',
  renhold: '#22b473',
  tomkjoring: '#4d4d4d',
  snofresing: '#fff',
  stroing: '#34d5eb', // midlertidig
  kantklipping: '#a80092', // midlertidig
  ukjent: '#000',
};

export const arbeidOptionsIds = {
  broyting: '0',
  salting: '1', // ?? samme som spreder?
  spreder: '2',
  broytingOgSpreder: '0,2',
  renhold: '3',
  snofresing: '4',
  stroing: '8',
  kantklipping: '10',
  tomkjoring: null,
};

export const utstyrsloggNavnKeys = {
  ukjent: 'utstyrlogg.ukjent',
  tomkjoring: 'utstyrlogg.tomkjoring',
  broytingOgSpreder: 'utstyrlogg.broytingOgSpreder',
  broyting: 'utstyrlogg.broyting',
  salting: 'utstyrlogg.salting',
  spreder: 'utstyrlogg.spreder',
  renhold: 'utstyrlogg.renhold',
  stroing: 'utstyrlogg.stroing',
  kantklipping: 'utstyrlogg.kantklipping',
  snofresing: 'utstyrlogg.snofresing',
};

export const seksjonNavnKeys = {
  vei: 'seksjon.vei',
};

export const getUtsyrsloggNavnKey = (id) => {
  if (id === null) return utstyrsloggNavnKeys.tomkjoring;

  const utstyrArray =
    id !== null ? id.split(',').map((item) => item.trim()) : [];

  if (
    utstyrArray.includes(arbeidOptionsIds.broyting) &&
    utstyrArray.includes(arbeidOptionsIds.spreder)
  ) {
    return utstyrsloggNavnKeys.broytingOgSpreder;
  }
  if (utstyrArray.includes(arbeidOptionsIds.broyting)) {
    return utstyrsloggNavnKeys.broyting;
  }
  if (utstyrArray.includes(arbeidOptionsIds.salting)) {
    return utstyrsloggNavnKeys.salting;
  }
  if (utstyrArray.includes(arbeidOptionsIds.spreder)) {
    return utstyrsloggNavnKeys.spreder;
  }
  if (utstyrArray.includes(arbeidOptionsIds.renhold)) {
    return utstyrsloggNavnKeys.renhold;
  }
  if (utstyrArray.includes(arbeidOptionsIds.snofresing)) {
    return utstyrsloggNavnKeys.snofresing;
  }
  if (utstyrArray.includes(arbeidOptionsIds.stroing)) {
    return utstyrsloggNavnKeys.stroing;
  }
  if (utstyrArray.includes(arbeidOptionsIds.kantklipping)) {
    return utstyrsloggNavnKeys.kantklipping;
  }

  return utstyrsloggNavnKeys.ukjent;
};

export const getSporFarge = (utstyrlogg) => {
  if (utstyrlogg === null) return arbeidsOptionsFarger.tomkjoring;
  const utstyrArray =
    utstyrlogg != null ? utstyrlogg.split(',').map((item) => item.trim()) : [];

  if (
    utstyrArray.includes(arbeidOptionsIds.broyting) &&
    utstyrArray.includes(arbeidOptionsIds.spreder)
  ) {
    return arbeidsOptionsFarger.broytingOgSpreder;
  }
  if (utstyrArray.includes(arbeidOptionsIds.broyting)) {
    return arbeidsOptionsFarger.broyting;
  }
  if (utstyrArray.includes(arbeidOptionsIds.spreder)) {
    return arbeidsOptionsFarger.spreder;
  }
  if (utstyrArray.includes(arbeidOptionsIds.renhold)) {
    return arbeidsOptionsFarger.renhold;
  }
  if (utstyrArray.includes(arbeidOptionsIds.snofresing)) {
    return arbeidsOptionsFarger.snofresing;
  }
  if (utstyrArray.includes(arbeidOptionsIds.stroing)) {
    return arbeidsOptionsFarger.stroing;
  }
  if (utstyrArray.includes(arbeidOptionsIds.kantklipping)) {
    return arbeidsOptionsFarger.kantklipping;
  }
  return arbeidsOptionsFarger.ukjent;
};

export const arbeidOptions = [
  {
    label: 'Brøyting',
    labelKey: utstyrsloggNavnKeys.broyting,
    value: false,
    legendClassName: 'arbeid broyting',
    filterKeys: '0',
    color: arbeidsOptionsFarger.broyting,
  },
  {
    label: 'Spreder',
    labelKey: utstyrsloggNavnKeys.spreder,
    value: false,
    legendClassName: 'arbeid spreder',
    filterKeys: '2|2,2',
    color: arbeidsOptionsFarger.spreder,
  },
  {
    label: 'Brøyting og spreder',
    labelKey: utstyrsloggNavnKeys.broytingOgSpreder,
    value: false,
    legendClassName: 'arbeid broyting-og-spreder',
    filterKeys: '0,2|2,0',
    color: arbeidsOptionsFarger.broytingOgSpreder,
  },
  {
    label: 'Renhold',
    labelKey: utstyrsloggNavnKeys.renhold,
    value: false,
    legendClassName: 'arbeid renhold',
    filterKeys: '3|3,3',
    color: arbeidsOptionsFarger.renhold,
  },
  {
    label: 'Tomkjøring',
    labelKey: utstyrsloggNavnKeys.tomkjoring,
    value: false,
    legendClassName: 'arbeid tomkjoring',
    filterKeys: null,
    color: arbeidsOptionsFarger.tomkjoring,
  },
  {
    label: 'Strøing',
    labelKey: utstyrsloggNavnKeys.stroing,
    value: false,
    legendClassName: 'arbeid stroing',
    filterKeys: '8',
    color: arbeidsOptionsFarger.stroing,
  },
  {
    label: 'Kantklipping',
    labelKey: utstyrsloggNavnKeys.kantklipping,
    value: false,
    legendClassName: 'arbeid kantklipping',
    filterKeys: '10',
    color: arbeidsOptionsFarger.kantklipping,
  },
];

export const seksjonOptions = [
  { label: 'Vei', labelKey: seksjonNavnKeys.vei, value: false },
];

export const getLatestTrack = (spor) => {
  let latest = 0;
  spor.forEach((f) => {
    if (f.properties && f.properties.tidsstempelSluttpunkt > latest) {
      latest = f.properties.tidsstempelSluttpunkt;
    }
  });
  return latest;
};
