import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router';
import KalenderPeriodeVelger from '../KalenderPeriodeVelger/KalenderPeriodeVelger';
import GeoSuggestInput from '../GeoSuggestInput/GeoSuggestInput';
import CollapsibleSection from './CollapsibleSection.tsx';
import CheckboxGroup from './CheckboxGroup';
import CollapsibleOmradeGroup from './CollapsibleOmradeGroup';
import {
  STANDARD_DATETIME_FORMAT,
  getStandardFilterFormattedDateTime,
} from '../../utils/dateUtils';
import {
  buildFilterOmradeOptions,
  getCheckedOmradeLabel,
  getCheckedBydelLabel,
} from '../../state/filterOmradeOptions';
import Translate from '../../Locale/Translate';
import { StatiskeDataContext } from '../Common/StatiskeDataProvider';
import { LocaleContext } from '../../Locale/LocaleProvider';
import SporingsdataInfo from '../Driftskart/SporingsdataInfo.tsx';

const EntrepenoerkontrollFilter = ({
  filter,
  updateFilter,
  avviktyper,
  getKartdata,
  match,
  history,
  calendarId,
}) => {
  const { statiskeData } = useContext(StatiskeDataContext);
  const localeContext = useContext(LocaleContext);

  useEffect(() => {
    const routeFraDato =
      match &&
      match.params &&
      match.params.fradato &&
      moment(match.params.fradato, STANDARD_DATETIME_FORMAT);

    const routeTilDato =
      match &&
      match.params &&
      match.params.tildato &&
      moment(match.params.tildato, STANDARD_DATETIME_FORMAT);

    if (!routeFraDato && !routeTilDato) return;

    updateFilter({
      datoFra: routeFraDato.format(),
      datoTil: routeTilDato.format(),
      autoloadKartdata:
        history.location &&
        history.location.state &&
        history.location.state.from === '/' &&
        moment.duration(routeTilDato.diff(routeFraDato)).asHours() < 25,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  useEffect(() => {
    if (!avviktyper || avviktyper == null) return;
    updateFilter({
      feilmeldingOptions: avviktyper.map((at) => {
        return {
          id: at.id,
          label:
            localeContext.currentLocale.messages[`avviktype.${at.id}`] ||
            at.navn,
          value: false,
          legendClassName: `avviktype id-${at.id}`,
        };
      }),
    });
  }, [avviktyper, updateFilter, localeContext.currentLocale]);

  useEffect(() => {
    if (filter.autoloadKartdata === true) {
      getKartdata(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.autoloadKartdata]);

  const updateOmradeFilter = (o) => {
    const driftsomradenavn = getCheckedOmradeLabel(o);
    const bydelnavn = getCheckedBydelLabel(o);

    updateFilter({
      driftsomrade: driftsomradenavn,
      bydel: bydelnavn,
    });
  };

  const getFormattedEntreprenor = (sporingId) => {
    if (!statiskeData || !statiskeData.entreprenorselskaper) return sporingId;
    const selskap = statiskeData.entreprenorselskaper.find((s) =>
      s?.sporingId?.includes(sporingId)
    );
    return (selskap && `${selskap.navn} (${sporingId})`) || sporingId;
  };

  const getSporingId = (entreprenorselskapNavn) => {
    if (!statiskeData || !statiskeData.entreprenorselskaper)
      return entreprenorselskapNavn;
    const selskap = statiskeData.entreprenorselskaper.find(
      (s) => s.navn === entreprenorselskapNavn
    );
    return (selskap && selskap.sporingId) || entreprenorselskapNavn;
  };

  const maskinOptions = filter.maskinIdOptions.map((mo) => ({
    ...mo,
    visible:
      filter.entreprenorOptions.every((o) => o.value === false) ||
      filter.entreprenorOptions
        .filter((eo) => eo.value === true)
        .map((eo) => getSporingId(eo.kontraktNummer))
        .includes(mo.kontraktNummer),
  }));

  const maskinLabel = `${
    localeContext.currentLocale.messages['filter.section-label.maskinid']
  } ${
    // eslint-disable-next-line no-nested-ternary
    maskinOptions.length > 0
      ? maskinOptions.filter((item) => item.value === true).length > 0
        ? `(${maskinOptions.filter((item) => item.value === true).length})`
        : `(${maskinOptions.filter((item) => item.visible).length})`
      : ''
  }`;

  return (
    <div className="entrepenoerkontroll-filter">
      <div className="entrepenoerkontroll-filter-row">
        <div className="entrepenoerkontroll-filter-row-section">
          <div className="driftskart-header-container medium-minus-only">
            <div className="driftskart-header">
              <h1>
                <Translate id="driftskart.heading" />
              </h1>
            </div>

            <div className="driftskart-sub-header">
              <SporingsdataInfo shouldUpdate />
            </div>
          </div>
          <h3>
            <Translate id="filter.sted" />
          </h3>
          <GeoSuggestInput
            placeholder={
              localeContext.currentLocale.messages['filter.sted.placeholder']
            }
            onChange={(location) => {
              updateFilter({
                latitude: location.latitude,
                longitude: location.longitude,
                adresse: location.adresse,
              });
            }}
            value={{
              lat: filter.latitude,
              lng: filter.longitude,
              adresse: filter.adresse,
            }}
          />
        </div>

        <div className="entrepenoerkontroll-filter-row-section entrepenoerkontroll-filter-datetime">
          <KalenderPeriodeVelger
            fra={filter.datoFra}
            til={filter.datoTil}
            onChange={(f, t) => {
              const fra = getStandardFilterFormattedDateTime(f);
              const til = getStandardFilterFormattedDateTime(t);
              history.push(`/kart/${fra}/${til}`);
              updateFilter({
                datoFra: fra,
                datoTil: til,
              });
            }}
            showTimepicker
            displayDayMonthYearButtons={false}
            calendarId={calendarId}
          />
        </div>
        <CollapsibleSection
          label={
            localeContext.currentLocale.messages['filter.section-label.seksjon']
          }
        >
          <CheckboxGroup
            options={filter.seksjonOptions}
            onChange={(o) => updateFilter({ seksjonOptions: o })}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label={
            localeContext.currentLocale.messages[
              'filter.section-label.arbeidstype'
            ]
          }
        >
          <CheckboxGroup
            options={filter.arbeidOptions}
            onChange={(o) => updateFilter({ arbeidOptions: o })}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label={
            localeContext.currentLocale.messages['filter.section-label.omrade']
          }
        >
          <CollapsibleOmradeGroup
            driftsomrader={buildFilterOmradeOptions(
              filter.driftsomrade,
              filter.bydel
            )}
            onChange={(o) => updateOmradeFilter(o)}
          />
        </CollapsibleSection>

        {filter.entreprenorOptions?.length > 1 && (
          <CollapsibleSection
            label={
              localeContext.currentLocale.messages[
                'filter.section-label.entreprenorselskap'
              ]
            }
          >
            <CheckboxGroup
              options={filter.entreprenorOptions.map((item) => {
                return {
                  ...item,
                  label: getFormattedEntreprenor(item.kontraktNummer),
                };
              })}
              onChange={(o) =>
                updateFilter({
                  entreprenorOptions: o.map((item) => {
                    return { ...item, label: getSporingId(item.label) };
                  }),
                  maskinIdOptions: filter.maskinIdOptions.map((o) => ({
                    ...o,
                    value: false,
                  })),
                })
              }
            />
          </CollapsibleSection>
        )}

        <CollapsibleSection collapsed label={maskinLabel}>
          <CheckboxGroup
            options={maskinOptions}
            onChange={(o) => updateFilter({ maskinIdOptions: o })}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label={
            localeContext.currentLocale.messages[
              'filter.section-label.feilmeldinger'
            ]
          }
        >
          <CheckboxGroup
            options={filter.feilmeldingOptions}
            onChange={(o) => updateFilter({ feilmeldingOptions: o })}
          />
        </CollapsibleSection>
      </div>
    </div>
  );
};

EntrepenoerkontrollFilter.propTypes = {
  updateFilter: PropTypes.func.isRequired,
  getKartdata: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    datoFra: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    datoTil: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    tiltak: PropTypes.string,
  }),
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  calendarId: PropTypes.string,
};

EntrepenoerkontrollFilter.defaultProps = {
  filter: {
    datoFra: '',
    datoTil: '',
    tiltak: '',
    sporId: '',
    tiltakOptions: [],
    sporIdOptions: [],
  },
  calendarId: 'defaultId',
};

export default withRouter(EntrepenoerkontrollFilter);
