import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NyInnmelding from './NyInnmelding';
import { toastError } from '../../state/toast';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toastError,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(NyInnmelding);
