import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Hva.css';
import MapInput from '../MapInput/MapInput';
import httpClient from '../../api/httpClient';

const InnmeldingFormMap = (props) => {
  const { innmelding, setInnmelding } = props;
  const [isSearchingLocation, setIsSearchingLocation] = useState(false);

  const onLocationSelected = (e) => {
    setIsSearchingLocation(true);
    const posisjon = e.latlng;
    if (posisjon) {
      httpClient
        .getBydelOgDriftsomrade(posisjon.lat, posisjon.lng)
        .then((resp) => {
          setIsSearchingLocation(false);
          setInnmelding({
            ...innmelding,
            latitude: !posisjon ? null : posisjon.lat,
            longitude: !posisjon ? null : posisjon.lng,
            adresse: null,
            bydel: resp.bydel,
            omrade: resp.driftsomrade,
          });
        });
    }
  };

  return (
    <>
      <div
        id="innmelding-form-map"
        className={`${isSearchingLocation ? 'wait-cursor' : ''}`}
      >
        <MapInput
          label="Velg posisjon"
          value={
            innmelding.latitude && innmelding.longitude
              ? {
                  lat: innmelding.latitude,
                  lng: innmelding.longitude,
                  adresse: innmelding.adresse,
                }
              : {}
          }
          onClick={onLocationSelected}
        />
      </div>
    </>
  );
};

InnmeldingFormMap.propTypes = {
  innmelding: PropTypes.shape({}).isRequired,
  setInnmelding: PropTypes.func.isRequired,
};

export default InnmeldingFormMap;
