/* eslint-disable no-use-before-define */
import httpClient from '../api/httpClient';
import { toastError } from './toast';

export default function reducer(state = { loading: false }, action = {}) {
  switch (action.type) {
    case GET_STATISKE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        entreprenorselskaper: action.payload.entreprenorselskaper,
        statuskoder: action.payload.statuskoder,
        driftsomrader: action.payload.driftsomrader,
        bydeler: action.payload.bydeler,
        feiltypeHierarki: action.payload.feiltypeHierarki,
        kontrakter: action.payload.kontrakter,
      };
    case GET_STATISKE_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_STATISKE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

const GET_STATISKE_DATA_REQUEST = 'statiskeData/GET/REQUEST';
const GET_STATISKE_DATA_SUCCESS = 'statiskeData/GET/SUCCESS';
const GET_STATISKE_DATA_ERROR = 'statiskeData/GET/ERROR';
export function getStatiskeData() {
  return async (dispatch) => {
    dispatch({ type: GET_STATISKE_DATA_REQUEST });
    try {
      const data = await httpClient.getStatiskeData();
      dispatch({
        type: GET_STATISKE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: GET_STATISKE_DATA_ERROR, payload: error });
      dispatch(toastError(error));
    }
  };
}
