import React, { useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import Translate from '../../Locale/Translate';
import { UserContext } from '../../User/UserProvider';

const Unauthorized = (): JSX.Element => {
  const auth = useAuth();
  const history = useHistory();
  const { login } = useContext(UserContext);

  function redirectTilOneLogin() {
    login();
  }

  if (auth.isAuthenticated) {
    // redirect til landingsside
    history.push('/');
  }

  return (
    <a
      href="#"
      onClick={redirectTilOneLogin}
      style={{
        paddingTop: '40px',
        width: 'auto',
        textDecoration: 'underline',
      }}
    >
      <Translate
        defaultMessage="Logg inn i Bymelding her."
        id="unauth.loginBymelding"
      />
    </a>
  );

  // const bymeldingClient = getBymeldingClientUrl();
  // return (
  //   <div
  //     style={{
  //       margin: '40px',
  //       paddingTop: '40px',
  //       width: 'auto',
  //     }}
  //   >
  //     <p
  //       style={{
  //         fontSize: '25px',
  //         fontWeight: 'bold',
  //       }}
  //     >
  //       <Translate
  //         defaultMessage="Denne tjenesten er under utvikling."
  //         id="unauth.unauthMessage_1"
  //       />
  //     </p>
  //     <p
  //       style={{
  //         fontSize: '20px',
  //       }}
  //     >
  //       <Translate
  //         defaultMessage="Noen brukere må derfor logge inn flere steder får å få tilgang til driftssiden."
  //         id="unauth.unauthMessage_2"
  //       />
  //       <br />
  //       <br />
  //       <a
  //         href={`${bymeldingClient}/login`}
  //         style={{
  //           paddingTop: '40px',
  //           width: 'auto',
  //           textDecoration: 'underline',
  //         }}
  //       >
  //         <Translate
  //           defaultMessage="Logg inn i Bymelding her."
  //           id="unauth.loginBymelding"
  //         />
  //       </a>
  //       <br />
  //       <br />
  //       <Link
  //         style={{
  //           paddingTop: '40px',
  //           width: 'auto',
  //           textDecoration: 'underline',
  //         }}
  //         to="login"
  //       >
  //         <Translate
  //           defaultMessage="Logg inn i Drift og vedlikehold her."
  //           id="unauth.loginEnk"
  //         />
  //       </Link>
  //     </p>
  //   </div>
  // );
};

export default Unauthorized;
