import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Translate from '../../Locale/Translate';
import OmradeOptions from '../../state/filterOmradeOptions';
import { arbeidOptions } from '../../utils/sporUtils';
import { LocaleContext } from '../../Locale/LocaleProvider';
import {
  getJanuary1stThisYear,
  getDecember31stThisYear,
} from '../../utils/dateUtils';
import KalenderPeriodeVelger from '../KalenderPeriodeVelger/KalenderPeriodeVelger';

function Filter(props) {
  const { statiskeData, valgtGruppe, filter, onFilterChanged } = props;
  const localeContext = useContext(LocaleContext);
  const [fraDato, setFraDato] = useState(getJanuary1stThisYear());
  const [tilDato, setTilDato] = useState(getDecember31stThisYear());

  const hierarki = statiskeData && statiskeData.feiltypeHierarki;

  const setFraTilDato = (fra, til) => {
    const oppdatertFilter = { ...filter };

    if (fraDato !== fra || tilDato !== til) {
      setFraDato(fra);
      setTilDato(til);
      oppdatertFilter.fraDato = fra;
      oppdatertFilter.tilDato = til;
    } else {
      oppdatertFilter.fraDato = null;
      oppdatertFilter.tilDato = null;
    }

    onFilterChanged(oppdatertFilter);
  };

  const setFilter = (element, filterelement) => {
    const oppdatertFilter = { ...filter };
    oppdatertFilter.fraDato = null;
    oppdatertFilter.tilDato = null;

    oppdatertFilter[filterelement] =
      element.value === null
        ? []
        : [{ id: element.value, navn: element.label }];

    if (filterelement === 'omrader') oppdatertFilter.isOmradeValgt = true; // informere om at filteret skal inkludere alle omr�der

    onFilterChanged(oppdatertFilter);
  };

  const getOmrader = () => {
    return [
      {
        value: '00000000-0000-0000-0000-000000000000',
        label:
          localeContext.currentLocale.messages[
            'statistikk.header.filter.omrader.alle-elementer'
          ],
      },
      ...OmradeOptions.map((omrade) => {
        return {
          label: omrade.label,
          value: omrade.kartlagsnavn,
        };
      }),
    ];
  };

  const getArbeidstyper = () => {
    return [
      {
        value: '00000000-0000-0000-0000-000000000000',
        label:
          localeContext.currentLocale.messages[
            'statistikk.header.filter.type-arbeid.alle-elementer'
          ],
      },
      ...arbeidOptions
        .filter((type) => type.filterKeys !== null)
        .map((type) => {
          return {
            label:
              localeContext.currentLocale.messages[type.labelKey] || type.label,
            value: type.filterKeys,
          };
        }),
    ];
  };

  const getValgtVerdi = (filterelement) => {
    return filter && filter[filterelement].length > 0
      ? {
          value: filter[filterelement][0].id,
          label: filter[filterelement][0].navn,
        }
      : null;
  };

  return (
    <>
      {hierarki && hierarki.kategorier && (
        <div className="statistikk-filter">
          <div className="statistikk-filter-element diagram-container-datovelgere">
            <KalenderPeriodeVelger
              fra={fraDato}
              til={tilDato}
              isDeaktivert={valgtGruppe === null}
              onChange={(fra, til) => setFraTilDato(fra, til)}
              displayDayMonthYearButtons
            />
          </div>
          <Select
            className="statistikk-filter-element"
            placeholder={
              <Translate id="statistikk.header.filter.kategorier.tittel" />
            }
            isDisabled={valgtGruppe === null}
            options={hierarki.kategorier
              .filter((kategori) => {
                return (
                  valgtGruppe !== 'drift' ||
                  [
                    '97867e05-f508-4457-8a67-f9c8f8dd236c', // vinterdrift
                    'd761fcec-371c-4017-86fb-7bbbb30338f0', // renhold
                  ].indexOf(kategori.id) >= 0
                );
              })
              .map((kategori) => {
                return {
                  value: kategori.id,
                  label:
                    localeContext.currentLocale.messages[
                      `kategori.${kategori.id}`
                    ] || kategori.navn,
                };
              })}
            value={getValgtVerdi('kategorier')}
            onChange={(e) => setFilter(e, 'kategorier')}
          />
          <Select
            className="statistikk-filter-element"
            placeholder={
              <Translate id="statistikk.header.filter.omrader.tittel" />
            }
            isDisabled={valgtGruppe === null}
            options={getOmrader()}
            value={getValgtVerdi('omrader')}
            onChange={(e) => setFilter(e, 'omrader')}
          />
          <Select
            className="statistikk-filter-element"
            placeholder={
              <Translate id="statistikk.header.filter.type-arbeid.tittel" />
            }
            isDisabled={valgtGruppe === null}
            options={getArbeidstyper()}
            value={getValgtVerdi('arbeidstyper')}
            onChange={(e) => setFilter(e, 'arbeidstyper')}
          />
        </div>
      )}
    </>
  );
}

Filter.propTypes = {
  statiskeData: PropTypes.shape({}).isRequired,
  valgtGruppe: PropTypes.string,
  filter: PropTypes.shape({}).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  valgtGruppe: null,
};

export default Filter;
