import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Collapse } from 'reactstrap';

const CollapsibleOmradeGroup = (props) => {
  const { driftsomrader, onChange } = props;

  const uncheckOmrade = (omrade) => {
    return {
      ...omrade,
      value: false,
      bydeler: omrade.bydeler.map((bydel) => ({ ...bydel, value: false })),
    };
  };

  const localOnChange = (omrade) => {
    const updatedOptions = driftsomrader.map((item) => {
      if (item.label === omrade.label) {
        return omrade;
      }
      return uncheckOmrade(item);
    });

    if (onChange) {
      onChange(updatedOptions);
    }
  };

  const localOnChildChange = (omrade, bydel) => {
    const bydeler = omrade.bydeler.map((item) => {
      if (item.label === bydel.label) {
        return bydel;
      }
      return { ...item, value: false };
    });

    localOnChange({ ...omrade, bydeler });
  };

  return (
    <div className="collapsible-omrade-group">
      {driftsomrader.map((omrade) => (
        <FormGroup check key={omrade.label}>
          <Label check>
            <Input
              name="omrade"
              type="checkbox"
              checked={omrade.value}
              onChange={(e) => {
                localOnChange({ ...omrade, value: e.target.checked });
              }}
            />
            {omrade.label}
          </Label>
          <Collapse isOpen={omrade.value}>
            {omrade.bydeler.map((bydel) => (
              <FormGroup check key={bydel.label}>
                <Label check>
                  <Input
                    name="bydel"
                    type="checkbox"
                    checked={bydel.value}
                    onChange={(e) => {
                      localOnChildChange(omrade, {
                        ...bydel,
                        value: e.target.checked,
                      });
                    }}
                  />
                  {bydel.label}
                </Label>
              </FormGroup>
            ))}
          </Collapse>
        </FormGroup>
      ))}
    </div>
  );
};

CollapsibleOmradeGroup.propTypes = {
  driftsomrader: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CollapsibleOmradeGroup;
