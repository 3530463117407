/* eslint-disable no-use-before-define */
import httpClient from '../api/httpClient';
import { toastError } from './toast';

export default function reducer(
  state = {
    avvik: {},
    loading: false,
  },
  action = {}
) {
  switch (action.type) {
    case GET_AVVIK_REQUEST:
      return {
        ...state,
        loading: true,
        avvik: {},
      };
    case GET_AVVIK_SUCCESS:
      return {
        ...state,
        loading: false,
        avvik: action.payload,
      };
    case PUT_AVVIK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_AVVIK_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

const PUT_AVVIK_REQUEST = 'avvik/PUT/REQUEST';
const PUT_AVVIK_SUCCESS = 'avvik/PUT/SUCCESS';
const PUT_AVVIK_ERROR = 'avvik/PUT/ERROR';
export function updateAvvik(id, avvik) {
  return async (dispatch) => {
    dispatch({ type: PUT_AVVIK_REQUEST });
    try {
      const data = await httpClient.updateAvvik(id, avvik);
      dispatch({
        type: PUT_AVVIK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: PUT_AVVIK_ERROR, payload: error });
      dispatch(toastError(error));
    }
  };
}

const GET_AVVIK_REQUEST = 'avvik/GET/REQUEST';
const GET_AVVIK_SUCCESS = 'avvik/GET/SUCCESS';
const GET_AVVIK_ERROR = 'avvik/GET/ERROR';
export function getAvvik(id) {
  return async (dispatch) => {
    dispatch({ type: GET_AVVIK_REQUEST });
    try {
      const data = await httpClient.getAvvik(id);
      dispatch({
        type: GET_AVVIK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: GET_AVVIK_ERROR, payload: error });
      dispatch(toastError(error));
    }
  };
}
