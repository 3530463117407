import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
} from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FaBell } from 'react-icons/fa';
import VarslingListe from './VarslingListe';
import httpClient from '../../api/httpClient';
import { UserContext } from '../../User/UserProvider.tsx';

const NEW_VARSLINGER = 'NEW_VARSLINGER';
const UPDATE_VARSLINGER = 'UPDATE_VARSLINGER';
const RESET = 'RESET';

const Varslinger = () => {
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);
  const intervalRef = useRef(undefined);
  const sisteSettIdRef = useRef(0);

  const initState = () => {
    return { varslinger: [], sisteSettId: 0 };
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case NEW_VARSLINGER: {
        const varslinger = [...action.data, ...state.varslinger];
        const sisteSettId =
          varslinger.length > 0
            ? varslinger
                .map((m) => m.id)
                .reduce((a, b) => {
                  return Math.max(a, b);
                })
            : 0;
        sisteSettIdRef.current = sisteSettId;
        return { varslinger, sisteSettId };
      }
      case UPDATE_VARSLINGER:
        return { varslinger: action.data };
      case RESET:
        return initState();
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initState());
  const antallUleste = state.varslinger.filter((m) => m.ulest === true).length;

  const getVarslinger = async () => {
    try {
      setLoading(true);
      const resp = await httpClient.getVarslinger(sisteSettIdRef.current);
      if (resp.length > 0) dispatch({ type: NEW_VARSLINGER, data: resp });
    } finally {
      setLoading(false);
    }
  };

  const oppdaterSisteLest = async (id) => {
    try {
      setLoading(true);
      const sisteLestId = id || state.sisteSettId;
      await httpClient.putVarslingIndex(sisteLestId);
      const data = state.varslinger.map((v) => {
        return { ...v, ulest: v.id > sisteLestId };
      });
      dispatch({ type: UPDATE_VARSLINGER, data });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const roles = user ? user.getRoles() : [];
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!roles || roles.length <= 0) return () => {};
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    dispatch({ type: RESET });
    getVarslinger();
    intervalRef.current = setInterval(getVarslinger, 120000);

    return () => clearInterval(intervalRef.current);
  }, [user]);

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav className="varslinger">
        <span>
          <FaBell size={21} />
          {antallUleste > 0 && (
            <div className="varslinger-ulest-antall">{antallUleste}</div>
          )}
        </span>
      </DropdownToggle>
      <DropdownMenu right className="varslinger">
        <div id="varslinger">
          <div className="varsling-liste-container">
            <VarslingListe
              varslinger={state.varslinger}
              oppdaterSisteLest={oppdaterSisteLest}
              loading={loading}
            />
          </div>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Varslinger;
