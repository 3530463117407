import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../User/UserProvider.tsx';

const InternalBymUserRoute = (props) => {
  const { component: Comp, ...rest } = props;
  const user = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={() => {
        if (user && user.isInternBymbruker()) {
          return <Comp {...props} />;
        }
        if (user && !user.isInternBymbruker()) {
          return <div>Access denied</div>;
        }
        return (
          <Redirect
            to={{
              pathname: '/unauth',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
export default InternalBymUserRoute;
