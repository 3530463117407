import axios from 'axios';
import moment from 'moment';
import { getFilterFormattedDateTime } from '../utils/dateUtils';
import { formatBounds } from '../utils/kartdataFilterUtils';
import * as urls from './urls';

class HttpClient {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static setDefaultCredentials(tokenData) {
    if (tokenData) {
      axios.defaults.headers.common.Authorization = `Bearer ${tokenData}`;
    }
  }

  // static loginUser(email, password, serviceId) {
  //   return axios.post(`${urls.autentiseringBaseUrl}${urls.login}`, {
  //     epost: email,
  //     passord: password,
  //     serviceId,
  //     // serviceId: BYMELDING_SERVICE_ID,
  //   });
  // }

  // static logOutUser() {
  //   axios.defaults.headers.common.Authorization = '';
  // }

  static updateErrorInStore(error) {
    return new Promise((resolve, reject) => reject(error));
  }

  // static validateToken() {
  //   axios
  //     .get(`${urls.autentiseringBaseUrl}${urls.validateToken}`)
  //     .catch(this.updateErrorInStore);
  // }

  // Kartdata
  // async getKartData(datoFra, datoTil, bounds, inkluderSporUtenUtstyrlogg) {
  static async getKartData(queryOptions) {
    const datoFra = moment(queryOptions.fra).format();
    const datoTil = moment(queryOptions.til).format();
    const bounds = formatBounds(queryOptions.bounds);
    const arbeidstyper = queryOptions.arbeidstyper
      ? queryOptions.arbeidstyper
          .map((x) => `arbeidstyper=${x != null ? x : ''}`)
          .join('&')
      : 'arbeidstyper=';
    const kontraktnummere = queryOptions.kontraktnummere
      ? queryOptions.kontraktnummere
          .map((x) => `kontraktnummere=${x}`)
          .join('&')
      : 'kontraktnummere=';
    const omrade = queryOptions.omrade || '';
    const bydel = queryOptions.bydel || '';

    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getKartData(
          encodeURIComponent(datoFra),
          encodeURIComponent(datoTil),
          bounds,
          arbeidstyper,
          kontraktnummere,
          omrade,
          bydel
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // Detaljer for valgt spor
  static async getSpordetaljer(kilde, sporId, latitude, longitude) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.sporDetaljer(
          kilde,
          sporId,
          latitude,
          longitude
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // Forbruk for valgt spor
  static async getForbrukForSpor(kilde, sporId) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.forbrukForSpor(kilde, sporId)}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // Siste registrerte spor i dataset
  static async getSporingsdataInfo() {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.sporingsdataInfo()}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getBydelOgDriftsomrade(latitude, longitude) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.bydelOgDriftsinfo(
          latitude,
          longitude
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // Avvik
  static async getStatiskeData() {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.statiskeData()}`,
        { headers: { Authorization: '' } }
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async postAvvikForm(form) {
    try {
      const url = `${urls.entrepenorkontrollUrl}${urls.postAvvik()}`;
      // const res = await axios.post(url, payload, config);

      const res = await axios({
        method: 'post',
        url,
        data: form,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });

      return Promise.resolve(res.data.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getAvvikListe(date) {
    const query = `?dato=${moment(date).format('YYYY-MM-DD HH:mm')}`;

    try {
      const response = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getAvvikListe()}${query}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getAvvikListeforPeriode(fra, til, omrade, bydel) {
    const query = `?datoFra=${moment(fra).format(
      'YYYY-MM-DD HH:mm'
    )}&datoTil=${moment(til).format('YYYY-MM-DD HH:mm')}&driftsomrade=${
      omrade || ''
    }&bydel=${bydel || ''}`;

    try {
      const response = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getAvvikListe()}${query}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getPublikummeldingerforPeriode(fra, til, omrade, bydel) {
    const query = `?datoFra=${moment(fra).format(
      'YYYY-MM-DD HH:mm'
    )}&datoTil=${moment(til).format('YYYY-MM-DD HH:mm')}&driftsomrade=${
      omrade || ''
    }&bydel=${bydel || ''}`;

    try {
      const response = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getPublikummeldinger()}${query}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getAvvik(id) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.avvikId(id)}`
      );
      return Promise.resolve(res.data.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getAvvikPdf(id) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.avvikId(id)}/pdf`,
        { responseType: 'blob' }
      );
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateAvvik(id, avvik) {
    try {
      const res = await axios.put(
        `${urls.entrepenorkontrollUrl}${urls.avvikId(id)}`,
        avvik
      );
      return Promise.resolve(res.data.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async setAvvikStatus(id, status, kommentar) {
    try {
      const form = new FormData();
      form.set('status', status);
      form.set('kommentar', kommentar);

      const res = await axios.put(
        `${urls.entrepenorkontrollUrl}${`${urls.avvikId(id)}/status`}`,
        form
      );

      return Promise.resolve(res.data.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getAvvikEndringslogg(id) {
    try {
      const response = await axios.get(
        `${urls.entrepenorkontrollUrl}${`${urls.avvikId(id)}/endringslogg`}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // Kontroller

  static async postKontroller(dato, antallKontrollpunkter) {
    try {
      const res = await axios.post(`${urls.entrepenorkontrollUrl}kontroll`, {
        dato,
        AntallKontrollpunkter: antallKontrollpunkter,
      });
      return Promise.resolve(res.data.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateKontroller(dato, kontroller, kontrollId) {
    try {
      const res = await axios.put(
        `${urls.entrepenorkontrollUrl}kontroll/${kontrollId}`,
        {
          dato,
          AntallKontrollpunkter: kontroller,
          id: kontrollId,
        }
      );
      return Promise.resolve(res.data.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getKontroller() {
    try {
      const res = await axios.get(`${urls.entrepenorkontrollUrl}kontroll/`);
      return Promise.resolve(res.data.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // Statistikk
  static async getStatistikkVinterdrift(datoFra, datoTil) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkVinterdrift(
          datoFra,
          datoTil
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkAvvik(datoFra, datoTil, filter) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkAvvik(
          datoFra,
          datoTil,
          filter
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkAvvikPrBydel(datoFra, datoTil, filter) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkAvvikPrBydel(
          datoFra,
          datoTil,
          filter
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkAvvikPrEntreprenor(datoFra, datoTil, filter) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkAvvikPrEntreprenor(
          datoFra,
          datoTil,
          filter
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkAvviksfeiltypePrBydel(datoFra, datoTil, filter) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkAvviksfeiltypePrBydel(
          datoFra,
          datoTil,
          filter
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkAvviksfeiltypePrEntreprenor(
    datoFra,
    datoTil,
    filter
  ) {
    try {
      const res = await axios.get(
        `${
          urls.entrepenorkontrollUrl
        }${urls.getStatistikkAvviksfeiltypePrEntreprenor(
          datoFra,
          datoTil,
          filter
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkAvvikOgKontroller(datoFra, datoTil) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkAvvikOgKontroller(
          datoFra,
          datoTil
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkTiltakPrEntreprenor(datoFra, datoTil, filter) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkTiltakPrEntreprenor(
          datoFra,
          datoTil,
          filter
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkRessursbrukPrMateriale(datoFra, datoTil, filter) {
    try {
      const res = await axios.get(
        `${
          urls.entrepenorkontrollUrl
        }${urls.getStatistikkRessursbrukPrMateriale(datoFra, datoTil, filter)}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkMaskinerIDriftPrBydel(datoFra, datoTil, filter) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkMaskinerIDriftPrBydel(
          datoFra,
          datoTil,
          filter
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getStatistikkKontrollerPrMnd(datoFra, datoTil, filter) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getStatistikkKontrollerPrMnd(
          datoFra,
          datoTil,
          filter
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // Værdata
  static async getHistoriskeVaerdata(tidspunkt) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getHistoriskeVaerdata(
          getFilterFormattedDateTime(tidspunkt)
        )}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // Todo remove: obsolete.
  static async getEntreprenorselskaper() {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getEntreprenorselskaper()}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getKontrakter() {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getKontrakter()}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async startBymeldingImport() {
    try {
      const res = await axios.post(
        `${urls.entrepenorkontrollUrl}${urls.startBymeldingImport()}`,
        {}
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async putEntreprenorselskapSporingId(kontraktId, sporingId, aktiv) {
    try {
      const res = await axios.put(
        `${urls.entrepenorkontrollUrl}${urls.putEntreprenorselskapSporingId()}`,
        { kontraktId, sporingId, aktiv }
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getEndpoints() {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getEndpoints()}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getHealthcheck() {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getHealthcheck()}`
      );
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.response) {
        return Promise.resolve(err.response.data);
      }
      return Promise.reject(err);
    }
  }

  static async getVarslinger(sisteSettId) {
    try {
      const res = await axios.get(
        `${urls.entrepenorkontrollUrl}${urls.getVarslinger(sisteSettId)}`
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async putVarslingIndex(sisteLestId) {
    try {
      const res = await axios.put(
        `${urls.entrepenorkontrollUrl}${urls.putVarslingerIndex()}`,
        {
          meldingId: sisteLestId,
        }
      );
      return Promise.resolve(res.data.result);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async avvikVedleggSrcBase64(id, vedleggId) {
    const url = `${urls.entrepenorkontrollUrl}${urls.downloadAvvikVedleggBase64(
      id,
      vedleggId
    )}`;
    try {
      const res = await axios.get(url);
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  static async getMeldingsstatistikk(fra, til) {
    try {
      const res = await axios.get(
        `${urls.bymeldingStatistikkUrl}${urls.getMeldingsstatistikk(fra, til)}`
      );
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // NotificationsService

  static async getNotificationServiceData(klientKey) {
    try {
      const res = await axios.get(
        `${urls.notificationServiceBaseUrl}${urls.getNotificationServiceData(
          klientKey
        )}`
      );
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default HttpClient;
