import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { map, includes, find } from 'lodash';
import httpClient from '../../api/httpClient';
import Translate from '../../Locale/Translate';

const ImageUploader = (props) => {
  const {
    label,
    ingress,
    images,
    maxCount,
    onImagesAdded,
    onImageRemoved,
    validState,
  } = props;
  const imageformats = ['image/png', 'image/jpeg', 'image/jpg'];
  const [base64Bilder, setBase64Bilder] = useState([]);
  const [nyeBilder, setnyeBilder] = useState([]);

  const onImagesAddedLocal = (e) => {
    const { files } = e.target;
    const arr = [];

    if (files.length > 0) {
      map(files, (f, i) => {
        if (includes(imageformats, f.type) && i < maxCount) {
          arr.push(f);
        }
      });
    }
    if (arr.length > 0) {
      if (onImagesAdded) {
        onImagesAdded(arr);
      }
    }
  };

  const getBildeData = async (bilder) => {
    const bildeData = await Promise.all(
      bilder.map((bilde) =>
        httpClient.avvikVedleggSrcBase64(bilde.avvikId, bilde.id)
      )
    );
    const b = bildeData.map((item) => {
      const bilde = item?.data?.result;
      return {
        ...bilde,
        src: `data:${bilde?.contentType};base64, ${bilde?.base64Vedlegg}`,
      };
    });
    setBase64Bilder(b);
  };

  useMemo(() => {
    const savedImages = images.filter((b) => b.id);
    setnyeBilder(images.filter((b) => !b.id));
    if (savedImages.length !== base64Bilder.length) getBildeData(savedImages);
  }, [images]);

  const onImageRemovedLocal = (bilde) => {
    if (onImageRemoved) {
      onImageRemoved(bilde);
    }
  };

  let input;
  return (
    <div className={`image-uploader${(validState && ' invalid') || ''}`}>
      <div className="label">
        {label && <h3>{label}</h3>}
        <span className="image-counter">
          {images ? images.length : 0} / {maxCount}
        </span>
      </div>
      {ingress && <p>{ingress}</p>}
      <input
        type="file"
        name="file"
        id="file"
        multiple
        onChange={onImagesAddedLocal}
        ref={(node) => {
          input = node;
        }}
      />
      <Button className="image-uploader-button" onClick={() => input.click()}>
        <Translate
          id="innmelding-form.addPictureBtnLabel"
          defaultMessage="Last opp bilder"
        />
      </Button>
      <div className="image-uploader-preview">
        {map(nyeBilder, (bilde, i) => {
          const src = URL.createObjectURL(bilde);
          return (
            <div key={`imgctr-${i}`} className="image-container">
              <img key={`img${i}`} src={src} alt={`img${i}`} />
              <Button
                key={`<btn${i}`}
                close
                onClick={() => onImageRemovedLocal(bilde)}
              />
            </div>
          );
        })}
        {map(base64Bilder, (bilde, i) => {
          return bilde ? (
            <div key={`imgctr-${bilde.id}`} className="image-container">
              <img
                key={`${bilde.filnavn}`}
                src={bilde.src}
                alt={`img${bilde.id}`}
              />
              <Button
                key={`<btn${i}`}
                close
                onClick={() => {
                  const b = find(images, (img) => bilde.vedleggId === img.id);
                  onImageRemovedLocal(b);
                }}
              />
            </div>
          ) : (
            <div />
          );
        })}
      </div>
      {validState && (
        <div className="image-uploader-feedback">{validState}</div>
      )}
    </div>
  );
};

ImageUploader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ingress: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  images: PropTypes.arrayOf(PropTypes.shape([])),
  maxCount: PropTypes.number.isRequired,
  onImagesAdded: PropTypes.func,
  onImageRemoved: PropTypes.func,
  validState: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

ImageUploader.defaultProps = {
  ingress: undefined,
  label: undefined,
  onImagesAdded: undefined,
  onImageRemoved: undefined,
  images: [],
  validState: null,
};

export default ImageUploader;
