import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AntallKontroller from './AntallKontroller';
import {
  getKontroller,
  postKontroller,
  updateKontroller,
  dateChange,
} from '../../state/kontroller';

const mapStateToProps = (state) => ({
  dato: state.kontroller.dato,
  kontroller: state.kontroller.kontroller,
  loading: state.kontroller.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getKontroller,
      postKontroller,
      updateKontroller,
      dateChange,
    },

    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AntallKontroller);
