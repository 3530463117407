import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Meldinger from './Components/Meldinger';
import './DashboardPage.scss';

interface iDashboardPage {
  meldingsstatistikk: any;
  getDashboardMeldinger: any;
  periodeStart: string;
}

const DashboardPage = (props: iDashboardPage) => {
  const { meldingsstatistikk, getDashboardMeldinger, periodeStart } = props;
  const [timer, setTimer] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPeriodeStart = () => {
    return moment().startOf('month').subtract(1, 'year').format('YYYY-MM-DD');
  };

  useEffect(() => {
    getDashboardMeldinger(getPeriodeStart());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getDashboardMeldinger(getPeriodeStart());
    }, 1800000);
    return () => clearInterval(interval);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="dashboard-wrapper">
      <h1>Dashboard</h1>
      <div>
        <Meldinger
          periodeStart={
            new Date(moment(periodeStart, 'YYYY-MM-DD').toISOString())
          }
          data={meldingsstatistikk}
        />
      </div>
    </div>
  );
};

DashboardPage.propTypes = {};

export default DashboardPage;
