import React from 'react';

const RequiredInputStar = () => (
  <span
    className="input-required-start"
    style={{ color: '#c3190c', margin: '0 2px' }}
  >
    *
  </span>
);

export default RequiredInputStar;
