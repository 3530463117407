import React from 'react';
import { Pane, Polygon } from 'react-leaflet';

interface iOmradeGrenser {
  bounds: any;
}

const OmradeGrenser = ({ bounds }: iOmradeGrenser) => (
  <Pane name="custom-bounds">
    {bounds && (
      <>
        <Polygon
          positions={bounds}
          color="#000"
          fill={false}
          weight={4}
          className="driftskart-bound-polygon-bacground"
        />
        <Polygon
          positions={bounds}
          color="#fff"
          fill={false}
          weight={2}
          className="driftskart-bound-polygon"
        />
      </>
    )}
  </Pane>
);

export default OmradeGrenser;
