import React from 'react';
import { Popup } from 'react-leaflet';
// import L from 'leaflet';

import { ReactComponent as AvvikIkon } from '../../../images/map_pin_avvik_fra_kontrollør.svg';
import { ReactComponent as MapPin } from '../../../images/map-pin.svg';

const BymMarker = ({ position, showTooltip = false, ...popupProps }) => {
  return (
    <Popup
      position={{ lat: position.lat, lng: position.lng }}
      closeOnClick={false}
      closeButton={false}
      className="map-input-marker"
      // autoPanPadding={new L.Point(50, 50, true)}
      autoPan={false}
    >
      <div className="map-input-marker-container">
        <div className="map-input-marker-title">
          <span>Valgt plassering</span>
        </div>
        <div className="map-input-marker-content">
          <MapPin />
          <span title={position.adresse}>
            {position.adresse ? position.adresse : 'Henter adresse...'}
          </span>
        </div>
        <div className="map-input-marker-marker-icon">
          <AvvikIkon width="30px" height="51px" />
        </div>
      </div>
    </Popup>
  );
};

export default BymMarker;
