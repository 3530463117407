import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import moment from 'moment';
import Translate from '../../Locale/Translate';
import httpClient from '../../api/httpClient';

interface iSporingsdataInfo {
  shouldUpdate: boolean;
}

const SporingsdataInfo = (props: iSporingsdataInfo) => {
  const { shouldUpdate } = props;
  const [loading, setLoading] = useState(false);
  const [sporingsdataInfo, setSporingsdataInfo] = useState(undefined);

  const getSporingsdataInfo = async () => {
    setLoading(true);
    const resp = await httpClient.getSporingsdataInfo();
    setSporingsdataInfo(
      resp && resp.features[0]
        ? resp.features[0].attributes.sistOppdatertSpor
        : null
    );
    setLoading(false);
  };

  useEffect(() => {
    if (shouldUpdate) getSporingsdataInfo();
  }, [shouldUpdate]);

  return (
    <>
      {!loading && (
        <Translate
          id="driftskart.subheading"
          defaultMessage="Siste registrerte spor: {sporingsdataInfo}"
          values={{
            sporingsdataInfo: sporingsdataInfo
              ? moment(sporingsdataInfo).format('DD.MM.YY - HH:mm')
              : '',
          }}
        />
      )}
      <PulseLoader loading={loading} />
    </>
  );
};

export default SporingsdataInfo;
