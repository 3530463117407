import { find } from 'lodash';
import httpClient from '../api/httpClient';
import { toastError, toastSuccess } from './toast';
import { getStartDay, getEndDay } from '../utils/dateUtils';

export const initFilter = {
  sok: '',
  seksjoner: [],
  kategorier: [],
  kontrolltyper: [],
  statuskoder: [],
  alvorlig: [],
  omrader: [],
  avviktyper: [],
  entreprenorselskaper: [],
  kontrakter: [],
  feiltyper: [],
};

const initKontrollpanel = {
  date: { fra: getStartDay(), til: getEndDay() },
  avvikListe: [],
  selectedItem: null,
  loading: false,
  filter: {
    initFilter,
  },
};

const GETAVVIKLISTEREQUEST = 'avvikliste/GET/REQUEST';
const GETAVVIKLISTESUCCESS = 'avvikliste/GET/SUCCESS';
const GETAVVIKLISTEERROR = 'avvikliste/GET/ERROR';
const DATECHANGED = 'kontrollpanel/DATECHANGED';
const ITEMSELECTED = 'kontrollpanel/ITEMSELECTED';
const ITEMUPDATED = 'kontrollpanel/ITEMUPDATED';

const STATUSUPDATEREQUEST = 'kontrollpanel/STATUSUPDATEREQUEST';
const STATUSUPDATESUCCESS = 'kontrollpanel/STATUSUPDATESUCCESS';
const STATUSUPDATEERROR = 'kontrollpanel/STATUSUPDATEERROR';

const UPDATE_FILTER = 'kontrollpanel/UPDATE_FILTER';
const UPDATE_SELECTED_AVVIK_ENDRINGSLOGG =
  'kontrollpanel/UPDATE_SELECTED_AVVIK_ENDRINGSLOGG';

export default function reducer(state = initKontrollpanel, action = {}) {
  switch (action.type) {
    case GETAVVIKLISTESUCCESS:
      return {
        ...state,
        avvikListe: action.payload,
        loading: false,
      };
    case GETAVVIKLISTEERROR:
      return {
        ...state,
        loading: false,
      };
    case GETAVVIKLISTEREQUEST:
      return {
        ...state,
        // avvikListe: action.payload,
        loading: true,
      };
    case DATECHANGED:
      return {
        ...state,
        date: action.payload,
        selectedItem: null,
      };

    case ITEMSELECTED:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case ITEMUPDATED:
      return {
        ...state,
        avvikListe: action.payload,
      };

    case STATUSUPDATEREQUEST:
      return {
        ...state,
        loading: true,
      };

    case STATUSUPDATESUCCESS:
      return {
        ...state,
        avvikListe: state.avvikListe.map((item) => {
          if (action.payload.id !== item.id) return item;
          return {
            ...item,
            status: action.payload.status,
          };
        }),
        loading: false,
      };

    case UPDATE_SELECTED_AVVIK_ENDRINGSLOGG:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          historikk: action.payload,
        },
      };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
}

export function changeDate(fra, til, id) {
  return async (dispatch) => {
    dispatch({ type: DATECHANGED, payload: { fra, til } });
    dispatch({ type: GETAVVIKLISTEREQUEST });
    try {
      const data = await httpClient.getAvvikListeforPeriode(fra, til);
      dispatch({
        type: GETAVVIKLISTESUCCESS,
        payload: data.result,
      });
      const item = find(data.result, (a) => {
        return a.id === id;
      });
      dispatch({ type: ITEMSELECTED, payload: item });
    } catch (error) {
      dispatch({ type: GETAVVIKLISTEERROR, payload: error });
      dispatch(toastError(error));
    }
  };
}

export function selectItem(item) {
  return async (dispatch) => {
    dispatch({ type: ITEMSELECTED, payload: item });
  };
}

export function updateItem(item) {
  return async (dispatch) => {
    dispatch({ type: ITEMUPDATED, payload: item });
  };
}

export function setAvvikStatus(innmelding, status, kommentar) {
  return async (dispatch) => {
    dispatch({ type: STATUSUPDATEREQUEST, payload: innmelding });
    try {
      await httpClient.setAvvikStatus(innmelding.id, status, kommentar);
      const a = { ...innmelding, status };

      dispatch({
        type: STATUSUPDATESUCCESS,
        payload: a,
      });
      dispatch(selectItem);
      dispatch(
        toastSuccess({
          id: 'toast.successUpdateStatus',
          defaultMessage: 'Status ble oppdatert',
        })
      );
    } catch (error) {
      dispatch({ type: STATUSUPDATEERROR, payload: error });
      dispatch(toastError(error));
    }
  };
}

export function updateSelectedItemEndringslogg(innmelding) {
  return async (dispatch) => {
    try {
      const data = await httpClient.getAvvikEndringslogg(innmelding.id);

      dispatch({
        type: UPDATE_SELECTED_AVVIK_ENDRINGSLOGG,
        payload: data.result,
      });
    } catch (error) {
      dispatch(toastError(error));
    }
  };
}

const getPersistedFilter = () => {
  return { ...initFilter };
};

export function initStoredFilter() {
  const persistedFilter = getPersistedFilter();
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FILTER,
      payload: { ...persistedFilter },
    });
  };
}

export function updateFilter(filter) {
  // persistFilter({ ...filter });
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FILTER,
      payload: filter,
    });
  };
}
