import React from 'react';
import Translate from '../../Locale/Translate';
import ConfirmModal from '../Common/ConfirmModal';
import CommentsInput from '../CommentsInput/CommentsInput';

interface iAvvikBladeConfirmModal {
  bekreftStatusEndringSynlig: boolean;
  nyStatus: {
    kommentar: string;
  };
  valid: boolean;
  setBekreftStatusEndringSynlig: any;
  setStatusKommentar: any;
  onEndreStatus: any;
}

const AvvikBladeConfirmModal = (props: iAvvikBladeConfirmModal) => {
  const {
    bekreftStatusEndringSynlig,
    onEndreStatus,
    setBekreftStatusEndringSynlig,
    nyStatus,
    valid,
    setStatusKommentar,
  } = props;

  return (
    <ConfirmModal
      tittel={
        <Translate id="kontrollpanel.avvik-endre-status-modal-bekreft.tittel" />
      }
      tekst={
        <Translate id="kontrollpanel.avvik-endre-status-modal-bekreft.text" />
      }
      bekreftKnappetekst={
        <Translate id="kontrollpanel.avvik-endre-status-modal-bekreft.confirmButton" />
      }
      avbrytKnappetekst={
        <Translate id="kontrollpanel.avvik-endre-status-modal-bekreft.cancelButton" />
      }
      show={bekreftStatusEndringSynlig}
      onConfirm={onEndreStatus}
      onCancel={() => setBekreftStatusEndringSynlig(false)}
    >
      <br />
      <Translate id="kontrollpanel.avvik-endre-status-modal-bekreft.hjelpetekst">
        {(msg: string) => (
          <CommentsInput
            maxChars={300}
            label={
              <Translate id="kontrollpanel.avvik-endre-status-modal-bekreft.kommentar" />
            }
            placeholder={msg}
            text={nyStatus.kommentar}
            validState={valid}
            autofocus
            onChange={(text) => setStatusKommentar(text)}
          />
        )}
      </Translate>
    </ConfirmModal>
  );
};

export default AvvikBladeConfirmModal;
