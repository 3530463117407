export const omradeOptions = [
  {
    label: 'Nord',
    kartlagsnavn: 'NORDØST',
    value: false,
    bydeler: [
      {
        label: 'Grorud',
        kartlagsnavn: 'GRORUD',
        value: false,
      },
      {
        label: 'Stovner',
        kartlagsnavn: 'STOVNER',
        value: false,
      },
      {
        label: 'Bjerke',
        kartlagsnavn: 'BJERKE',
        value: false,
      },
      {
        label: 'Alna',
        kartlagsnavn: 'ALNA',
        value: false,
      },
      {
        label: 'Nordmarka',
        kartlagsnavn: 'NORDMARKA',
        value: false,
      },
      {
        label: 'Østmarka',
        kartlagsnavn: 'ØSTMARKA',
        value: false,
      },
    ],
  },
  {
    label: 'Sør/Øst',
    kartlagsnavn: 'SØRØST',
    value: false,
    bydeler: [
      {
        label: 'Østensjø',
        kartlagsnavn: 'ØSTENSJØ',
        value: false,
      },
      {
        label: 'Nordstrand',
        kartlagsnavn: 'NORDSTRAND',
        value: false,
      },
      {
        label: 'Søndre Nordstrand',
        kartlagsnavn: 'SØNDRE NORDSTRAND',
        value: false,
      },
      {
        label: 'Østmarka',
        kartlagsnavn: 'ØSTMARKA',
        value: false,
      },
    ],
  },
  {
    label: 'Vest',
    kartlagsnavn: 'NORDVEST',
    value: false,
    bydeler: [
      {
        label: 'Ullern',
        kartlagsnavn: 'ULLERN',
        value: false,
      },
      {
        label: 'Vestre Aker',
        kartlagsnavn: 'VESTRE AKER',
        value: false,
      },
      {
        label: 'Nordre Aker',
        kartlagsnavn: 'NORDRE AKER',
        value: false,
      },
      {
        label: 'Nordmarka',
        kartlagsnavn: 'NORDMARKA',
        value: false,
      },
    ],
  },
  {
    label: 'Sentrum',
    kartlagsnavn: 'SENTRUM',
    value: false,
    bydeler: [
      {
        label: 'Frogner',
        kartlagsnavn: 'FROGNER',
        value: false,
      },
      {
        label: 'Gamle Oslo',
        kartlagsnavn: 'GAMLE OSLO',
        value: false,
      },
      {
        label: 'Sentrum (bydel)',
        filterId: 'Sentrum',
        kartlagsnavn: 'SENTRUM',
        value: false,
      },
      {
        label: 'St.Hanshaugen',
        kartlagsnavn: 'ST.HANSHAUGEN',
        value: false,
      },
      {
        label: 'Sagene',
        kartlagsnavn: 'SAGENE',
        value: false,
      },
      {
        label: 'Grünerløkka',
        filterId: 'Grunerløkka',
        kartlagsnavn: 'GRUNERLØKKA',
        value: false,
      },
    ],
  },
];

export const omradeFlatList = () => {
  const acu = [];
  omradeOptions.forEach((curr) => {
    acu.push({ id: curr.filterId || curr.label, navn: curr.label });
    curr.bydeler.forEach((i) =>
      acu.push({
        id: i.filterId || i.label,
        navn: i.label,
        parent: curr.label,
      })
    );
  });
  return acu;
};

export const buildFilterOmradeOptions = (driftsomrade, bydel) => {
  const ret = omradeOptions.map((o) => {
    return {
      ...o,
      value: !!(driftsomrade && o.label === driftsomrade),
      bydeler: o.bydeler.map((b) => {
        return { ...b, value: !!(bydel && b.label === bydel) };
      }),
    };
  });
  return ret;
};

export const getCheckedOmrade = (options) => {
  if (!options) return undefined;
  return options.filter((o) => o.value === true)[0];
};

export const getCheckedBydel = (options) => {
  if (!options) return undefined;
  const omrade = getCheckedOmrade(options);
  if (!omrade) return undefined;
  return omrade.bydeler.filter((b) => b.value === true)[0];
};

export const getCheckedOmradeLabel = (options) => {
  if (!options) return undefined;
  const omrade = getCheckedOmrade(options);
  if (!omrade || !omrade.label) return undefined;
  return omrade.label;
};

export const getCheckedBydelLabel = (options) => {
  if (!options) return undefined;
  const bydel = getCheckedBydel(options);
  if (!bydel || !bydel.label) return undefined;
  return bydel.label;
};

export const getOmradeLocation = (driftsomradeNavn, bydelNavn) => {
  if (!driftsomradeNavn) return undefined;
  const omrade = omradeOptions.filter((o) => o.label === driftsomradeNavn)[0];
  if (!omrade) return undefined;
  const bydel = omrade.bydeler.filter((b) => b.label === bydelNavn)[0];
  return (bydel && bydel.position) || (omrade && omrade.position);
};

export const getDriftsomrade = (label) => {
  if (!label) return undefined;
  return omradeOptions.filter((o) => o.label === label)[0];
};

export const getBydel = (label) => {
  if (!label) return undefined;
  return omradeOptions
    .map((o) => o.bydeler)
    .reduce((acc, val) => [...acc, ...val], [])
    .filter((b) => b.label === label)[0];
};

export const getDriftsomradeKartlagsnavn = (label) => {
  if (!label) return undefined;
  const omrade = getDriftsomrade(label);
  if (!omrade) return undefined;
  return omrade.kartlagsnavn;
};

export const getBydelKartlagsnavn = (label) => {
  if (!label) return undefined;
  const bydel = getBydel(label);
  if (!bydel) return undefined;
  return bydel.kartlagsnavn;
};

export default omradeOptions;
