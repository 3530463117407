import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../../Locale/Translate';
import './Tabelldiagram.scss';

function Tabelldiagram(props) {
  const { data, ingenDataTekst } = props;
  const isNoData = () => !data || !data.kolonner || !data.tabelldata;

  if (isNoData())
    return (
      <div className="no-data h-100">
        {!ingenDataTekst && <Translate id="diagram-container.ingen-data" />}
        {ingenDataTekst && <span>{ingenDataTekst}</span>}
      </div>
    );

  // konverter alle kolonne-keys til lower case
  data.tabelldata = data.tabelldata.map((rad) => {
    const newRad = {};
    Object.keys(rad).forEach((key) => {
      newRad[key.toLowerCase()] = rad[key];
    });

    return newRad;
  });

  return (
    <table className="tabelldiagram-table">
      <thead>
        <tr>
          {data.kolonner.map((kolonne) => {
            return <th key={Math.random()}>{kolonne}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.tabelldata.map((rad) => {
          return (
            <tr key={Math.random()}>
              {data.kolonner.map((kol) => {
                return <td key={Math.random()}>{rad[kol.toLowerCase()]}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
      {data.total && (
        <tfoot>
          <tr>
            <td>Total</td>
            {data.typer.map((type) => {
              return <td>{data.total[type]}</td>;
            })}
          </tr>
        </tfoot>
      )}
    </table>
  );
}

Tabelldiagram.propTypes = {
  data: PropTypes.shape({}),
  ingenDataTekst: PropTypes.string,
};

Tabelldiagram.defaultProps = {
  data: {},
  ingenDataTekst: '',
};
export default Tabelldiagram;
