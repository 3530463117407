import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import './StatusInput.css';

const StatusInput = (props) => {
  const { label, onChange, botgrunnlag } = props;
  return (
    <div className="status-input">
      <div className="custom-control custom-checkbox">
        <Label className="custom-control-label" htmlFor="botgrunnlag">
          <input
            type="checkbox"
            className="custom-control-input"
            id="botgrunnlag"
            checked={botgrunnlag}
            onChange={(e) => onChange && onChange(e.target.checked)}
          />
          {label}
        </Label>
      </div>
    </div>
  );
};

StatusInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  botgrunnlag: PropTypes.bool.isRequired,
};

export default StatusInput;
