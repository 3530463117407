import { avvikStatuskoder } from '../../constants/status';

export const asd = 2;

export const inkluderAvvik = (avvik, filter) => {
  // hvis et meldingsnummer er oppgitt skal søket baseres kun på det
  if (filter.sok !== '') {
    return avvik.nummer && avvik.nummer.search(filter.sok) >= 0;
  }

  // skjul slettede avvik hvis ikke spesifikt angitt i filteret
  const statusSlettetId = avvikStatuskoder.find(
    (status) => status.key === 'Slettet'
  ).id;

  if (
    avvik.status === statusSlettetId &&
    filter.statuskoder.findIndex((e) => e.id === statusSlettetId) < 0
  )
    return false;

  if (
    !Object.keys(filter).some((key) => {
      return filter[key].length > 0;
    })
  ) {
    return true; // ingen filter definert, alle avvik er synlige
  }

  // hvis en filtertype er satt må det være match, ellers ignorer
  const seksjonMatch =
    filter.seksjoner &&
    (filter.seksjoner.length === 0 ||
      filter.seksjoner.findIndex((e) => e.id === avvik.seksjonId)) >= 0;

  const kategoriMatch =
    filter.kategorier &&
    (filter.kategorier.length === 0 ||
      filter.kategorier.findIndex((e) => e.id === avvik.kategoriId)) >= 0;

  const kontrolltypeMatch =
    filter.kontrolltyper &&
    (filter.kontrolltyper.length === 0 ||
      filter.kontrolltyper.findIndex((e) => e.id === avvik.arbeidstypeId)) >= 0;

  const avviktypeMatch =
    filter.avviktyper &&
    (filter.avviktyper.length === 0 ||
      filter.avviktyper.findIndex((e) => e.id === avvik.avviktypeId)) >= 0;

  const entreprenorselskapMatch =
    filter.entreprenorselskaper &&
    (filter.entreprenorselskaper.length === 0 ||
      filter.entreprenorselskaper.findIndex(
        (e) => e.id === avvik.kontrakt.selskapId
      )) >= 0;

  const kontraktMatch =
    filter.kontrakter &&
    (filter.kontrakter.length === 0 ||
      filter.kontrakter.findIndex((e) => e.id === avvik.kontraktId)) >= 0;

  const statusMatch =
    filter.statuskoder &&
    (filter.statuskoder.length === 0 ||
      filter.statuskoder.findIndex((e) => e.id === avvik.status)) >= 0;

  const alvorligMatch =
    filter.alvorlig &&
    (filter.alvorlig.length === 0 ||
      filter.alvorlig.findIndex((e) => e.id === avvik.botgrunnlag)) >= 0;

  const omradeMatch =
    filter.omrader &&
    (filter.omrader.length === 0 ||
      filter.omrader.findIndex((e) => e.id === avvik.bydel) >= 0);

  const feiltypeMatch =
    filter.feiltyper &&
    (filter.feiltyper.length === 0 ||
      filter.feiltyper.findIndex((e) => e.id === avvik.feiltypeId) >= 0);

  return (
    seksjonMatch &&
    kategoriMatch &&
    kontrolltypeMatch &&
    avviktypeMatch &&
    entreprenorselskapMatch &&
    kontraktMatch &&
    statusMatch &&
    alvorligMatch &&
    omradeMatch &&
    feiltypeMatch
  );
};
