// types
const ERROR = 'toast/ERROR';
const SUCCESS = 'toast/SUCCESS';
const CLEAR = 'toast/CLEAR';
const WARNING = 'toast/WARNING';

export default function reducer(
  state = { error: undefined, success: undefined, warning: undefined },
  action = {}
) {
  if (action.type === ERROR) {
    return {
      ...state,
      error: action.payload,
    };
  }
  if (action.type === SUCCESS) {
    return {
      ...state,
      success: action.payload,
    };
  }
  if (action.type === WARNING) {
    return {
      ...state,
      warning: action.payload,
    };
  }
  if (action.type === CLEAR) {
    return {
      ...state,
      error: null,
      success: null,
      warning: null,
    };
  }
  return state;
}
export function toastError(payload) {
  return { type: ERROR, payload };
}
export function toastSuccess(payload) {
  return { type: SUCCESS, payload };
}
export function toastWarning(payload) {
  return { type: WARNING, payload };
}
export function resetToast() {
  return { type: CLEAR };
}

export const errorSelector = (state) => state.toast.error;
export const warningSelector = (state) => state.toast.warning;
export const successSelector = (state) => state.toast.success;
