import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import map from 'lodash/map';
import filter from 'lodash/filter';
import Breadcrumb from './Breadcrumb';

const BreadCrumbContainer = ({ location, history }) => {
  const [expandedOnMobile, setExpandedOnMobile] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const onMobile = () => {
    return window.innerWidth < 768;
  };

  const createBreadcrumb = (
    name,
    id,
    link,
    active,
    displayBackBtn = false
  ) => ({
    link,
    outsideUrl: null,
    name,
    isActive: active,
    displayBackBtn,
    id,
  });

  const onBackClick = () => {
    history.goBack();
  };

  useEffect(() => {
    let paths = location.pathname.split('/');
    // remove the last element if there was a / at the end of the pathname
    paths =
      paths[paths.length - 1] === '' ? paths.slice(0, paths.length - 1) : paths;
    // remove the first element if the second one is an empty string which means that we are in the root of the website
    paths = paths[1] === '' ? paths.slice(1) : paths;
    const bc = map(paths, (item, index) => {
      let name = '';
      let link = '';

      const activeBreadcrumb = index + 1 === paths.length;
      switch (`/${item}`) {
        case '/':
          name = 'Startsiden';
          link = '/';
          return createBreadcrumb(name, 'startsiden', link, activeBreadcrumb);
        case '/kontrollpanel':
          name = 'Kontrollpanel';
          link = '/kontrollPanel';
          return createBreadcrumb(
            name,
            'kontrollpanel',
            link,
            activeBreadcrumb
          );
        case '/kart':
          name = 'kart';
          link = '/kart';
          return createBreadcrumb(name, 'kart', link, activeBreadcrumb);
        case '/statistikk':
          name = 'Statistikk';
          link = '/statistikk';
          return createBreadcrumb(name, 'statistikk', link, activeBreadcrumb);
        // case '/ny':
        //   name = 'Avvik';
        //   link = '/innmelding/ny';
        //   return createBreadcrumb(name, 'nytt_avvik', link, activeBreadcrumb);
        // case '/endre':
        //   name = 'Endre innmelding';
        //   link = '/innmelding/endre';
        //   return createBreadcrumb(
        //     name,
        //     'endre_innmelding',
        //     link,
        //     activeBreadcrumb
        //   );

        case '/innmelding_1':
          name = 'Avvik';
          link += '/innmelding_1';
          return createBreadcrumb(name, 'innmelding_1', link, activeBreadcrumb);

        case '/innmelding_2':
          name = 'Hva ble kontrollert?';
          link += '/innmelding_2';
          return createBreadcrumb(
            name,
            'innmelding_2',
            link,
            activeBreadcrumb,
            true
          );
        case '/innmelding_3':
          name = 'Hvor så du avviket?';
          link += '/innmelding_3';
          return createBreadcrumb(
            name,
            'innmelding_3',
            link,
            activeBreadcrumb,
            true
          );
        case '/innmelding_4':
          name = 'Legg til kommentar';
          link += '/innmelding_4';
          return createBreadcrumb(
            name,
            'innmelding_4',
            link,
            activeBreadcrumb,
            true
          );
        case '/bekreftet':
          name = 'Innmelding lagret';
          link += '/';
          return createBreadcrumb(name, 'bekreftet', link, activeBreadcrumb);

        default:
          return null;
        // name = item;
        // link = `/${item}`;
        // return createBreadcrumb(name, link, activeBreadcrumb);
      }
    });
    setBreadcrumbs(filter(bc, (i) => i !== null));
  }, [location, setBreadcrumbs]);

  return (
    <Breadcrumb
      onMobile={onMobile()}
      breadcrumbs={breadcrumbs}
      expandedOnMobile={expandedOnMobile}
      toggleExpanded={() => setExpandedOnMobile(!expandedOnMobile)}
      onBackClick={onBackClick}
    />
  );
};

BreadCrumbContainer.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(BreadCrumbContainer);
