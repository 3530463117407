import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DashboardPage from './DashboardPage.tsx';
import { getDashboardMeldinger } from '../../state/dashboard';

const mapStateToProps = (state) => ({
  meldingsstatistikk: state.dashboard.meldinger,
  periodeStart: state.dashboard.fra,
  loading: state.dashboard.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDashboardMeldinger,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
