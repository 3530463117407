export const BYMELDINGADMIN = 'bymelding-admin';

// BymRoller
export const BYMSERVICEAVDELINGADMIN = 'bymelding-serviceavdeling-admin';
export const BYMSERVICEAVDELING = 'bymelding-serviceavdeling';
export const BYMENTREPRENORADMIN = 'bymelding-entreprenor-admin';
export const BYMENTREPRENOR = 'bymelding-entreprenor';

// EnkRoller
export const ENTREPRENORKONTROLLADMIN = 'entreprenorkontroll-admin';
export const FORVALTER = 'entreprenorkontroll-forvalter';
export const KONTROLLOR = 'entreprenorkontroll-kontrollor';
export const ENTREPRENOR = 'entreprenorkontroll-entreprenor';
export const DASHBOARD = 'entreprenorkontroll-dashboard';
