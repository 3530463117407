/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { use100vh } from 'react-div-100vh';

import BymMap from '../Common/Map/BymMap';
import { wmslayers } from '../../utils/mapUtils';

import ForbrukSpor from './ForbrukSpor';
import httpClient from '../../api/httpClient';
import SporingPopup from './SporingPopup';
import AvvikPopup from './AvvikPopup.tsx';
import OmradeGrenser from './OmradeGrenser.tsx';
import { getAvvikIcon, getPublikummeldingIcon } from '../../utils/avvikUtils';
import { LocaleContext } from '../../Locale/LocaleProvider';
// import HighlightedGeoJSON from './HighlightedGeoJSON';
import SporDataPane from './SporDataPane';

const EntrepenoerkontrollMap = (props) => {
  const {
    showTooltip,
    autoBounds,
    layers,
    kartData,
    onMapViewportChanged,
    defaultCenter,
    selectedTrack,
    setSelectedTrack,
    bounds,
  } = props;
  const defaultZoomLevel = 15;
  const localeContext = useContext(LocaleContext);
  const [spordetaljer, setSpordetaljer] = useState(undefined);
  const [avvikdetaljer, setAvvikdetaljer] = useState(undefined);
  const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel);

  const localOnMapViewportChanged = (e) => {
    setZoomLevel(e.zoom);
    onMapViewportChanged(e);
  };

  const fetchSpordetaljer = async (kilde, sporId, latlng) => {
    if (kilde && sporId && latlng) {
      const resp = await httpClient.getSpordetaljer(
        kilde,
        sporId,
        latlng.lat,
        latlng.lng
      );
      setSpordetaljer(resp);
    }
  };

  const deselectTrack = () => {
    setSelectedTrack({ kilde: undefined, sporId: undefined });
  };

  useEffect(() => {
    if (
      selectedTrack &&
      selectedTrack.kilde &&
      selectedTrack.sporId &&
      selectedTrack.latlng
    ) {
      fetchSpordetaljer(
        selectedTrack.kilde,
        selectedTrack.sporId,
        selectedTrack.latlng
      );
    }
  }, [selectedTrack]);

  const kartOverlay = React.createRef();

  const getSelectedFeatures = (geoJSON, selected) => {
    if (
      geoJSON &&
      geoJSON != null &&
      selected &&
      selected != null &&
      selected.kilde &&
      selected.sporId
    ) {
      const features = geoJSON
        .filter((data) => data.kilde === selected.kilde)
        .map((data) =>
          data.features.filter(
            (feature) => feature.properties.sporId === selected.sporId
          )
        )
        .reduce((prev, curr) => [...prev, ...curr], []);

      return [
        {
          type: 'FeatureCollection',
          kilde: selected.kilde,
          features,
        },
      ];
    }
    return [];
  };

  const featureCollection = getSelectedFeatures(
    kartData.geoJSON,
    selectedTrack
  );

  const enrichItem = (item) => {
    return {
      ...item,
      arbeidstype:
        localeContext.currentLocale.messages[
          `kontrolltype.${item.arbeidstypeId}`
        ] || `${item.arbeidstypeId}`,
      feiltype:
        localeContext.currentLocale.messages[`feiltype.${item.feiltypeId}`] ||
        `${item.feiltypeId}`,
      kategori:
        localeContext.currentLocale.messages[`kategori.${item.kategoriId}`] ||
        `${item.kategoriId}`,
      seksjon:
        localeContext.currentLocale.messages[`seksjon.${item.seksjonId}`] ||
        `${item.seksjonId}`,
      veitype:
        localeContext.currentLocale.messages[`veitype.${item.veitypeId}`] ||
        `${item.veitypeId}`,
      status: item.status || item.meldingsstatusId,
    };
  };

  const getMarkers = () => {
    const avvik = kartData.avvik
      .filter((item) => item && item.latitude && item.longitude)
      .map((item) => {
        return {
          position: [item.latitude, item.longitude],
          title: item.adresse,
          key: item.id,
          icon: getAvvikIcon(item),
          onClick: () => {
            setAvvikdetaljer(enrichItem(item));
          },
        };
      });

    const publikummeldinger = kartData.publikummeldinger
      .filter((item) => item && item.latitude && item.longitude)
      .map((item) => {
        return {
          position: [item.latitude, item.longitude],
          title: item.adresse,
          key: item.id,
          icon: getPublikummeldingIcon(item),
          onClick: () => {
            setAvvikdetaljer(item);
          },
        };
      });

    return [...avvik, ...publikummeldinger];
  };

  // reset display setting etter neste render
  if (kartOverlay.current && kartOverlay.current.style.display === 'none')
    kartOverlay.current.style.display = 'flex';

  const height = use100vh();
  const viewHeight = height ? height - 151.938 : '100%';

  return (
    <div style={{ height: viewHeight }}>
      <div
        className={`map-container ${
          selectedTrack && selectedTrack.kilde ? 'selected-track-open' : ' '
        }`}
        id="oversiktkart-container"
      >
        <BymMap
          defaultZoom={defaultZoomLevel}
          defaultCenter={defaultCenter}
          laster={kartData.loading}
          geoJSON={kartData.geoJSON}
          showTooltip={showTooltip}
          autoBounds={autoBounds}
          layers={layers}
          wmslayers={wmslayers}
          onMapViewportChanged={localOnMapViewportChanged}
          markers={getMarkers()}
          bounds={bounds}
          hideKartlagvelger={false}
        >
          {spordetaljer && selectedTrack && selectedTrack.latlng && (
            <SporingPopup
              position={selectedTrack.latlng}
              spordetaljer={spordetaljer}
            />
          )}
          <SporDataPane
            tracks={kartData.geoJSON}
            selectedTrack={featureCollection}
            setSelectedTrack={setSelectedTrack}
            setSpordetaljer={setSpordetaljer}
            zoomLevel={zoomLevel}
          />
          {avvikdetaljer && (
            <AvvikPopup
              avvikdetaljer={avvikdetaljer}
              onClose={() => {
                setAvvikdetaljer(undefined);
              }}
            />
          )}
          <OmradeGrenser bounds={bounds} />

          {/* <HeatmapLayer
            fitBoundsOnLoad={false}
            fitBoundsOnUpdate={false}
            points={points}
            longitudeExtractor={m => m[0]}
            latitudeExtractor={m => m[1]}
            intensityExtractor={() => {
              return 3.0;
            }}
            radius={15}
            blur={7}
          /> */}
        </BymMap>
        {kartData.loading && (
          <div className="map-overlay" id="oversiktkart-overlay">
            <div className="content">
              <PulseLoader
                sizeUnit="px"
                size={10}
                color="#fff"
                loading={kartData.loading}
              />
            </div>
          </div>
        )}
        {selectedTrack && selectedTrack.kilde && (
          <div id="forbruk-spor-container">
            <ForbrukSpor
              kilde={selectedTrack.kilde}
              sporId={selectedTrack.sporId}
              onClose={() => deselectTrack()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EntrepenoerkontrollMap.propTypes = {
  mapProps: PropTypes.shape({}),
  showTooltip: PropTypes.bool,
  autoBounds: PropTypes.bool,
  layers: PropTypes.arrayOf(PropTypes.string),
  kartData: PropTypes.shape({
    publikummeldinger: PropTypes.arrayOf(PropTypes.shape({})),
    geoJSON: PropTypes.arrayOf(PropTypes.shape({})),
    avvik: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  onMapViewportChanged: PropTypes.func.isRequired,
  spordetaljer: PropTypes.shape({}),
  defaultCenter: PropTypes.shape({}),
  selectedTrack: PropTypes.shape({
    kilde: PropTypes.string,
    sporId: PropTypes.string,
    latlng: PropTypes.string,
  }).isRequired,
  setSelectedTrack: PropTypes.func.isRequired,
  bounds: PropTypes.arrayOf(PropTypes.shape({})),
};

EntrepenoerkontrollMap.defaultProps = {
  layers: [],
  kartData: {},
  autoBounds: false,
  defaultCenter: undefined,
  showTooltip: false,
  mapProps: {},
  spordetaljer: {},
  bounds: null,
};

export default EntrepenoerkontrollMap;
