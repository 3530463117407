import moment from 'moment';
import { getFormattedLongDate, getFormattedDate } from '../../utils/dateUtils';

const defaultConfig = {
  name: 'day',
  minDetail: 'year',
  maxDetail: 'month',
  returnValue: 'range',
  showWeekNumbers: false,
  selectRange: true,
  view: 'month',
  className: 'periodevelger-kalender',
};

export const configs = {
  day: {
    ...defaultConfig,
    name: 'day',
    selectRange: true,
    view: 'month',
    minDetail: 'month',
    maxDetail: 'month',
  },
  mnd: {
    ...defaultConfig,
    name: 'mnd',
    view: 'year',
    selectRange: false,
    minDetail: 'year',
    maxDetail: 'year',
  },
  year: {
    ...defaultConfig,
    name: 'year',
    view: 'decade',
    selectRange: false,
    minDetail: 'decade',
    maxDetail: 'decade',
  },
};

export const getFormattedPeriodAsPlainDates = (fra, til) => ({
  label: `${getFormattedDate(fra)} - ${getFormattedDate(til)}`,
});

export const getPeriode = (fra, til) => {
  const firstOfMonth = moment(fra).startOf('month');
  const endOfMonth = moment(fra).endOf('month');
  const fistOfYear = moment(fra).startOf('year');
  const endOfYear = moment(fra).endOf('year');

  if (
    moment(fra).diff(fistOfYear, 'days') === 0 &&
    (moment(til).diff(endOfYear, 'days') === 0 ||
      moment(til).diff(moment(), 'days') === 0)
  ) {
    return {
      label: moment(fra).format('YYYY'),
      prevLabel: moment(fra).subtract(1, 'year').format('YYYY'),
      prevValue: [
        moment(fra).subtract(1, 'year').startOf('year'),
        moment(fra).subtract(1, 'year').endOf('year'),
      ],

      nextLabel: moment(fra).add(1, 'year').format('YYYY'),
      nextValue: [
        moment(fra).add(1, 'year').startOf('year'),
        moment(fra).add(1, 'year').endOf('year'),
      ],
    };
  }

  if (
    moment(fra).diff(firstOfMonth, 'days') === 0 &&
    (moment(til).diff(endOfMonth, 'days') === 0 ||
      moment(til).diff(moment(), 'days') === 0)
  ) {
    return {
      label: moment(fra).format('MMMM YYYY'),
      prevLabel: moment(fra).subtract(1, 'month').format('MMMM YYYY'),
      prevValue: [
        moment(fra).subtract(1, 'month').startOf('month'),
        moment(fra).subtract(1, 'month').endOf('month'),
      ],
      nextLabel: moment(fra).add(1, 'month').format('MMMM YYYY'),
      nextValue: [
        moment(fra).add(1, 'month').startOf('month'),
        moment(fra).add(1, 'month').endOf('month'),
      ],
    };
  }

  if (moment(til).diff(fra, 'hours') < 24)
    return {
      label: getFormattedLongDate(fra),
      prevLabel: getFormattedDate(moment(fra).subtract(1, 'day')),
      prevValue: [
        moment(fra).subtract(1, 'day').startOf('day'),
        moment(fra).subtract(1, 'day').endOf('day'),
      ],
      nextLabel: getFormattedDate(moment(fra).add(1, 'day')),
      nextValue: [
        moment(fra).add(1, 'day').startOf('day'),
        moment(fra).add(1, 'day').endOf('day'),
      ],
    };
  return getFormattedPeriodAsPlainDates(fra, til);
};
