/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import httpClient from '../../api/httpClient';
import 'react-image-lightbox/style.css';
import './BildeModal.scss';

function BildeModal(props) {
  const { bilder, valgtBildeIndex, avvikId } = props;
  const [base64Bilder, setBase64Bilder] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentBildeIndex, setCurrentBildeIndex] = useState(valgtBildeIndex);

  const visBildekarusell = (bildeIndex) => {
    setCurrentBildeIndex(bildeIndex);
    setVisible(true);
  };

  const getBildeData = async () => {
    const bildeData = await Promise.all(
      bilder.map((bildeId) =>
        httpClient.avvikVedleggSrcBase64(avvikId, bildeId)
      )
    );
    setBase64Bilder(
      bildeData.map((item) => {
        const bilde = item?.data?.result;
        return {
          ...bilde,
          src: `data:${bilde?.contentType};base64, ${bilde?.base64Vedlegg}`,
        };
      })
    );
  };

  useEffect(() => {
    getBildeData();
  }, []);

  return (
    <>
      {base64Bilder &&
        base64Bilder.length > 0 &&
        base64Bilder.map((bilde, i) => {
          if (bilde) {
            return (
              <img
                key={bilde.vedleggId}
                className="bilde-modal-preview-image"
                alt={`${bilde.filnavn}`}
                src={bilde.src}
                onClick={() => visBildekarusell(i)}
              />
            );
          }
          return null;
        })}

      {visible && (
        <Lightbox
          mainSrc={base64Bilder[currentBildeIndex].src}
          nextSrc={base64Bilder[(currentBildeIndex + 1) % bilder.length].src}
          prevSrc={
            base64Bilder[
              (currentBildeIndex + base64Bilder.length - 1) %
                base64Bilder.length
            ].src
          }
          onCloseRequest={() => setVisible(false)}
          onMovePrevRequest={() =>
            setCurrentBildeIndex(
              (currentBildeIndex + base64Bilder.length - 1) %
                base64Bilder.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentBildeIndex((currentBildeIndex + 1) % base64Bilder.length)
          }
        />
      )}
    </>
  );
}

BildeModal.propTypes = {
  bilder: PropTypes.arrayOf(PropTypes.string).isRequired,
  valgtBildeIndex: PropTypes.number,
  avvikId: PropTypes.string.isRequired,
};

BildeModal.defaultProps = {
  valgtBildeIndex: 0,
};

export default BildeModal;
