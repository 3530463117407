import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { withRouter } from 'react-router';
import moment from 'moment';
import { use100vh } from 'react-div-100vh';
import Translate from '../../../Locale/Translate';
import DatetimeInput from '../../DatetimeInput/DatetimeInput';
import GeoSuggestInput from '../../GeoSuggestInput/GeoSuggestInput';
import InnmeldingFormMap from '../InnmeldingFormMap';
import ImageUploader from '../../ImageUploader/ImageUploader';
import CommentsInput from '../../CommentsInput/CommentsInput';
import StatusInput from '../../StatusInput/StatusInput';
import InnmeldingFormEntreprenorselskap from '../InnmeldingFormEntreprenorselskap';
import InnmeldingFormHva from '../InnmeldingFormHva';
import { UserContext } from '../../../User/UserProvider';
import RequiredInputStar from '../requiredInputStar';
import InnmeldingFooter from './InnmeldingFooter';
import { avvikStatuskoder } from '../../../constants/status';

const InnmeldingForm = (props) => {
  const {
    innmelding,
    setInnmelding,
    onSave,
    saving,
    header,
    validState,
    statiskeData,
    navLink,
    step,
    steps,
  } = props;

  const user = useContext(UserContext);

  const onSaveClick = (status) => {
    onSave(
      status || avvikStatuskoder.find((st) => st.key === 'IkkeBehandlet').id
    );
  };

  const height = use100vh();
  const viewHeight = height ? height - 93.94 : '100%';

  return (
    <div>
      <div
        style={{
          height: viewHeight,
        }}
      >
        <div className="innmelding-form-mobil">
          {step === steps.when && (
            <div className="innmelding-form-step innmelding-form-step-when">
              <h2>{header}</h2>
              <div>
                <h3 className="innmelding-form-mobil-header">
                  <Translate
                    id={
                      user.isEntreprenor()
                        ? 'innmelding-form.dateTimeLabel.entreprenor'
                        : 'innmelding-form.dateTimeLabel'
                    }
                  />
                  <RequiredInputStar />
                </h3>
                <DatetimeInput
                  datoLabel={<Translate id="innmelding-form.dateLabel" />}
                  tidLabel={<Translate id="innmelding-form.timeLabel" />}
                  handleChange={(e) =>
                    setInnmelding({ ...innmelding, utfort: e })
                  }
                  value={innmelding.utfort}
                  validState={validState.utfort}
                  isValidDate={(current) => moment(current).isBefore(moment())}
                />
              </div>
              <div id="innmelding-form-angi-img">
                <ImageUploader
                  label={
                    <>
                      <Translate id="innmelding-form.addPictureHeader" />
                      <RequiredInputStar />
                    </>
                  }
                  ingress={<Translate id="innmelding-form.pictureIngress" />}
                  images={innmelding.bilder}
                  maxCount={5}
                  onImagesAdded={(bilder) =>
                    setInnmelding({
                      ...innmelding,
                      bilder: [...innmelding.bilder, ...bilder],
                    })
                  }
                  onImageRemoved={(bilde) =>
                    setInnmelding({
                      ...innmelding,
                      slettedeBilder: bilde.id
                        ? [...(innmelding.slettedeBilder || []), bilde.id]
                        : null,
                      bilder: filter(innmelding.bilder, (b) => {
                        return b !== bilde;
                      }),
                    })
                  }
                  validState={validState.bilder}
                />
              </div>
              <InnmeldingFooter
                nextLink={`${navLink}${steps.what}`}
                onSave={onSaveClick}
                saving={saving}
              />
            </div>
          )}
          {step === steps.what && (
            <div className="innmelding-form-step innmelding-form-step-when">
              <h2 className="innmelding-form-mobil-header">
                <Translate
                  id={
                    user.isEntreprenor()
                      ? 'innmelding-form.whatHeader.entreprenor_mobil'
                      : 'innmelding-form.whatHeader_mobil'
                  }
                />
                <RequiredInputStar />
              </h2>
              <InnmeldingFormHva
                statiskeData={statiskeData}
                setInnmelding={setInnmelding}
                innmelding={innmelding}
                validState={validState}
                isSearchable={false}
              />
              <InnmeldingFooter
                nextLink={`${navLink}${steps.where}`}
                onSave={onSaveClick}
                saving={saving}
              />
            </div>
          )}
          {step === steps.where && (
            <div className="innmelding-form-step innmelding-form-step-where">
              <InnmeldingFormMap
                innmelding={innmelding}
                setInnmelding={setInnmelding}
              />
              <div className="innmelding-form-mobil-step-where-second">
                <h3 className="innmelding-form-mobil-header">
                  <Translate id="innmelding-form.placeHeader_mobil" />
                  <RequiredInputStar />
                </h3>

                <div id="innmelding-form-angi-sted">
                  <p>
                    <Translate id="innmelding-form.placeSub_mobil" />
                  </p>
                  <Translate id="innmelding-form.placePlaceholder">
                    {(msg) => (
                      <GeoSuggestInput
                        placeholder={msg}
                        value={{
                          lat: innmelding.latitude,
                          lng: innmelding.longitude,
                          adresse: innmelding.adresse,
                          bydel: innmelding.bydel,
                          omrade: innmelding.omrade,
                        }}
                        validState={validState.sted}
                        setInnmelding={(value) => {
                          setInnmelding({ ...innmelding, ...value });
                        }}
                      />
                    )}
                  </Translate>
                </div>
                <InnmeldingFooter
                  nextLink={`${navLink}${steps.comment}`}
                  onSave={onSaveClick}
                  saving={saving}
                />
              </div>
            </div>
          )}
          {step === steps.comment && (
            <div className="innmelding-form-step innmelding-form-step-comment">
              <h2>
                <Translate id="innmelding-form.commentLabel_mobil" />
              </h2>
              <CommentsInput
                label={<Translate id="innmelding-form.commentLabel" />}
                maxChars={300}
                text={innmelding.kommentar}
                onChange={(text) =>
                  setInnmelding({ ...innmelding, kommentar: text })
                }
              />
              {!user.isEntreprenor() && (
                <>
                  <InnmeldingFormEntreprenorselskap
                    innmelding={innmelding}
                    setInnmelding={setInnmelding}
                    validState={validState}
                    statiskeData={statiskeData}
                  />

                  <h3 className="innmelding-form-mobil-header">
                    <Translate id="innmelding-form.statusLabel" />
                  </h3>
                  <StatusInput
                    label={<Translate id="innmelding-form.basisForTicket" />}
                    botgrunnlag={innmelding.botgrunnlag}
                    onChange={(botgrunnlag) => {
                      setInnmelding({ ...innmelding, botgrunnlag });
                    }}
                  />
                </>
              )}
              <InnmeldingFooter
                saveButton
                onSave={onSaveClick}
                saving={saving}
                validState={validState}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InnmeldingForm.propTypes = {
  innmelding: PropTypes.shape({}).isRequired,
  validState: PropTypes.shape({}).isRequired,
  header: PropTypes.node,
  setInnmelding: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  statiskeData: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  navLink: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InnmeldingForm.defaultProps = {
  header: null,
};

export default withRouter(InnmeldingForm);
