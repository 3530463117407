import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import map from 'lodash/map';
import Translate from '../../Locale/Translate';

const Listeheader = (props) => {
  const { itemCount, sortBy, setSortBy } = props;
  const options = [
    {
      value: 'adresse',
      order: 'asc',
      label: (
        <Translate id="kontrollpanel.header.list-header.sort-by-address" />
      ),
    },
    {
      value: 'utfort',
      order: 'desc',
      label: <Translate id="kontrollpanel.header.list-header.sort-by-time" />,
    },
    {
      value: 'nummer',
      order: 'desc',
      label: <Translate id="kontrollpanel.header.list-header.sort-by-number" />,
    },
  ];

  useEffect(() => {
    if (!sortBy) {
      setSortBy(options.find((i) => i.value === 'nummer'));
    }
  });

  return (
    <div className="kontrollpanel-listheader">
      <div className="item-count">
        <span>
          <Translate id="kontrollpanel.header.list-header.item-count" /> (
          {itemCount})
        </span>
      </div>
      <div className="dropdowns">
        <Label>
          <Translate id="kontrollpanel.header.list-header.sort-by" />:
        </Label>
        <UncontrolledDropdown>
          <DropdownToggle caret>
            {sortBy || <Translate id="kontrollpanel.header.list-header.sort" />}
          </DropdownToggle>
          <DropdownMenu right>
            {map(options, (i) => (
              <DropdownItem key={i.value} onClick={() => setSortBy(i)}>
                {i.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  );
};

Listeheader.propTypes = {
  itemCount: PropTypes.number.isRequired,
  sortBy: PropTypes.element,
  setSortBy: PropTypes.func.isRequired,
};

Listeheader.defaultProps = {
  sortBy: undefined,
};

export default Listeheader;
