import React, { useState, useEffect, useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import moment from 'moment';

import httpClient from '../../api/httpClient';
import { getFilterFormattedDateTime } from '../../utils/dateUtils';
import { getUtsyrsloggNavnKey } from '../../utils/sporUtils';
import Linjediagram from '../Statistikk/Linjediagram';
import SporingsdataInfo from '../Driftskart/SporingsdataInfo.tsx';
import { LocaleContext } from '../../Locale/LocaleProvider';

import './DashboardDriftsstatistikk.scss';

const DashboardDriftsstatistikkAktiveMaskiner = () => {
  const localeContext = useContext(LocaleContext);

  const [timer, setTimer] = useState();
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [datoFra, setDatoFra] = useState();
  const [datoTil, setDatoTil] = useState();

  const [maskinerIDriftStatistikkdata, setMaskinerIDriftStatistikkdata] =
    useState({});

  const konverterStatistikkdataArbeidstypeGuidTilTekst = (
    data,
    ignoreKolonnenavn
  ) => {
    if (!data) return data;

    if (data.tabell && data.tabell.kolonner) {
      data.tabell.kolonner.forEach((element, index, array) => {
        const utstyrsloggKey = getUtsyrsloggNavnKey(element);
        if (element.toLowerCase() !== ignoreKolonnenavn.toLowerCase())
          array[index] =
            localeContext.currentLocale.messages[utstyrsloggKey] ||
            utstyrsloggKey;
      });

      data.tabell.tabelldata = data.tabell.tabelldata.map((rad) => {
        const newRad = {};
        Object.keys(rad).forEach((key) => {
          const utstyrsloggKey = getUtsyrsloggNavnKey(key);
          const newKey =
            key.toLowerCase() !== ignoreKolonnenavn.toLowerCase()
              ? localeContext.currentLocale.messages[utstyrsloggKey] ||
                utstyrsloggKey
              : key;
          newRad[newKey] = rad[key];
        });

        return newRad;
      });
    }
    return data;
  };

  const getData = () => {
    setShouldUpdate(true);
    const filter = {
      omrade: [
        {
          id: '00000000-0000-0000-0000-000000000000',
          navn: 'Alle områder',
        },
      ],
      kategoriId: [
        {
          id: '97867e05-f508-4457-8a67-f9c8f8dd236c',
          navn: 'Vinterdrift',
        },
      ],
      arbeidstype: [],
      seksjonId: [],
    };

    const fra = moment().subtract(1, 'day').format();
    setDatoFra(fra);

    const til = moment().format();
    setDatoTil(til);

    setMaskinerIDriftStatistikkdata({ loading: true });
    httpClient
      .getStatistikkMaskinerIDriftPrBydel(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        filter
      )
      .then((data) => {
        // oversett arbeidstyper fra GUID til tekst
        setMaskinerIDriftStatistikkdata(
          konverterStatistikkdataArbeidstypeGuidTilTekst(data, 'Område')
        );
      });
    setShouldUpdate(false);
  };

  useEffect(() => {
    getData();

    setTimer(
      setInterval(() => {
        getData();
      }, 900000) //  Henter data hvert 15 minutt
    );
  }, []);

  return (
    <>
      <div className="statistikkPage-dashboard">
        <div className="statistikkPage-dashboard-chart-container">
          {maskinerIDriftStatistikkdata &&
          !maskinerIDriftStatistikkdata.loading ? (
            <>
              <div>
                <h1>
                  {
                    localeContext.currentLocale.messages[
                      'diagram-container.antall-aktive-maskiner.tittel'
                    ]
                  }
                </h1>
                <h3>
                  Vei / Alle områder / Alle typer arbeid / Antall maskiner
                </h3>
              </div>
              <Linjediagram
                xAxisLabel={
                  localeContext.currentLocale.messages[
                    'statistikk.axis-labels.tid'
                  ]
                }
                yAxisLabel={
                  localeContext.currentLocale.messages[
                    'statistikk.axis-labels.antall'
                  ]
                }
                tooltipFormat="24h"
                tooltipVerdiPostfix="stk"
                data={
                  maskinerIDriftStatistikkdata &&
                  maskinerIDriftStatistikkdata.statistikk
                }
                ingenDataTekst="#"
              />
            </>
          ) : (
            <PulseLoader loading color="#fff" />
          )}
        </div>
      </div>
      <div className="statistikkPage-dashboard-sub">
        <h2>Siste 24 timer</h2>
        <SporingsdataInfo shouldUpdate={shouldUpdate} />
        <p>Sist oppdatert: {moment(datoTil).format('DD.MM.YY - HH:mm')}</p>
      </div>
    </>
  );
};

export default DashboardDriftsstatistikkAktiveMaskiner;
