import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import Translate from '../../Locale/Translate';

const SearchElement = ({ filter, updateFilter }) => (
  <Translate id="kontrollpanel.header.buttons.search">
    {(msg) => (
      <>
        <Label className="kontrollpanel-btn-sok-label">{msg}</Label>
        <InputGroup className="kontrollpanel-btn kontrollpanel-btn-sok ">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaSearch />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="number"
            className="kontrollpanel-sok-input"
            placeholder={msg}
            onChange={(e) =>
              updateFilter({
                ...filter,
                sok: e.target.value,
              })
            }
            value={filter.sok}
          />
        </InputGroup>
      </>
    )}
  </Translate>
);

SearchElement.propTypes = {
  filter: PropTypes.shape({}).isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default SearchElement;
