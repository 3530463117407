/* eslint-disable no-use-before-define */
import httpClient from '../api/httpClient';
import { toastError } from './toast';
import { getFilterFormattedDateTime } from '../utils/dateUtils';

const VINTERDRIFTSUCCESS = 'statistikk/SUCCESS';
const VINTERDRIFTERROR = 'statistikk/ERROR';
const VINTERDRIFTREQUEST = 'statistikk/REQUEST';

const AVVIKSUCCESS = 'statistikk/avvik/SUCCESS';
const AVVIKERROR = 'statistikk/avvik/ERROR';
const AVVIKREQUEST = 'statistikk/avvik/REQUEST';

const AVVIKBYDELSUCCESS = 'statistikk/avvik-bydel/SUCCESS';
const AVVIKBYDELERROR = 'statistikk/avvik-bydel/ERROR';
const AVVIKBYDELREQUEST = 'statistikk/avvik-bydel/REQUEST';

const AVVIKOGKONTROLLERSUCCESS = 'statistikk/avvik-og-kontroller/SUCCESS';
const AVVIKOGKONTROLLERERROR = 'statistikk-og-kontroller/avvik/ERROR';
const AVVIKOGKONTROLLERREQUEST = 'statistikk-og-kontroller/avvik/REQUEST';

export default function reducer(
  state = {
    forbruk: {},
    avvik: {},
    avvikBydel: {},
    avvikOgKontroller: {},
    loading: false,
    avvikLoading: false,
  },
  action = {}
) {
  switch (action.type) {
    case VINTERDRIFTSUCCESS:
      return {
        ...state,
        forbruk: action.payload,
        loading: false,
      };
    case VINTERDRIFTERROR:
      return {
        ...state,
        forbruk: {},
        loading: false,
      };
    case VINTERDRIFTREQUEST:
      return {
        ...state,
        forbruk: {},
        loading: true,
      };

    case AVVIKSUCCESS:
      return {
        ...state,
        avvik: action.payload,
        avvikLoading: false,
      };
    case AVVIKERROR:
      return {
        ...state,
        avvik: {},
        avvikLoading: false,
      };
    case AVVIKREQUEST:
      return {
        ...state,
        avvik: {},
        avvikLoading: true,
      };

    case AVVIKBYDELSUCCESS:
      return {
        ...state,
        avvikBydel: action.payload,
        avvikBydelLoading: false,
      };
    case AVVIKBYDELERROR:
      return {
        ...state,
        avvikBydel: {},
        avvikBydelLoading: false,
      };
    case AVVIKBYDELREQUEST:
      return {
        ...state,
        avvikBydel: {},
        avvikBydelLoading: true,
      };

    case AVVIKOGKONTROLLERSUCCESS:
      return {
        ...state,
        avvikOgKontroller: action.payload,
        avvikOgKontrollerLoading: false,
      };
    case AVVIKOGKONTROLLERERROR:
      return {
        ...state,
        avvikOgKontroller: {},
        avvikOgKontrollerLoading: false,
      };
    case AVVIKOGKONTROLLERREQUEST:
      return {
        ...state,
        avvikOgKontroller: {},
        avvikOgKontrollerLoading: true,
      };

    default:
      return state;
  }
}

const getStatistikkVinterdriftSuccess = (res) => ({
  type: VINTERDRIFTSUCCESS,
  payload: res,
});

const getStatistikkVinterdriftError = (error) => ({
  type: VINTERDRIFTERROR,
  payload: error,
});

export function getStatistikkVinterdrift(filter) {
  return async (dispatch) => {
    dispatch({
      type: VINTERDRIFTREQUEST,
    });
    try {
      const data = await httpClient.getStatistikkVinterdrift(
        getFilterFormattedDateTime(filter.datoFra),
        getFilterFormattedDateTime(filter.datoTil)
      );
      dispatch(getStatistikkVinterdriftSuccess(data));
    } catch (error) {
      dispatch(getStatistikkVinterdriftError(error));
      dispatch(toastError(error));
    }
  };
}

const getStatistikkAvvikSuccess = (res) => ({
  type: AVVIKSUCCESS,
  payload: res,
});

const getStatistikkAvvikError = (error) => ({
  type: AVVIKERROR,
  payload: error,
});

export function getStatistikkAvvik(filter) {
  return async (dispatch) => {
    dispatch({
      type: AVVIKREQUEST,
    });
    try {
      const data = await httpClient.getStatistikkAvvik(
        getFilterFormattedDateTime(filter.datoFra),
        getFilterFormattedDateTime(filter.datoTil)
      );
      dispatch(getStatistikkAvvikSuccess(data));
    } catch (error) {
      dispatch(getStatistikkAvvikError(error));
      dispatch(toastError(error));
    }
  };
}

const getStatistikkAvvikBydelSuccess = (res) => ({
  type: AVVIKBYDELSUCCESS,
  payload: res,
});

const getStatistikkAvvikBydelError = (error) => ({
  type: AVVIKBYDELERROR,
  payload: error,
});

export function getStatistikkAvvikBydel(filter) {
  return async (dispatch) => {
    dispatch({
      type: AVVIKBYDELREQUEST,
    });
    try {
      const data = await httpClient.getStatistikkAvvikPrBydel(
        getFilterFormattedDateTime(filter.datoFra),
        getFilterFormattedDateTime(filter.datoTil)
      );
      dispatch(getStatistikkAvvikBydelSuccess(data));
    } catch (error) {
      dispatch(getStatistikkAvvikBydelError(error));
      dispatch(toastError(error));
    }
  };
}

const getStatistikkAvvikOgKontrollerSuccess = (res) => ({
  type: AVVIKOGKONTROLLERSUCCESS,
  payload: res,
});

const getStatistikkAvvikOgKontrollerError = (error) => ({
  type: AVVIKOGKONTROLLERERROR,
  payload: error,
});

export function getStatistikkAvvikOgKontroller(filter) {
  return async (dispatch) => {
    dispatch({
      type: AVVIKOGKONTROLLERREQUEST,
    });
    try {
      const data = await httpClient.getStatistikkAvvikOgKontroller(
        getFilterFormattedDateTime(filter.datoFra),
        getFilterFormattedDateTime(filter.datoTil)
      );
      dispatch(getStatistikkAvvikOgKontrollerSuccess(data));
    } catch (error) {
      dispatch(getStatistikkAvvikOgKontrollerError(error));
      dispatch(toastError(error));
    }
  };
}
