import React from 'react';
import { Redirect, Switch } from 'react-router';
import NyInnmeldingContainer from './NyInnmeldingContainer';
import EndreInnmelding from './EndreInnmeldingContainer';
import PrivateRoute from '../Routes/PrivateRoute';
import InnmeldingsBekreftelse from './InnmeldingsBekreftelse';

const InnmeldingPage = ({ ...props }) => {
  const { match } = props;
  return (
    <Switch>
      <PrivateRoute
        path={`${match.path}/ny/:step?`}
        component={NyInnmeldingContainer}
      />
      <PrivateRoute
        path={`${match.path}/endre/:kontrollId/:step?`}
        component={EndreInnmelding}
      />
      <PrivateRoute
        exact
        path={`${match.path}/bekreftet`}
        component={InnmeldingsBekreftelse}
      />
      <Redirect from="/" to={`${match.path}/ny/`} />
    </Switch>
  );
};
export default InnmeldingPage;
