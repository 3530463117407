import React, { useEffect, useState, useRef } from 'react';
import { PulseLoader } from 'react-spinners';
import moment from 'moment';
import httpClient from '../../api/httpClient';
import getEnvironmentVariable from '../../utils/environment';
import './Helse.css';

const Helse = () => {
  const [loading, setLoading] = useState(false);
  const [health, setHealth] = useState(undefined);
  const [status, setStatus] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(undefined);
  const intervalRef = useRef(undefined);

  const getHealthcheckStatus = async () => {
    try {
      setLoading(true);
      const resp = await httpClient.getHealthcheck();
      setStatus(true);
      setHealth(resp);
      setLastUpdated(moment());
    } catch (err) {
      setStatus(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    getHealthcheckStatus();
    intervalRef.current = setInterval(() => getHealthcheckStatus(), 120000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const clientVersion =
    getEnvironmentVariable('REACT_APP_CLIENT_VERSION') || '?';

  const serviceVersion = (health && health.version) || '';

  return (
    <div id="helsesjekk-dashboard-container">
      <div className="loader-container">
        <PulseLoader loading={loading} color="#fff" />
        {!loading && (
          <>
            <div id="status" className={status === true ? 'ok' : 'error'} />
            <div>
              <span>{moment().format()}</span>
            </div>
          </>
        )}
      </div>
      {health && (
        <>
          <div
            className={`panel systemstatus ${
              health.status.toLowerCase().split(' ')[0]
            }`}
          >
            <div className="key">Entreprenørkontroll systemstatus</div>
            <div className="value">
              <span>Client version: {clientVersion}</span>
              <span> | </span>
              <span>Service version: {serviceVersion}</span>
            </div>
            <div className="value">
              <span>{lastUpdated && lastUpdated.format()}</span>
            </div>
          </div>
          {health.entries &&
            health.entries.map((entry) => (
              <div
                key={entry.key}
                className={`panel ${entry.value.toLowerCase().split(' ')[0]}`}
              >
                <div className="key">{entry.key}</div>
                <div className="value">{entry.value}</div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default Helse;
