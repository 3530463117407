import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import find from 'lodash/find';

const SearchableDropdownList = (props) => {
  const {
    label,
    validState,
    options,
    value,
    placeholder,
    onChange,
    isSearchable,
  } = props;
  const v = value ? find(options, (i) => i.value === value) : null;
  return (
    <FormGroup className="searchable-dropdown-list">
      <Label>
        {label}
        <Select
          className={`react-select-container ${validState && ' invalid'}`}
          classNamePrefix="react-select"
          placeholder={placeholder}
          value={v}
          options={options}
          onChange={onChange}
          isSearchable={isSearchable}
        />
      </Label>
      {validState && (
        <div className="invalid-dropDown-feedback">{validState}</div>
      )}
    </FormGroup>
  );
};

SearchableDropdownList.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  validState: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isSearchable: PropTypes.bool,
};

SearchableDropdownList.defaultProps = {
  label: null,
  validState: null,
  options: [],
  value: null,
  placeholder: 'Velg...',
  isSearchable: true,
};

export default SearchableDropdownList;
