import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EndreInnmelding from './EndreInnmelding';
import { updateAvvik, getAvvik } from '../../state/avvik';
import { toastError } from '../../state/toast';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toastError,
      updateAvvik,
      getAvvik,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  avvik: state.avvik.avvik,
  loading: state.avvik.loading,
  statiskeData: state.statiskeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(EndreInnmelding);
