/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import Translate from '../../Locale/Translate';

const StolpediagramVertikalt = React.forwardRef((props, ref) => {
  const { data, stackBars, skjulteTyper, tooltipVerdiPostfix, ingenDataTekst } =
    props;

  const getBarColor = (index) => {
    const barColors = [
      '#034b45',
      '#4ef8b6',
      '#f9c66b',
      '#ff8274',
      '#888BD1',
      '#1FCCD2',
      '#B1C913',
      '#A4B2C6',
      '#A4B2C6',
    ];
    const modulusIndex =
      index < barColors.length ? index : index % barColors.length;

    return barColors[modulusIndex];
  };

  const getEnhet = (type) => {
    if (type.indexOf('Saltløsning') >= 0) return 'liter';
    if (type.indexOf('Natrium') >= 0) return 'kg';

    return '';
  };

  const formatItemValue = (value, name, props) => {
    const formattedNumber = new Intl.NumberFormat('nb-NO').format(value);
    return `${formattedNumber} ${
      tooltipVerdiPostfix || getEnhet(props.payload.name)
    }`;
  };

  const isNoData = () =>
    !data || !data.elementer || data.elementer.length === 0;

  if (isNoData())
    return (
      <div className="no-data h-100">
        {!ingenDataTekst && <Translate id="diagram-container.ingen-data" />}
        {ingenDataTekst && <span>{ingenDataTekst}</span>}
      </div>
    );

  return (
    <ResponsiveContainer width="99%" height={500}>
      <ComposedChart
        ref={ref}
        layout="vertical"
        data={data && data.elementer}
        margin={{ top: 20, right: 50, bottom: 20, left: 20 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          type="number"
          allowDecimals={false}
          label={{ value: data.verdiType, position: 'right' }}
        />
        <YAxis
          dataKey="name"
          type="category"
          label={{ value: data.elementType, position: 'top' }}
        />
        <Tooltip
          labelStyle={{ fontWeight: 'bold' }}
          itemStyle={{ color: 'black' }}
          formatter={formatItemValue}
        />
        <Legend />
        {data &&
          data.typer &&
          data.typer
            .filter((item) => {
              return skjulteTyper.indexOf(item) < 0;
            })
            .map((type, index) => {
              return (
                <Bar
                  name={type}
                  dataKey={type}
                  stackId={stackBars ? 'a' : `"a${index}"`}
                  barSize={20}
                  fill={getBarColor(index)}
                  key={Math.random()}
                />
              );
            })}
      </ComposedChart>
    </ResponsiveContainer>
  );
});

StolpediagramVertikalt.propTypes = {
  data: PropTypes.shape({}),
  stackBars: PropTypes.bool,
  skjulteTyper: PropTypes.arrayOf(PropTypes.string),
  tooltipVerdiPostfix: PropTypes.string,
  ingenDataTekst: PropTypes.string,
};

StolpediagramVertikalt.defaultProps = {
  data: {},
  stackBars: true,
  skjulteTyper: [],
  tooltipVerdiPostfix: '',
  ingenDataTekst: '',
};

export default StolpediagramVertikalt;
