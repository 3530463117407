import { connect } from 'react-redux';
import { getKartdata } from '../../state/kartdata';
import { updateFilter } from '../../state/filter';
import Filter from './Filter';

const mapStateToProps = (state, ownProps) => ({
  avviktyper:
    state.statiskeData &&
    state.statiskeData.feiltypeHierarki &&
    state.statiskeData.feiltypeHierarki.avviktyper,
  filter: state.filter.filter,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: (filter) => dispatch(updateFilter(filter)),
  getKartdata: (filter) => dispatch(getKartdata(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
