import React, { Component, useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../User/UserProvider';

const LandingPage = (): JSX.Element => {
  const auth = useAuth();
  const history = useHistory();
  const { login } = useContext(UserContext);

  if (auth.isLoading) {
    return <div>Vennligst vent...</div>;
  }

  if (!auth.isAuthenticated) {
    login();
  }

  // send bruker til innlogging direkte
  if (auth.isAuthenticated) {
    // redirect til landingsside
    history.push('/');
  }

  return (
    <div style={{ maxWidth: '400px', margin: '50px auto' }}>
      <div>
        <button
          type="button"
          className="kontrollpanel-btn kontrollpanel-btn-innmelding hide-on-mobile btn btn-primary"
          onClick={() => login()}
        >
          Logg inn
        </button>
      </div>
    </div>
  );
};

export default LandingPage;

// class LoginPage extends Component {

//   render() {
//     return (
//       <div style={{ maxWidth: '400px', margin: '50px auto' }}>
//         <div>Hei</div>
//         {/* <LoginForm login={this.handleLogin} /> */}
//       </div>
//     );
//   }
// }
