import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const DisplayStateProvider = (props) => {
  const { location, children } = props;

  // her kunne vi i stedet sjekket en verdi i store, men enkel løsning nå
  if (location.pathname.endsWith('/monitor'))
    if (document.querySelector('html'))
      document.querySelector('html').classList.add('monitor-mode');

  return children;
};

DisplayStateProvider.propTypes = {
  location: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
};

export default withRouter(DisplayStateProvider);
