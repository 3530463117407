import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import CSSTransition from 'react-transition-group/CSSTransition';
import map from 'lodash/map';
import { find, findIndex } from 'lodash';
import { FaLayerGroup } from 'react-icons/fa';
import { wmslayers } from '../../utils/mapUtils';
import KartlagInfo from './KartlagInfo';
import {
  addKartlag as addKartlagAction,
  removeKartlag as removeKartlagAction,
  resetKartlag as resetKartlagAction,
  getKartlagDescription as getKartlagDescriptionAction,
} from '../../state/kartlag';
import Translate from '../../Locale/Translate';

const Kartlag = ({
  kartlag,
  addKartlag,
  removeKartlag,
  resetKartlag,
  valgtKartlag,
  getKartlagDescription,
}) => {
  useEffect(() => {
    getKartlagDescription();
  }, [getKartlagDescription]);

  const [open, toggleOpen] = useState(false);

  const handleKartlagChange = (v) => {
    if (find(valgtKartlag, (l) => l.id.toString() === v))
      return removeKartlag(v);
    return addKartlag(find(wmslayers, (l) => l.id.toString() === v));
  };

  return (
    <>
      <div
        className={`kartlag-header-container ${open ? ' open' : ' closed'}`}
        role="button"
        onClick={() => {
          toggleOpen(!open);
        }}
        onKeyPress={() => {
          toggleOpen(!open);
        }}
        tabIndex={0}
      >
        <div className="kartlag-header">
          <FaLayerGroup />
          {/* <span className="kartlag-header-text">Kartlag</span>
          <FaCaretDown
            className={`kartlag-header-caret ${open ? ' open' : ' closed'}`}
          /> */}
        </div>
      </div>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="kartlag-body"
        unmountOnExit
      >
        <div className="kartlag-body-container" aria-hidden={!open}>
          <div className="kartlag-body-container-buttons">
            <Button
              onClick={resetKartlag}
              className="kartlag-body-reset-button"
            >
              <Translate
                id="kartlag.resetKartlag"
                defaultMessage="Fjern valg"
              />
            </Button>
            <Button
              onClick={() => toggleOpen(!open)}
              className="kartlag-body-close-button"
            >
              <Translate id="kartlag.lukk" defaultMessage="Lukk" />
            </Button>
          </div>

          <div className="kartlag-body-container-div">
            <div className="kartlag-body-container-full-height">
              {map(wmslayers, (l) => {
                const checked = findIndex(valgtKartlag, { id: l.id }) !== -1;
                if (l.type === 'header') {
                  return (
                    <div className="kartlag-body-row" key={l.id}>
                      <b>
                        <Translate
                          id={`kartlag.header-${l.id}`}
                          defaultMessage={l.label}
                        />
                      </b>
                    </div>
                  );
                }
                return (
                  <div className="kartlag-body-row" key={l.id}>
                    <label htmlFor={l.id}>
                      <input
                        type="checkbox"
                        id={l.id}
                        name={l.id}
                        value={l.id}
                        checked={checked}
                        onChange={(e) => {
                          handleKartlagChange(e.target.value);
                        }}
                      />
                      <Translate
                        id={`kartlag.kartlag-${l.labelId}`}
                        defaultMessage={l.label}
                      />
                    </label>
                    <KartlagInfo kartlag={kartlag} id={l.id} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

Kartlag.propTypes = {
  kartlag: PropTypes.arrayOf(PropTypes.shape({})),
  valgtKartlag: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number])
  ).isRequired,
  addKartlag: PropTypes.func.isRequired,
  removeKartlag: PropTypes.func.isRequired,
  resetKartlag: PropTypes.func.isRequired,
  getKartlagDescription: PropTypes.func.isRequired,
};

Kartlag.defaultProps = {
  kartlag: [],
};

const mapStateToProps = (state) => ({
  kartlag: state.kartlag.kartlag,
  valgtKartlag: state.kartlag.valgtKartlag,
});

const mapDispatchToProps = (dispatch) => ({
  addKartlag: (kartLag) => dispatch(addKartlagAction(kartLag)),
  removeKartlag: (kartLag) => dispatch(removeKartlagAction(kartLag)),
  resetKartlag: () => dispatch(resetKartlagAction()),
  getKartlagDescription: () => dispatch(getKartlagDescriptionAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Kartlag);
