import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  PieChart,
  Pie,
} from 'recharts';
import moment from 'moment';
import locale_nb from 'moment/locale/nb'; // eslint-disable-line
import { LocaleContext } from '../../../Locale/LocaleProvider';
import AntallMeldinger from './AntallMeldinger';
import featureIsEnabled, {
  FEATURE_DASHBOARD_STATISTIKK_ENDRINGSTYPER,
  FEATURE_DASHBOARD_STATISTIKK_AVVISTE_MELDINGSTYPER,
} from '../../../utils/featureManagement';
import {
  capitalizeFirstLetter,
  addThousandSeparator,
} from '../../../utils/stringUtils';

const Meldinger = (props) => {
  const { periodeStart, data } = props;
  const localeContext = useContext(LocaleContext);
  const {
    totaltAntall,
    totaltAntallAvviste,
    antallFordeltPaStatusOgManed,
    antallGangerAvvistOgAntallMeldinger,
    antallGangerAvvistPrMeldingstype,
    antallEndringerFordeltPaFelt,
    endringstyperFordeltPaManed,
  } = data;

  const totaltAntallAvvisteProsent = (totaltAntallAvviste / totaltAntall) * 100;

  const getTranslatedEndringstypeNavn = (key) =>
    localeContext.currentLocale.messages[`endringstype.${key.toLowerCase()}`] ||
    key;

  const fordelingPrMnd =
    antallFordeltPaStatusOgManed &&
    Object.keys(antallFordeltPaStatusOgManed).map((mnd) => {
      const antallAksepterte = antallFordeltPaStatusOgManed[mnd]['ikke-avvist'];
      const antallAvviste = antallFordeltPaStatusOgManed[mnd].avvist;
      const { totalt } = antallFordeltPaStatusOgManed[mnd];

      return {
        maned: mnd,
        aksepterte: antallAksepterte,
        avviste: antallAvviste,
        totalt,
      };
    });

  const endringstyperPrMnd =
    endringstyperFordeltPaManed &&
    Object.keys(endringstyperFordeltPaManed).map((mnd) => {
      const endringstyper = {};
      Object.keys(endringstyperFordeltPaManed[mnd]).forEach((key) => {
        const oversatt = getTranslatedEndringstypeNavn(key);
        endringstyper[oversatt] = endringstyperFordeltPaManed[mnd][key];
      });
      return {
        ...endringstyper,
        maned: mnd,
      };
    });

  const unikeEndringstyper =
    endringstyperFordeltPaManed &&
    Object.keys(endringstyperFordeltPaManed)
      .map((mnd) => Object.keys(endringstyperFordeltPaManed[mnd]))
      .filter((typer) => typer.length > 0)
      .flat()
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .map((key) => getTranslatedEndringstypeNavn(key));

  let totaltDenneManeden = 0;
  let avvistDenneManeden = 0;
  let avvistDenneManedenProsent = 0;
  if (fordelingPrMnd && fordelingPrMnd.length > 0) {
    totaltDenneManeden = fordelingPrMnd[fordelingPrMnd.length - 1].totalt || 0;
    avvistDenneManeden = fordelingPrMnd[fordelingPrMnd.length - 1].avviste || 0;
    avvistDenneManedenProsent =
      totaltDenneManeden === 0
        ? 0
        : (avvistDenneManeden / totaltDenneManeden) * 100;
  }

  let totaltForrigeManed = 0;
  let avvistForrigeManed = 0;
  let avvistForrigeManedProsent = 0;
  if (fordelingPrMnd && fordelingPrMnd.length > 1) {
    totaltForrigeManed = fordelingPrMnd[fordelingPrMnd.length - 2].totalt || 0;
    avvistForrigeManed = fordelingPrMnd[fordelingPrMnd.length - 2].avviste || 0;
    avvistForrigeManedProsent =
      totaltForrigeManed === 0
        ? 0
        : (avvistForrigeManed / totaltForrigeManed) * 100;
  }

  const antallGangerAvvist =
    antallGangerAvvistOgAntallMeldinger &&
    Object.keys(antallGangerAvvistOgAntallMeldinger).map((key) => {
      return {
        antallGanger: key,
        antallMeldinger: antallGangerAvvistOgAntallMeldinger[key],
      };
    });

  const avvistPrMeldingstype =
    antallGangerAvvistPrMeldingstype &&
    Object.keys(antallGangerAvvistPrMeldingstype).map((key) => {
      return {
        meldingstype: key,
        antallMeldinger: antallGangerAvvistPrMeldingstype[key],
      };
    });

  const endringerPerEndringstype =
    antallEndringerFordeltPaFelt &&
    Object.keys(antallEndringerFordeltPaFelt).map((key) => {
      return {
        endringstype: getTranslatedEndringstypeNavn(key),
        antallEndringer: antallEndringerFordeltPaFelt[key],
      };
    });

  moment.updateLocale(localeContext.currentLocale.locale, locale_nb);
  const convertToDate = (dato) => {
    return moment(dato, 'YY-MM').format('MMM YYYY');
  };

  const formatTooltipValues = (value, name, props) => {
    return [addThousandSeparator(value), capitalizeFirstLetter(name)];
  };
  const formatTooltipLabel = (val) => {
    return capitalizeFirstLetter(moment(val, 'YY-MM').format('MMMM YYYY'));
  };

  if (!totaltAntall)
    return (
      <>
        {' '}
        <PulseLoader sizeUnit="px" size={10} color="#123abc" loading />
      </>
    );

  const renderPieLabel = (label) => {
    return `${label.name} (${label.value} meldinger)`;
  };

  const barColors = [
    '#8884d8',
    '#97ddb6',
    '#ff7f7f',
    '#ffc658',
    '#8c564b',
    '#1f77b4',
  ];

  return (
    <div className="meldingsstatistikk-container">
      <AntallMeldinger
        className="totalt-antall"
        headerText="Totalt antall meldinger"
        centerText={addThousandSeparator(totaltAntall)}
        footerText={`${addThousandSeparator(
          totaltAntallAvviste
        )} avviste (${totaltAntallAvvisteProsent.toFixed(1)}
        %)`}
      />

      <div className="manedsfordeling">
        <AntallMeldinger
          className="denne-maneden"
          headerText="Meldinger denne måneden"
          centerText={addThousandSeparator(totaltDenneManeden) || 0}
          footerText={`${addThousandSeparator(
            avvistDenneManeden
          )} avviste (${avvistDenneManedenProsent.toFixed(1)}%)`}
        />
        <AntallMeldinger
          className="forrige-maned"
          headerText="Meldinger forrige måned"
          centerText={addThousandSeparator(totaltForrigeManed)}
          footerText={`${addThousandSeparator(
            avvistForrigeManed
          )} avviste (${avvistForrigeManedProsent.toFixed(1)}%)`}
        />
      </div>
      <div className="diagram-container">
        <BarChart
          width={600}
          height={300}
          data={fordelingPrMnd}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid horizontal vertical={false} />
          <XAxis
            dataKey="maned"
            interval={0}
            tickFormatter={convertToDate}
            angle={30}
            tickMargin={10}
            tick={{ stroke: 'black', strokeWidth: 0.5 }}
          />
          <YAxis tick={{ fill: 'black' }} />
          <Tooltip
            formatter={formatTooltipValues}
            labelFormatter={formatTooltipLabel}
          />
          <Legend verticalAlign="top" height={36} />
          <Bar dataKey="aksepterte" stackId="a" fill="#97ddb6" />
          <Bar dataKey="avviste" stackId="a" fill="#ff7f7f">
            <LabelList
              dataKey="totalt"
              formatter={(val) => addThousandSeparator(val)}
              position="top"
              color="red"
            />
          </Bar>
        </BarChart>
      </div>

      {featureIsEnabled(FEATURE_DASHBOARD_STATISTIKK_AVVISTE_MELDINGSTYPER) && (
        <div className="avvist-fordeling-kakediagram">
          <div className="kakediagram-header">
            5 mest avviste meldingstyper siden{' '}
            {moment(periodeStart).format('MMMM YYYY')}
          </div>
          <div className="diagram-container">
            <PieChart width={1500} height={300}>
              <Pie
                isAnimationActive
                data={avvistPrMeldingstype.map((mld) => {
                  return {
                    name: mld.meldingstype,
                    value: mld.antallMeldinger,
                  };
                })}
                outerRadius={80}
                fill="#8884d8"
                label={renderPieLabel}
              />
              <Tooltip />
            </PieChart>
          </div>
        </div>
      )}

      <div className="avvistfordeling">
        <div className="avvist-ganger-og-antall">
          <div className="avvistfordeling-header">
            Gjentatt avviste meldinger
          </div>
          {antallGangerAvvist.length === 0 && <i>Ingen</i>}
          <table>
            {antallGangerAvvist.map((i) => {
              return (
                <tr>
                  <td>
                    {i.antallMeldinger} melding
                    {i.antallMeldinger > 1 ? 'er ' : ' '}
                    er avvist {i.antallGanger} ganger
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>

      {featureIsEnabled(FEATURE_DASHBOARD_STATISTIKK_ENDRINGSTYPER) && (
        <>
          <div className="endringer-fordeling-kakediagram">
            <div className="kakediagram-header">
              Type endringer på meldinger
            </div>
            <div className="diagram-container">
              <PieChart width={800} height={300}>
                <Pie
                  isAnimationActive
                  data={endringerPerEndringstype.map((m) => {
                    return {
                      name: m.endringstype,
                      value: m.antallEndringer,
                    };
                  })}
                  outerRadius={80}
                  fill="#8884d8"
                  label={(label) => `${label.name} (${label.value} endringer)`}
                />
                <Tooltip />
              </PieChart>
            </div>
          </div>
          <div className="diagram-container">
            <BarChart
              width={600}
              height={300}
              data={endringstyperPrMnd}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid horizontal vertical={false} />
              <XAxis
                dataKey="maned"
                interval={0}
                tickFormatter={convertToDate}
                angle={30}
                tickMargin={10}
                tick={{ stroke: 'black', strokeWidth: 0.5 }}
              />
              <YAxis tick={{ fill: 'black' }} />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              {unikeEndringstyper &&
                unikeEndringstyper.map((endringstype, index) => (
                  <Bar
                    dataKey={endringstype}
                    stackId="a"
                    fill={barColors[index % unikeEndringstyper.length]}
                  />
                ))}
            </BarChart>
          </div>
        </>
      )}

      {/* <div>
        <BarChart width={730} height={250} data={antallGangerAvvist}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="antallMeldinger" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="antallMeldinger" fill="#8884d8" />
        </BarChart>
      </div> */}
    </div>
  );
};

Meldinger.propTypes = {
  periodeStart: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default Meldinger;
