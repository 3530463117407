/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { PulseLoader } from 'react-spinners';
import Translate from '../../Locale/Translate';
import InnmeldingForm from './InnmeldingForm';
import InnmeldingFormMobil from './innmeldingMobilvisning/InnmeldingFormMobil';
import httpClient from '../../api/httpClient';
import {
  createAvvikFormData,
  validateAvvik,
  validateKladd,
} from '../../utils/formUtils';
import { UserContext } from '../../User/UserProvider.tsx';
import { avvikStatuskoder } from '../../constants/status';

const EndreAvvik = ({
  toastError,
  match,
  getAvvik,
  avvik,
  loading,
  statiskeData,
  history,
}) => {
  const isMobile = window.innerWidth <= 490;

  const user = useContext(UserContext);
  const [localAvvik, setlocalAvvik] = useState(avvik);

  useEffect(() => {
    async function func() {
      try {
        await getAvvik(match.params.kontrollId);
      } catch (error) {
        console.log(error);
      }
    }
    func();
  }, []);

  useEffect(() => setlocalAvvik({ ...avvik }), [avvik]);

  const [validation, setValidation] = useState({ valid: true });
  const steps = {
    when: 'innmelding_1',
    what: 'innmelding_2',
    where: 'innmelding_3',
    comment: 'innmelding_4',
  };

  const [step, setStep] = useState(steps.when);

  useEffect(() => {
    if (match.params.step && match.params.step !== step) {
      setStep(match.params.step);
    } else
      history.replace(
        `/innmelding/endre/${match.params.kontrollId}/${steps.when}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.step]);

  const isKladd = (statusId) =>
    avvikStatuskoder.find((status) => status.key === 'Kladd').id === statusId;

  useEffect(() => {
    if (!validation.valid) setValidation(validateAvvik(localAvvik, user));
  }, [localAvvik, user, validation.valid]);

  const saveAvvik = async (nyStatusId) => {
    const validate = isKladd(nyStatusId)
      ? validateKladd(localAvvik, user)
      : validateAvvik(localAvvik, user);

    if (validate.valid) {
      localAvvik.status = nyStatusId || localAvvik.status;
      const avvikForm = createAvvikFormData(localAvvik);
      try {
        await httpClient.updateAvvik(match.params.kontrollId, avvikForm);
        history.push('/innmelding/bekreftet');
      } catch (err) {
        toastError(err);
      }
    } else setValidation(validate);
  };

  return (
    <>
      {!loading ? (
        <>
          {isMobile ? (
            <InnmeldingFormMobil
              header={
                user.isEntreprenor() ? (
                  <Translate id="innmelding.pageHeader.entreprenor" />
                ) : (
                  <Translate id="innmelding.pageHeader" />
                )
              }
              innmelding={localAvvik}
              setInnmelding={setlocalAvvik}
              onSave={() => saveAvvik()}
              saving={loading}
              validState={validation}
              statiskeData={statiskeData}
              step={step}
              steps={steps}
              navLink={`/innmelding/endre/${match.params.kontrollId}/`}
            />
          ) : (
            <InnmeldingForm
              header={
                user.isEntreprenor() ? (
                  <Translate id="innmelding.pageHeader.entreprenor" />
                ) : (
                  <Translate id="innmelding.pageHeader" />
                )
              }
              innmelding={localAvvik}
              setInnmelding={setlocalAvvik}
              onSave={saveAvvik}
              saving={loading}
              validState={validation}
              statiskeData={statiskeData}
            />
          )}
          {/* <InnmeldingForm
          header={
            <Translate
              id="innmelding.endre.pageHeader"
              defaultMessage="Endre kontroll {id}"
              values={{ id: localAvvik.id }}
            />
          }
          innmelding={localAvvik}
          setInnmelding={setlocalAvvik}
          onSave={saveAvvik}
          validState={validation}
          statiskeData={statiskeData}
        />
         */}
        </>
      ) : (
        <PulseLoader />
      )}
    </>
  );
};

EndreAvvik.propTypes = {
  toastError: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  getAvvik: PropTypes.func.isRequired,
  avvik: PropTypes.shape({}),
  loading: PropTypes.bool,
  statiskeData: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

EndreAvvik.defaultProps = {
  loading: false,
  avvik: {
    id: null,
    innmeldingstype: '',
    avviktypeId: null,
    status: null,
    utfort: null,
    seksjon: null,
    latitude: null,
    longitude: null,
    adresse: null,
    bydel: null,
    kategori: null,
    arbeidstype: null,
    feiltype: null,
    bilder: [],
    kommentar: '',
    botgrunnlag: false,
  },
};

export default withRouter(EndreAvvik);
