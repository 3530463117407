import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import './CommentsInput.css';

const CommentsInput = (props) => {
  const {
    label,
    maxChars,
    text,
    placeholder,
    validState,
    autofocus,
    onChange,
  } = props;

  return (
    <div className="comments">
      <FormGroup>
        <Label className="label">
          <div className="label-container">
            {label && (
              <Label className="label-container-label">
                {label} {!validState && <i className="invalid-state">*</i>}
              </Label>
            )}
            <span className="char-counter">
              {text ? text.length : 0} / {maxChars}
            </span>
          </div>
          <Input
            type="textarea"
            name="comments"
            id="comments"
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value.substring(0, maxChars))}
            value={text}
            autoFocus={autofocus}
          />
        </Label>
      </FormGroup>
    </div>
  );
};

CommentsInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  maxChars: PropTypes.number.isRequired,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  validState: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

CommentsInput.defaultProps = {
  label: undefined,
  text: '',
  placeholder: '',
  validState: true,
  autofocus: false,
};

export default CommentsInput;
