import localforage from 'localforage';

class EnkLocalStore {
  static localStorage;

  constructor() {
    if (typeof window !== 'undefined') {
      this.localStorage = localforage.createInstance({
        driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: 'entrepenørkontroll-store',
      });
    }
  }

  async getItem(key) {
    return this.localStorage.getItem(key);
  }

  async setItem(key, value) {
    return this.localStorage.setItem(key, value);
  }
}

const enkStore = new EnkLocalStore();
export default enkStore;
