/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import { Map, TileLayer, WMSTileLayer, LayersControl } from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import BymMarker from './BymMarker';
import MapInputMarker from './MapInputMarker';
import Kartlag from '../../Kartlag/Kartlag';
import {
  sateliteBaseMap,
  WMSlayerServiceUrl,
  kartverketBaseMap,
  getIsKartverketTilgjengelig,
} from '../../../utils/mapUtils';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { resetKartlag as resetKartlagAction } from '../../../state/kartlag';

class BymMap extends Component {
  map = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      isKartverketTilgjengelig: true,
    };
  }

  componentDidMount = () => {
    this.setState({ isInitialized: true });
    this.invalidateMap();
    this.checkIfKartverketIsWorking();
  };

  componentDidUpdate = (prevProps) => {
    const { selectedPosition, shouldInvalidate, setShouldInvalidate } =
      this.props;

    const l = this.map.current.leafletElement;
    if (shouldInvalidate) {
      l.invalidateSize(false);
      setShouldInvalidate(false);
    }

    if (
      l &&
      !isEmpty(selectedPosition) &&
      prevProps.selectedPosition !== selectedPosition
    ) {
      l.panTo(
        new L.LatLng(
          selectedPosition.position.lat,
          selectedPosition.position.lng
        )
      );
    }
  };

  componentWillUnmount = () => {
    const { resetKartlag } = this.props;
    resetKartlag();
  };

  checkIfKartverketIsWorking = async () => {
    const a = await getIsKartverketTilgjengelig();
    if (!a) this.setState({ isKartverketTilgjengelig: false });
  };

  getDisableClusteringAtZoom = (markers) => {
    if (markers.length > 200) return 15;
    return markers.length < 100 ? 13 : 14;
  };

  invalidateMap = () => {
    // Hack to make leaflet render properly
    setTimeout(() => {
      const m = this.map.current;
      if (m && m.leafletElement) {
        m.leafletElement.invalidateSize(false);
        this.handleMapViewportChanged();
      }
    }, 300);
  };

  handleMapViewportChanged = () => {
    const { onMapViewportChanged } = this.props;
    const m = this.map.current;

    if (m && m.leafletElement) {
      if (onMapViewportChanged) {
        onMapViewportChanged({
          center: m.leafletElement.getCenter(),
          zoom: m.leafletElement.getZoom(),
          bounds: m.leafletElement.getBounds(),
        });
      }
    }
  };

  handleClickInMap = (e) => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  };

  render() {
    const { isInitialized, isKartverketTilgjengelig } = this.state;
    const {
      showTooltip,
      markerClusterLimit = 60,
      defaultCenter,
      mapCenter,
      defaultZoom,
      markers,
      selectedPosition,
      children,
      kartlag,
      bounds,
      hideKartlag,
      hideKartlagvelger,
      scrollWheelZoom,
      touchZoom,
    } = this.props;
    const useMarkerCluster = markers && markers.length > markerClusterLimit;
    if (!isInitialized) return null;

    return (
      <>
        <Map
          center={mapCenter || defaultCenter}
          zoom={defaultZoom}
          maxZoom={19}
          minZoom={11}
          bounds={bounds}
          ref={this.map}
          onClick={(e) => this.handleClickInMap(e)}
          onViewportChanged={(e) => this.handleMapViewportChanged(e)}
          scrollWheelZoom={scrollWheelZoom}
          touchZoom={touchZoom}
          // {...this.props}
        >
          <LayersControl
            position="topleft"
            className="basmeap-layers-toggle-control"
          >
            <LayersControl.BaseLayer key="Satellittkart" name="Flyfoto">
              <TileLayer
                url={sateliteBaseMap.url}
                getMatrix={sateliteBaseMap.getMatrix}
                attribution={sateliteBaseMap.attribution}
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer
              key="Grunnkart"
              name="Grunnkart"
              checked={isKartverketTilgjengelig}
            >
              <TileLayer
                url={kartverketBaseMap.url}
                attribution={kartverketBaseMap.attribution}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer
              key="detaljert_grunnkart"
              name="Detaljert"
              checked={!isKartverketTilgjengelig}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
              />
            </LayersControl.BaseLayer>

            {!hideKartlag &&
              map(kartlag, (l) => (
                <LayersControl.Overlay key={l.id} name={l.id} checked>
                  <WMSTileLayer
                    className="bym-wms-layer"
                    format="image/png"
                    opacity={l.opacity ? l.opacity : 1}
                    transparent
                    layers={l.layerId || l.id}
                    url={WMSlayerServiceUrl}
                  />
                </LayersControl.Overlay>
              ))}
          </LayersControl>

          {useMarkerCluster && (
            <MarkerClusterGroup
              disableClusteringAtZoom={this.getDisableClusteringAtZoom(markers)}
              zoomToBoundsOnClick
              spiderfyOnMaxZoom={false}
            >
              {markers.map((marker) => (
                <BymMarker
                  position={marker.position}
                  showTooltip={showTooltip}
                  onClick={marker.onClick}
                  key={marker.key || marker.title}
                  {...marker}
                />
              ))}
            </MarkerClusterGroup>
          )}
          {!useMarkerCluster &&
            markers.map((marker) => (
              <BymMarker
                position={marker.position}
                showTooltip={showTooltip}
                onClick={marker.onClick}
                key={marker.key || marker.title}
                {...marker}
              />
            ))}
          {!isEmpty(selectedPosition) && (
            <MapInputMarker
              position={selectedPosition}
              showTooltip={showTooltip}
              key={selectedPosition.title}
              {...selectedPosition}
            />
          )}
          {children}
        </Map>
        {!hideKartlagvelger && <Kartlag />}
      </>
    );
  }
}

BymMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({})),
  selectedPosition: PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
  showTooltip: PropTypes.bool,
  markerClusterLimit: PropTypes.number,
  defaultCenter: PropTypes.arrayOf(PropTypes.number),
  mapCenter: PropTypes.arrayOf(PropTypes.number),
  defaultZoom: PropTypes.number,
  kartlag: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number])
  ),
  onClick: PropTypes.func,
  onMapViewportChanged: PropTypes.func,
  hideKartlag: PropTypes.bool,
  hideKartlagvelger: PropTypes.bool,
  shouldInvalidate: PropTypes.bool,
  scrollWheelZoom: PropTypes.bool,
  touchZoom: PropTypes.bool,
  setShouldInvalidate: PropTypes.func,
  resetKartlag: PropTypes.func.isRequired,
  children: PropTypes.node,
  bounds: PropTypes.arrayOf({}),
};

BymMap.defaultProps = {
  markers: [],
  markerClusterLimit: 60,
  defaultCenter: [59.911111, 10.733333],
  mapCenter: null,
  defaultZoom: 12,
  kartlag: [],
  onClick: undefined,
  selectedPosition: undefined,
  onMapViewportChanged: undefined,
  hideKartlag: false,
  hideKartlagvelger: false,
  shouldInvalidate: false,
  showTooltip: false,
  scrollWheelZoom: true,
  touchZoom: true,

  setShouldInvalidate: () => {},
  children: null,
  bounds: null,
};

const mapStateToProps = (state) => ({
  kartlag: state.kartlag.valgtKartlag,
});

const mapDispatchToProps = (dispatch) => ({
  resetKartlag: () => dispatch(resetKartlagAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BymMap);
