import { Component } from 'react';
import PropTypes from 'prop-types';
import TraceKit from 'tracekit';
import { v4 as uuidv4 } from 'uuid';

import { detect } from 'detect-browser';
import httpClient from '../../api/httpClient';
import { ENTREPENORKONTROLL_CLIENT_ID } from '../../utils/environment';

class LoggProvider extends Component {
  // eslint-disable-next-line react/sort-comp
  traceId;

  browser;

  logserviceURL;

  constructor(props) {
    super(props);
    this.traceId = uuidv4();
    this.browser = detect();
    TraceKit.remoteFetching = false;
  }

  componentDidMount() {
    this.loggError('load');
    TraceKit.report.subscribe((errorReport) => {
      this.loggError(errorReport);
    });
  }

  componentDidCatch(error) {
    TraceKit.report(error);
  }

  loggError = (error) => {
    const errorReport = {
      clientId: ENTREPENORKONTROLL_CLIENT_ID,
      browserUrl: window.location.href,
      traceId: this.traceId,
      logLevel: 'ERROR',
      melding: error
        ? JSON.stringify({ browser: this.browser, error })
        : 'Enk-logg',
    };
    console.log(errorReport);
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

LoggProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoggProvider;
