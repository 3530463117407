import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../../Locale/Translate';
import RequiredInputStar from './requiredInputStar';
import SearchableDropdownList from '../SearchableDropdownList/SearchableDropdownList';

const InnmeldingFormEntreprenorselskap = (props) => {
  const { innmelding, setInnmelding, validState, statiskeData } = props;

  const entreprenorselskapOptions =
    statiskeData &&
    statiskeData.kontrakter &&
    statiskeData.kontrakter
      .map((v) => {
        return {
          value: v.id,
          label: v.navn,
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });

  return (
    <div id="innmelding-form-angi-entreprenor">
      <h3>
        <Translate id="innmelding-form.entreprenorLabel" />
        <RequiredInputStar />
      </h3>
      <div>
        <SearchableDropdownList
          label={<Translate id="innmelding-form.entreprenorIngress" />}
          options={entreprenorselskapOptions}
          value={innmelding.kontraktId}
          onChange={(e) =>
            setInnmelding({
              ...innmelding,
              kontraktId: e.value,
            })
          }
          validState={validState.kontraktId}
        />
      </div>
    </div>
  );
};

InnmeldingFormEntreprenorselskap.propTypes = {
  innmelding: PropTypes.shape({}).isRequired,
  validState: PropTypes.shape({}).isRequired,
  setInnmelding: PropTypes.func.isRequired,
  statiskeData: PropTypes.shape({}).isRequired,
};

export default InnmeldingFormEntreprenorselskap;
