import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import { connect } from 'react-redux';
import {
  getSporingsdata as getSporingsdataAction,
  onMapViewportChanged as onMapViewportChangedAction,
} from '../../state/kartdata';
import { updateFilter as updateFilterAction } from '../../state/filter';
import { getSporFarge, arbeidOptions } from '../../utils/sporUtils';
import { getNorwegianLocalTime } from '../../utils/dateUtils';
import BymMap from '../Common/Map/BymMap';
import SporDataPane from '../Driftskart/SporDataPane';

const DashboardMonitorMapContainer = ({
  filter,
  kartData,
  hentSpor,
  setFilter,
}) => {
  const defaultZoomLevel = 12;
  const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel);
  const [timer, setTimer] = useState();
  const [geoJson, setGeoJson] = useState();
  const [sisteSpor, setSisteSpor] = useState();

  useEffect(() => {
    setTimer(
      setInterval(() => {
        const f = {
          ...filter,
          datoFra: getNorwegianLocalTime(moment().subtract(1, 'day').format()),
          datoTil: getNorwegianLocalTime(),
        };
        setFilter(f);
        hentSpor(f);
      }, 900000) // Henter spor hvert 15 minutt
    );
  }, []);

  useEffect(() => {
    hentSpor(filter);
  }, []);

  useEffect(() => {
    if (kartData.geoJSON && kartData.geoJSON.length > 0) {
      let latest = 0;
      if (kartData.geoJSON[0].features) {
        kartData.geoJSON[0].features.forEach((f) => {
          if (f.properties.tidsstempelSluttpunkt > latest) {
            latest = f.properties.tidsstempelSluttpunkt;
          }
          if (f.properties.dobbeltkode === undefined) {
            if (
              f.properties.utstyrLogg &&
              f.properties.utstyrLogg.includes(',')
            ) {
              f.properties.dobbeltkode = 0;

              const res = f.properties.utstyrLogg.split(',');
              for (let index = 1; index < res.length; index++) {
                const newObject = JSON.parse(JSON.stringify(f));
                newObject.properties.dobbeltkode = index;

                kartData.geoJSON[0].features.push(newObject);
              }
            } else {
              f.properties.dobbeltkode = -1;
            }
          }
        });
      }
      setGeoJson(kartData.geoJSON);
      setSisteSpor(latest);
    }
  }, [kartData.geoJSON]);

  const localOnMapViewportChanged = (e) => {
    setZoomLevel(e.zoom);
    // onMapViewportChanged(e);
  };

  const getWeight = (zoom) => {
    if (zoom >= 17) {
      return 7;
    }
    if (zoom <= 15) {
      return 2;
    }
    return 3;
  };

  const geoJSONStyle = (feature) => {
    return {
      weight: getWeight(zoomLevel),
      border: 'black',
      borderWidth: 0.1,
      color: getSporFarge(feature.properties.utstyrLogg),
    };
  };

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <BymMap
        defaultZoom={defaultZoomLevel}
        defaultCenter={[59.93884821220985, 10.813980102539064]}
        laster={kartData.loading}
        geoJSON={geoJson}
        showTooltip={false}
        autoBounds
        geoJSONStyle={geoJSONStyle}
        onMapViewportChanged={localOnMapViewportChanged}
        hideKartlag
        hideKartlagvelger
        // baseKart="norges_grunnkart_graatone"
      >
        <SporDataPane tracks={geoJson} />
      </BymMap>
      <div
        style={{
          position: 'absolute',
          left: '20px',
          bottom: '20px',
          zIndex: 99999,
          fontSize: '20px',
          color: '#fff"',
          backgroundColor: 'black',
          padding: '10px',
          transition: 'height 1s',
          height: 'auto',
        }}
      >
        <PulseLoader loading={kartData.loading} color="#fff" />
        <span
          style={{
            fontSize: '30px',
          }}
        >
          Driftskart
        </span>
        <br />
        <span
          style={{
            fontSize: '25px',
          }}
        >
          Spor fra siste 24 timer.
        </span>
        <br />
        Sist oppdatert: {moment(filter.datoTil).format('DD.MM.YYYY HH:mm')}
        <br />
        {sisteSpor > 0
          ? `Siste registrerte spor: ${moment(sisteSpor).format(
              'DD.MM.YYYY HH:mm'
            )}`
          : ''}
      </div>
      <div
        style={{
          position: 'absolute',
          right: '20px',
          bottom: '20px',
          zIndex: 99999,
          fontSize: '20px',
          color: '#fff"',
          backgroundColor: 'black',
          padding: '10px',
        }}
      >
        {arbeidOptions.map((arbeidstype) => {
          return (
            <div
              style={{
                fontSize: '15px',
                padding: '2px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {arbeidstype.label}:{' '}
              <div
                style={{
                  width: '30px',
                  height: '15px',
                  backgroundColor: arbeidstype.color,
                  marginLeft: '20px',
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter.filter,
  kartData: state.kartdata,
  statiskeData: state.statiskeData,
});

const mapDispatchToProps = (dispatch) => ({
  hentSpor: (filter) => dispatch(getSporingsdataAction(filter)),
  setFilter: (filter) => dispatch(updateFilterAction(filter)),
  onMapViewportChanged: (viewport) =>
    dispatch(onMapViewportChangedAction(viewport)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardMonitorMapContainer);
