import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

const PublicRoute = ({ loggedIn, component, location, ...rest }) => (
  <Route component={component} {...rest} />
);

function mapStateToProps(state) {
  return { loggedIn: state.login != null };
}

PublicRoute.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  component: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps)(PublicRoute);
