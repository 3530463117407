import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

function FilterElement(props) {
  const { label, id, onCloseClick } = props;

  return (
    <div className="kontrollpanel-filter-element">
      <span className="kontrollpanel-filter-element-label">{label}</span>
      <Button
        className="kontrollpanel-filter-element-close"
        onClick={() => onCloseClick(id)}
      />
    </div>
  );
}

FilterElement.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default FilterElement;
