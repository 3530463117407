import { en as unitTest } from './test.text.json';
import { en as header } from '../features/Navigation/Header.text.json';
import { en as shortcut } from '../features/Navigation/Shortcutbar.txt.json';
import { en as landingsside } from '../features/Landingsside/Landingsside.text.json';
import { en as innmelding } from '../features/Innmelding/Innmelding.text.json';
import { en as toast } from '../features/Common/Toast/toast.txt.json';
import { en as dtInput } from '../features/DatetimeInput/DatetimeInput.txt.json';
import { en as kartlag } from '../features/Kartlag/Kartlag.txt.json';
import { en as statistikk } from '../features/Statistikk/Statistikk.text.json';
import { en as kontrollpanel } from '../features/Kontrollpanel/Kontrollpanel.text.json';
import { en as antallKontroller } from '../features/AntallKontroller/antallKontroller.txt.json';
import { en as breadCrumbs } from '../features/Breadcrumb/breadcrumbs.txt.json';
import { en as feiltyper } from '../features/Feiltyper/Feiltyper.txt.json';
import { en as tiltaktyper } from '../features/Tiltaktyper/Tiltaktyper.txt.json';
import { en as entreprenorkontrollMap } from '../features/Driftskart/EntreprenorkontrollMap.text.json';
import { en as roller } from '../features/Admin/Roller.text.json';
import { en as kalenderPeriodeVelger } from '../features/KalenderPeriodeVelger/KalenderPeriodeVelger.txt.json';
import { en as unauth } from '../features/Common/Unauthorized.txt.json';
import { en as dirty } from '../features/Driftskart/MapIsDirtyIndikator.txt.json';
import { en as varslinger } from '../features/Varslinger/Varslinger.text.json';
import { en as statistikkMeldinger } from '../features/Dashboard/Components/Meldinger.text.json';

export default {
  ...unitTest,
  ...header,
  ...shortcut,
  ...innmelding,
  ...landingsside,
  ...toast,
  ...dtInput,
  ...kartlag,
  ...statistikk,
  ...kontrollpanel,
  ...antallKontroller,
  ...breadCrumbs,
  ...feiltyper,
  ...tiltaktyper,
  ...entreprenorkontrollMap,
  ...roller,
  ...kalenderPeriodeVelger,
  ...unauth,
  ...dirty,
  ...varslinger,
  ...statistikkMeldinger,
};
