import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-leaflet';
import moment from 'moment';
import { StatiskeDataContext } from '../Common/StatiskeDataProvider';
import { getUtsyrsloggNavnKey } from '../../utils/sporUtils';
import Translate from '../../Locale/Translate';

const SporingPopup = (props) => {
  const { position, spordetaljer } = props;
  const { statiskeData } = useContext(StatiskeDataContext);

  const getFormattedEntreprenor = (sporingId) => {
    if (!statiskeData || !statiskeData.entreprenorselskaper) return sporingId;

    const selskap = statiskeData.entreprenorselskaper.find(
      (s) => s.sporingId === sporingId
    );

    return (selskap && selskap.navn) || sporingId;
  };

  const entreprenorselskap = getFormattedEntreprenor(
    spordetaljer.kontraktnummer
  );

  return (
    <Popup
      position={position}
      className="sporing-popup"
      keepInView={false}
      autoPan={false}
      autoClose
    >
      <div id="sporingpopup">
        <div className="sporingpopup-icon" />
        <div className="sporingpopup-content">
          <div>
            <span className="sporingpopup-content-heading">
              <Translate id={getUtsyrsloggNavnKey(spordetaljer.utstyrslogg)} />
            </span>
          </div>
          <div>
            <span>
              {entreprenorselskap}; {spordetaljer.maskinId}
            </span>
          </div>
          <div>
            <span>
              {moment(spordetaljer.tidsstempelStartpunkt).format(
                'DD.MM.YYYY [Kl.] HH.mm'
              )}
            </span>
          </div>
        </div>
      </div>
    </Popup>
  );
};

SporingPopup.propTypes = {
  position: PropTypes.shape({}).isRequired,
  spordetaljer: PropTypes.shape({}).isRequired,
};

export default SporingPopup;
