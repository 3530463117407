import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import Translate from '../../Locale/Translate';
import MapInput from '../MapInput/MapInput';
import takk from '../../images/takkSmilefjes.svg';

const InnmeldingsBekreftelse = ({ history }) => {
  return (
    <div>
      <Row className="innmeldingsbekreftelse">
        <Col
          xs={{ size: 12, order: 2 }}
          lg={{ size: 4, order: 1 }}
          className="innmelding-form-input"
        >
          <div id="bekreftet">
            <img src={takk} alt="Smilefjes" className="takk-ikon" />
            <h1>
              <Translate
                id="innmelding-bekreft.title"
                defaultMessage="Takk for at du gjør byen bedre."
              />
            </h1>
            <p className="takk-tekst">
              <Translate
                id="innmelding-bekreft.text"
                defaultMessage="Din innmelding vi nå bli synlig for alle aktive brukere. Det kan
                føre til at feilen blir opprettet raskere og byen vi bor i blir
                enda bedre. Takk!"
              />
            </p>
            <Button
              color="primary"
              onClick={() => history.push('/innmelding/ny')}
            >
              <Translate
                id="innmelding-bekreft.newbutton"
                defaultMessage="Meld inn flere kontroller"
              />
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push('/kontrollpanel/')}
            >
              <Translate
                id="innmelding-bekreft.cancelbutton"
                defaultMessage="Gå til dine innmeldte saker"
              />
            </Button>
          </div>
        </Col>
        <Col
          xs={{ hidden: true }}
          lg={{ size: 8, order: 2 }}
          id="innmelding-form-right"
        >
          <div id="innmelding-bekreftet-map">
            <MapInput label="Velg posisjon" value={null} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(InnmeldingsBekreftelse);
