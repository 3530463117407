import React from 'react';
import PropTypes from 'prop-types';

const AntallMeldinger = (props) => {
  const { className, headerText, centerText, footerText } = props;

  return (
    <div className={`antall-meldinger-container ${className}`}>
      <div className="header">{headerText}</div>
      <div className="main">{centerText}</div>
      <div className="footer">{footerText}</div>
    </div>
  );
};

AntallMeldinger.propTypes = {
  className: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  centerText: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
};

AntallMeldinger.defaultProps = {
  className: '',
};

export default AntallMeldinger;
