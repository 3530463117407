// export const SERVICE_ID = 'c760b553-6d7b-4607-9e44-cc136e0449ea';
export const BYMELDING_SERVICE_ID = '59FFACE5-C76C-446C-BB58-15B110578A87';
export const ENTREPENORKONTROLL_SERVICE_ID =
  'c760b553-6d7b-4607-9e44-cc136e0449ea';
export const ENTREPENORKONTROLL_CLIENT_ID =
  'a93090f6-ea73-4e1b-9cf0-3f17be4f2470';

/**
 * Function that returns the value of a given environment variable.
 * It first checks if the variable is defined on the global window object,
 * if not it falls back to process.env.
 * The reason for this is that environment varibles are resolved at build time,
 * so to avoid running a build every time the application starts up in production
 * all REACT_APP_* environment variables are generated on the window object at startup
 * with the 'runtimeEnv.js' script
 */

function getEnvironmentVariable(key, defaultValue) {
  const env = window[key] || process.env[key];
  if (env != null) {
    // eslint-disable-next-line no-console
    console.log(`${key} : ${env}`);
    return env;
  }
  if (defaultValue) return defaultValue;
  throw new Error(`Missing environment configuration with key '${key}'`);
}

function isProduction() {
  const env = getEnvironmentVariable('REACT_APP_REACT_ENV', 'development');
  return env === 'production';
}

export const isLocalhost = () => {
  const env = getEnvironmentVariable('REACT_APP_REACT_ENV', 'development');
  return env === 'local';
};

export const getBymeldingClientUrl = () => {
  return getEnvironmentVariable('REACT_APP_URL_BYMELDING_CLIENT');
};

export default getEnvironmentVariable;
export { isProduction };
