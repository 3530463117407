import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimeInput from 'react-time-input';
import { ReactComponent as KlokkeIkon } from '../../images/klokke-ikon.svg';

const KalenderPeriodeVelgerTidInput = ({ fra, til, onChange }) => {
  const [time, setTime] = useState({
    fromTime: moment(fra).format('HH:mm'),
    toTime: moment(til).format('HH:mm'),
  });

  useEffect(() => {
    if (
      moment(fra).format('HH:mm') !== time.fromTime ||
      moment(til).format('HH:mm') !== time.toTime
    ) {
      setTime({
        fromTime: moment(fra).format('HH:mm'),
        toTime: moment(til).format('HH:mm'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fra, til]);

  const handleFromTimeChanged = (e) => {
    if (time.fromTime !== e) {
      const fromTime = moment(e, 'HH:mm');
      if (fromTime.isValid()) {
        const newFromDT = moment(fra).set({
          hour: fromTime.get('hour'),
          minute: fromTime.get('minute'),
        });
        onChange(newFromDT.format(), moment(til).format());
        setTime({
          fromTime: newFromDT.format('HH:mm'),
          toTime: moment(til).format('HH:mm'),
        });
      }
    }
  };

  const handleToTimeChanged = (e) => {
    if (time.toTime !== e) {
      const toTime = moment(e, 'HH:mm');
      if (toTime.isValid()) {
        const newToDT = moment(til).set({
          hour: toTime.get('hour'),
          minute: toTime.get('minute'),
        });
        onChange(moment(fra).format(), newToDT.format());
        setTime({
          fromTime: moment(fra).format('HH:mm'),
          toTime: newToDT.format('HH:mm'),
        });
      }
    }
  };

  return (
    <div className="periodevelger-time">
      <div className="periodevelger-time-input-group">
        <TimeInput
          initTime={time.fromTime}
          className="time-form-control"
          onTimeChange={handleFromTimeChanged}
        />
        <div className="klokke-ikon-container">
          <KlokkeIkon />
        </div>
      </div>
      <div className="periodevelger-time-input-group">
        <TimeInput
          initTime={time.toTime}
          className="time-form-control"
          onTimeChange={handleToTimeChanged}
        />
        <div className="klokke-ikon-container">
          <KlokkeIkon />
        </div>
      </div>
    </div>
  );
};

KalenderPeriodeVelgerTidInput.propTypes = {
  fra: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  til: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};
KalenderPeriodeVelgerTidInput.defaultProps = {
  fra: null,
  til: null,
};

export default KalenderPeriodeVelgerTidInput;
