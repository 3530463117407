import React from 'react';
import { Popup } from 'react-leaflet';
import moment from 'moment';
import {} from '../../utils/dateUtils';
import { Link } from 'react-router-dom';
import { clientUrls } from '../../api/urls';
import Translate from '../../Locale/Translate';
// eslint-disable-next-line import/no-unresolved
import StatusBadge from '../Common/StatusBadge';
import { AVVIKTYPE_ENTREPRENOR } from '../../utils/avvikUtils';

interface avvikdetaljer {
  avviktypeId: string;
  nummer: string;
  latitude: number;
  longitude: number;
  meldingstype: string;
  seksjon: string;
  arbeidstype: string;
  feiltype: string;
  adresse: string;
  utfort: string;
  botgrunnlag: boolean;
  linkUrl: string;
  id: string;
  status: string | null;
  meldingsstatusId: string | null;
}

const AvvikPopup = (props: {
  avvikdetaljer: avvikdetaljer;
  onClose: any;
}): JSX.Element => {
  const { avvikdetaljer, onClose } = props;

  const isEntreprenorAvvik =
    avvikdetaljer && avvikdetaljer.avviktypeId === AVVIKTYPE_ENTREPRENOR;

  const isAvvikMelding =
    avvikdetaljer && avvikdetaljer.nummer && avvikdetaljer.nummer.endsWith('K');

  return (
    <Popup
      keepInView={false}
      className="avvik-popup"
      position={[avvikdetaljer.latitude, avvikdetaljer.longitude]}
      onClose={onClose}
      style={{ maxWidth: 'auto' }}
    >
      <div>
        <div className="avvik-popup-header">
          <span className="avvik-popup-heading">
            {avvikdetaljer.meldingstype ||
              `${avvikdetaljer.seksjon}, ${avvikdetaljer.arbeidstype}: ${avvikdetaljer.feiltype}`}
          </span>
        </div>
        <div className="avvik-popup-content">
          <table>
            <tbody>
              <tr>
                <th>
                  <Translate id="avvikPopup.sted" />
                </th>
                <td colSpan={2}>{avvikdetaljer.adresse}</td>
              </tr>
              <tr>
                <th>
                  <Translate id="avvikPopup.dato" />
                </th>
                <td colSpan={2}>
                  {moment(avvikdetaljer.utfort).format('DD.MM.YYYY')}
                </td>
              </tr>
              <tr>
                <th>
                  <Translate id="avvikPopup.tidspunkt" />
                </th>
                <td>{moment(avvikdetaljer.utfort).format('HH.mm')}</td>
                <td className="avvik-alvorlig-status">
                  {isAvvikMelding &&
                    !isEntreprenorAvvik &&
                    avvikdetaljer.botgrunnlag && (
                      <>
                        <div
                          className={`avvik-alvorlig-status-indicator ${
                            avvikdetaljer.botgrunnlag
                              ? 'avvik-alvorlig-status-indicator-alvorlig'
                              : ''
                          }`}
                        />
                        <Translate id="avvikPopup.alvorlig" />
                      </>
                    )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="avvik-popup-footer">
            <div>
              {avvikdetaljer.linkUrl && (
                <a href={avvikdetaljer.linkUrl}>
                  <Translate id="avvikPopup.linkTekst" />
                </a>
              )}
              {!avvikdetaljer.linkUrl && (
                <Link
                  to={clientUrls.kontrollPanel(
                    `${moment(avvikdetaljer.utfort).format(
                      'YYYY-MM-DD'
                    )}%2000:00`,
                    `${moment(avvikdetaljer.utfort).format(
                      'YYYY-MM-DD'
                    )}%2023:59`,
                    `${avvikdetaljer.id}`
                  )}
                >
                  &nbsp;
                  <Translate id="avvikPopup.linkTekst" />
                </Link>
              )}
            </div>
            <StatusBadge
              status={
                avvikdetaljer.status ||
                avvikdetaljer.meldingsstatusId ||
                '00000000-0000-0000-0000-000000000000'
              }
              isEksternInnmelder={
                avvikdetaljer.avviktypeId === AVVIKTYPE_ENTREPRENOR
              }
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default AvvikPopup;
