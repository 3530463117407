import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';
import moment from 'moment';
import find from 'lodash/find';
import { withRouter } from 'react-router';
import { LocaleContext } from '../../Locale/LocaleProvider';

const VarslingListeItem = (props) => {
  const { varsling, history } = props;
  const localeContext = useContext(LocaleContext);

  const formatDateTime = (dt) => moment(dt).format('DD.MM.YYYY - [Kl.]HH:mm');
  const formatType = (kategoriId, arbeidstypeId, feiltypeId) => {
    const kategori = !kategoriId
      ? ''
      : localeContext.currentLocale.messages[`kategori.${kategoriId}`] ||
        `${kategoriId}`;
    const arbeidstype = !arbeidstypeId
      ? ''
      : localeContext.currentLocale.messages[`kontrolltype.${arbeidstypeId}`] ||
        `${arbeidstypeId}`;
    const feiltype = !feiltypeId
      ? ''
      : localeContext.currentLocale.messages[`feiltype.${feiltypeId}`] ||
        `${feiltypeId}`;

    const formattert = `${kategori}${
      kategori && arbeidstype ? ', ' : ''
    }${arbeidstype}${kategori || arbeidstype ? ' - ' : ''}${feiltype}`;

    return formattert !== ''
      ? formattert
      : localeContext.currentLocale.messages[
          'kontrollpanel.udefinert-kategori'
        ];
  };

  const kategorier = [
    { id: '97867e05-f508-4457-8a67-f9c8f8dd236c', class: 'vinterdrift' },
    { id: 'd761fcec-371c-4017-86fb-7bbbb30338f0', class: 'renhold' },
    { id: '0df91571-8cff-4c5f-bc1b-4adbe5cbf7d8', class: 'avfall' },
    { id: '58c6ced2-575c-41e4-a786-ca6d8554bfb3', class: 'sluk-kummer-lokk' },
    { id: '47b7699d-42ce-4754-a195-92415ca78ae6', class: 'vei-asfalt' },
    { id: '00f28ca8-6509-4c25-80bf-603ddcffbebb', class: 'konstruksjoner' },
    { id: '9f44059f-e7ef-467d-834f-3503f473019b', class: 'biler-maskiner' },
    { id: '5f494d30-5f21-44bd-98c6-46955c404ddb', class: 'traer-busker' },
    { id: 'd7fbb314-9d4b-436a-a682-dde403e0ba1c', class: 'miljoavvik' },
  ];
  const iconClass = find(kategorier, (k) => k.id === varsling.kategoriId);

  return (
    <ListGroupItem
      className={`varsling-liste-item${varsling.ulest ? ' ulest' : ''}${
        varsling.link ? ' link' : ''
      }`}
      onClick={varsling.link && (() => history.push(varsling.link))}
    >
      <div
        className={`varsling-liste-item-icon ${
          iconClass ? iconClass.class : 'default'
        }`}
      />
      <div className="varsling-liste-item-body">
        <div className="varsling-liste-item-header-text">
          <span>
            {varsling.overskrift ||
              formatType(
                varsling.kategoriId,
                varsling.arbeidstypeId,
                varsling.feiltypeId
              )}
          </span>
        </div>
        <div className="varsling-liste-item-text">
          <span>{varsling.tekst}</span>
        </div>
        <div className="varsling-liste-item-time">
          <span>
            {varsling.timestamp && formatDateTime(varsling.timestamp)}
          </span>
        </div>
      </div>
    </ListGroupItem>
  );
};

VarslingListeItem.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func })
    .isRequired,
  varsling: PropTypes.shape({
    overskrift: PropTypes.string,
    tekst: PropTypes.string,
    ulest: PropTypes.bool,
    timestamp: PropTypes.string,
    kategoriId: PropTypes.string,
    arbeidstypeId: PropTypes.string,
    feiltypeId: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};

export default withRouter(VarslingListeItem);
