import React, { useEffect, useState, useReducer, useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import { Table, Button, Input } from 'reactstrap';
import moment from 'moment';
import locale_nb from 'moment/locale/nb'; // eslint-disable-line
import httpClient from '../../api/httpClient';
import { LocaleContext } from '../../Locale/LocaleProvider';

const EntreprenorselskapAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [kontrakter, setKontrakter] = useState(undefined);
  const [itemsLoading, dispatch] = useReducer((itemsLoading, { type, id }) => {
    switch (type) {
      case 'addLoadingItem':
        return [...itemsLoading, id];
      case 'removeLoadingItem':
        return itemsLoading.filter((item) => item !== id);
      default:
        return itemsLoading;
    }
  }, []);
  const localeContext = useContext(LocaleContext);

  const getFormattedDateTime = (dato) => {
    moment.updateLocale(localeContext.currentLocale.locale, locale_nb);
    return moment(dato, moment.ISO_8601).format('DD.MM.YYYY [kl.] HH:mm');
  };

  const getKontrakter = async () => {
    try {
      setLoading(true);
      const resp = await httpClient.getKontrakter();
      setKontrakter(resp);
    } finally {
      setLoading(false);
    }
  };

  const startBymeldingImport = async () => {
    try {
      setLoading(true);
      await httpClient.startBymeldingImport();
      await getKontrakter();
    } finally {
      setLoading(false);
    }
  };

  const updateSporingId = async (kontraktId, sporingId, aktiv) => {
    const kontrakt = kontrakter.find((s) => s.id === kontraktId);
    if (sporingId === null) {
      sporingId = '';
    }

    try {
      dispatch({ type: 'addLoadingItem', id: kontrakt.id });
      const resp = await httpClient.putEntreprenorselskapSporingId(
        kontraktId,
        sporingId,
        aktiv
      );
      const kontrakterCopy = [...kontrakter];
      kontrakter.find((s) => s.id === resp.id).sporingId = resp.sporingId;
      kontrakter.find((s) => s.id === resp.id).aktiv = resp.aktiv;
      setKontrakter(kontrakterCopy);
    } finally {
      dispatch({ type: 'removeLoadingItem', id: kontrakt.id });
    }
  };

  const sorterKontrakter = (kontrakter) => {
    if (!kontrakter || kontrakter === null) return [];
    return kontrakter.sort((a, b) => {
      if (a.navn > b.navn) return 1;
      if (a.navn < b.navn) return -1;
      return 0;
    });
  };

  useEffect(() => {
    getKontrakter();
  }, []);

  return (
    <div>
      <div
        style={{
          padding: '20px 10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h1>Entreprenørselskaper</h1>
        <Button color="primary" onClick={() => startBymeldingImport()}>
          Importer fra Bymelding
        </Button>
      </div>
      <PulseLoader loading={loading} />
      {kontrakter && (
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Kontrakt</th>
              <th>Entreprenørselskap</th>
              <th>Gyldig fra</th>
              <th>Gyldig til</th>
              <th>Kontraktnummer</th>
              <th>Aktiv</th>
              <th style={{ width: '100px' }}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {sorterKontrakter(kontrakter).map((kontrakt) => (
              <tr key={kontrakt.id}>
                <td>{kontrakt.id}</td>
                <td>{kontrakt.navn}</td>
                <td>{kontrakt.selskap.navn}</td>
                <td>{getFormattedDateTime(kontrakt.gyldigFraDato)}</td>
                <td>{getFormattedDateTime(kontrakt.gyldigTilDato)}</td>
                <td>
                  <CustomInput
                    value={kontrakt.kontraktnummer}
                    onChange={(value) =>
                      updateSporingId(kontrakt.id, value, kontrakt.aktiv)
                    }
                  />
                </td>
                <td>
                  <Input
                    type="checkbox"
                    checked={kontrakt.aktiv}
                    onClick={(e) =>
                      updateSporingId(
                        kontrakt.id,
                        kontrakt.kontraktnummer,
                        e.target.checked
                      )
                    }
                  />
                </td>
                <td>
                  <PulseLoader
                    sizeUnit="px"
                    size={10}
                    color="#000"
                    loading={itemsLoading.includes(kontrakt.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

const CustomInput = (props) => {
  const { value, onChange, children } = props;
  const [newValue, setNewValue] = useState(undefined);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <Input
      value={newValue}
      onChange={(e) => e && e.target && setNewValue(e.target.value)}
      onBlur={() => onChange && newValue && onChange(newValue)}
    >
      {children}
    </Input>
  );
};

export default EntreprenorselskapAdmin;
