import React from 'react';
import { Badge } from 'reactstrap';
import Translate from '../../Locale/Translate';

interface iStatusBadge {
  status: string;
  isEksternInnmelder: boolean;
}

const StatusBadge = (props: iStatusBadge) => {
  const { status, isEksternInnmelder } = props;

  return (
    <div className="avvik-status-badges">
      {isEksternInnmelder && (
        <Badge className="avvik-status-badge" color="ekstern-innmelder" pill>
          <Translate id="kontrollpanel.avvik-status.ekstern-innmelder" />
        </Badge>
      )}
      <Badge className="avvik-status-badge" color={status} pill>
        <Translate id={`kontrollpanel.avvik-status.${status}`} />
      </Badge>
    </div>
  );
};

export default StatusBadge;
