import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatiskeData as getStatiskeDataAction } from '../../state/statiskeData';

export const StatiskeDataContext = React.createContext();

const StatiskeDataProvider = (props) => {
  const { statiskeData, getStatiskeData, children } = props;

  useEffect(() => {
    getStatiskeData();
  }, [getStatiskeData]);

  return (
    <StatiskeDataContext.Provider value={{ statiskeData }}>
      {children}
    </StatiskeDataContext.Provider>
  );
};

// class StatiskeDataProvider extends Component {
//   static propTypes = {
//     children: PropTypes.node.isRequired,
//     getStatiskeData: PropTypes.func.isRequired,
//   };

//   componentDidMount() {
//     const { getStatiskeData } = this.props;
//     getStatiskeData();
//   }

//   render() {
//     const { children } = this.props;
//     return children;
//   }
// }

const mapStateToProps = (state) => ({
  statiskeData: state.statiskeData,
});

const mapDispatchToProps = (dispatch) => ({
  getStatiskeData: () => dispatch(getStatiskeDataAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatiskeDataProvider);
