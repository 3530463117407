import React, { useState } from 'react';

import { Collapse, Button } from 'reactstrap';

interface CollapsibleSectionInterface {
  label: string;
  children: any;
  collapsed: boolean;
}

const CollapsibleSection = (props: CollapsibleSectionInterface) => {
  const { label, children, collapsed = false } = props;
  const [isOpen, setIsOpen] = useState(!collapsed);

  return (
    <div className="entrepenoerkontroll-filter-row-section entrepenoerkontroll-filter-row-section-collapse">
      <Button
        block
        color="link"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`collapsible ${isOpen ? 'open' : 'closed'}`}
      >
        {label}
      </Button>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};

export default CollapsibleSection;
