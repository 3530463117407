import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import aktivMarkerSkygge from '../../../images/aktiv-marker-skygge.png';

const BymMarker = ({
  position,
  showTooltip = false,
  innmeldingType,
  onClick,
  icon,
  erAktivMarker,
  ...popupProps
}) => {
  const markerIcon = L.icon({
    iconUrl: icon,
    shadowUrl: erAktivMarker ? aktivMarkerSkygge : null,
    iconSize: [45, 51],
    shadowSize: [60, 60],
    iconAnchor: [22, 47],
    shadowAnchor: [30, 50],
    popupAnchor: [0, -39],
  });

  return (
    <>
      <Marker icon={markerIcon} position={position} onClick={onClick}>
        {/* <BymMarkerPopup {...popupProps} /> */}
        {showTooltip && popupProps.title && (
          <Tooltip opacity={0.9} direction="top" offset={{ x: -1, y: -42 }}>
            {popupProps.title}
          </Tooltip>
        )}
      </Marker>
    </>
  );
};

export default BymMarker;
