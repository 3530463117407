/* eslint-disable no-param-reassign */
import moment from 'moment';
import { getFilterFormattedDateTime } from './dateUtils';
import {
  getDriftsomradeKartlagsnavn,
  getBydelKartlagsnavn,
} from '../state/filterOmradeOptions';

export const formatBounds = (bounds) => {
  if (
    bounds &&
    bounds._southWest &&
    bounds._southWest.lat &&
    bounds._southWest.lng &&
    bounds._northEast &&
    bounds._northEast.lat &&
    bounds._northEast.lng
  ) {
    return `${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}`;
  }

  return '';
};

export const boxIsWithinBounds = (box, bounds) => {
  if (box && bounds && bounds._northEast && bounds._southWest) {
    return (
      bounds._northEast.lat >= box._northEast.lat &&
      bounds._northEast.lng >= box._northEast.lng &&
      bounds._southWest.lat <= box._southWest.lat &&
      bounds._southWest.lng <= box._southWest.lng
    );
  }
  return true;
};

export const buildOptions = (filter) => {
  const kontraktnummere = filter.entreprenorOptions
    .filter((o) => o.value === true)
    .map((o) => o.kontraktNummer);

  const maskinIds = filter.maskinIdOptions
    .filter((o) => o.value === true)
    .map((o) => o.label);

  const arbeidstyper = filter.arbeidOptions
    .filter((a) => a.value === true)
    .map((a) => (a.filterKeys ? a.filterKeys.split('|') : [null]))
    .reduce((acc, val) => [...acc, ...val], []);

  const omrade = getDriftsomradeKartlagsnavn(filter.driftsomrade);
  const bydel = getBydelKartlagsnavn(filter.bydel);

  const avviktypeId = filter.feilmeldingOptions
    .filter((o) => o.value === true)
    .map((o) => o.id);

  return {
    fra: filter.datoFra,
    til: filter.datoTil,
    bounds: filter.viewport.bounds,
    kontraktnummere,
    maskinIds,
    arbeidstyper,
    omrade,
    bydel,
    avviktypeId,
  };
};

export const filtrerKartData = (kartData, filter) => {
  const ret = { ...kartData };

  if (
    filter &&
    filter.entreprenorOptions &&
    filter.maskinIdOptions &&
    kartData &&
    kartData.geoJSON
  ) {
    const options = buildOptions(filter);

    const filteredCollections = kartData.geoJSON.map((collection) => ({
      ...collection,
      features: collection.features.filter(
        (x) =>
          (options.kontraktnummere.length === 0 ||
            options.kontraktnummere.includes(x.properties.kontraktNummer)) &&
          (options.arbeidstyper.length === 0 ||
            options.arbeidstyper.includes(x.properties.utstyrLogg)) &&
          (options.maskinIds.length === 0 ||
            options.maskinIds
              .map((m) => m.toString())
              .includes(x.properties.maskinId.toString()))
      ),
    }));

    // Dette legger til feature.dobbeltkode for de som har utstyrlogger som er mer enn 1 logg-kode.
    // Deretter blir det laget en kopi av sporet
    // Dette er fordi hvis vi vil kombinere farger krever det at vi lager en duplikat-path med forskjellige farger.
    // For å holde styr på hvilken farge sporet skal være, er feature.dobbeltkode lik den utstyrskoden som vi vil at fargen skal være lik.
    // F.eks så vil "0,5" ha ett spor med dobbeltkode 0 og ett med dobbeltkode 1. 0 tar da "0" koden og 1 tar "5" koden.
    if (filteredCollections && filteredCollections.length > 0) {
      if (filteredCollections[0].features) {
        filteredCollections[0].features.forEach((f) => {
          if (f.properties.dobbeltkode === undefined) {
            if (
              f.properties.utstyrLogg &&
              f.properties.utstyrLogg.includes(',') &&
              f.properties.utstyrLogg !== '0,2' &&
              f.properties.utstyrLogg !== '2,0'
            ) {
              f.properties.dobbeltkode = 0;
            } else {
              f.properties.dobbeltkode = -1;
            }
          }
          if (
            f.properties.utstyrLogg &&
            f.properties.utstyrLogg.includes(',') &&
            f.properties.utstyrLogg !== '0,2' &&
            f.properties.utstyrLogg !== '2,0'
          ) {
            const res = f.properties.utstyrLogg.split(',');
            for (let index = 1; index < res.length; index++) {
              const newObject = JSON.parse(JSON.stringify(f));
              newObject.properties.dobbeltkode = index;

              filteredCollections[0].features.push(newObject);
            }
          }
        });
      }
    }
    ret.geoJSON = filteredCollections;
  }

  if (filter && filter.feilmeldingOptions && kartData && kartData.avvik) {
    const options = buildOptions(filter);
    const filteredAvvik = kartData.avvik.filter(
      (a) =>
        options.avviktypeId.length === 0 ||
        options.avviktypeId.includes(a.avviktypeId)
    );
    ret.avvik = filteredAvvik;
  }

  if (
    filter &&
    filter.feilmeldingOptions &&
    kartData &&
    kartData.publikummeldinger
  ) {
    const options = buildOptions(filter);
    const filteredPublikummeldinger = kartData.publikummeldinger.filter(
      (a) =>
        options.avviktypeId.length === 0 ||
        options.avviktypeId.includes(a.avviktypeId)
    );
    ret.publikummeldinger = filteredPublikummeldinger;
  }

  return ret;
};

export const getCheckedOptions = (options) => {
  if (!options) return [];

  const matches = options.filter((o) => o.value === true).map((o) => o.label);
  if (matches.length > 0) {
    return matches;
  }

  // ingen valgt == alle valgt
  return options.map((o) => o.label);
};

export const mapIsDirty = (prevFilter, filter) => {
  const dateTimeIsDirty =
    (prevFilter.datoFra &&
      filter.datoFra &&
      getFilterFormattedDateTime(prevFilter.datoFra) !==
        getFilterFormattedDateTime(filter.datoFra)) ||
    (prevFilter.datoTil &&
      filter.datoTil &&
      getFilterFormattedDateTime(prevFilter.datoTil) !==
        getFilterFormattedDateTime(filter.datoTil));

  const prevArbeid = getCheckedOptions(prevFilter.arbeidOptions);
  const currArbeid = getCheckedOptions(filter.arbeidOptions);
  const newArbeid = currArbeid.filter((a) => !prevArbeid.includes(a));
  const arbeidIsDirty = prevArbeid.length > 0 && newArbeid.length > 0;

  const viewportIsDirty = !boxIsWithinBounds(
    filter.viewport ? filter.viewport.bounds : null,
    prevFilter.viewport ? prevFilter.viewport.bounds : null
  );

  const prevOmrade = prevFilter.driftsomrade;
  const currOmrade = filter.driftsomrade;
  const omradeIsDirty = currOmrade !== prevOmrade;

  const prevBydel = prevFilter.bydel === undefined ? null : prevFilter.bydel; // erstatt undefined med null
  const currBydel = filter.bydel === undefined ? null : filter.bydel; // erstatt undefined med null
  const bydelIsDirty = currBydel !== prevBydel;

  return (
    dateTimeIsDirty ||
    (viewportIsDirty && !currOmrade && !currBydel) ||
    arbeidIsDirty ||
    omradeIsDirty ||
    bydelIsDirty
  );
};

export const persistedFilterKey = 'driftskart.filter';
export const persistFilter = (filter) => {
  localStorage.setItem(
    persistedFilterKey,
    JSON.stringify({ ...filter, hydrated: false })
  );
};
export const getPersistedFilter = () => {
  const persistedFilter = JSON.parse(localStorage.getItem(persistedFilterKey));
  if (!persistedFilter || persistedFilter === null) return undefined;
  return { filter: { ...persistedFilter } };
};

const hasValidDates = (filter) => {
  if (!(filter.datoFraIsValid && filter.datoTilIsValid)) return false;

  const fra = moment(filter.datoFra);
  const til = moment(filter.datoTil);
  return fra.isBefore(til);
};

export const filterIsValid = (filter) => {
  return hasValidDates(filter);
};

export default filtrerKartData;
