import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { clientUrls } from '../../api/urls';
import { getAvvikIcon } from '../../utils/avvikUtils';
import {
  getNorwegianLocalTime,
  getFilterFormattedDateTime,
  getStartDay,
  getEndDay,
} from '../../utils/dateUtils';
import { inkluderAvvik } from './KontrollpanelFilterUtils';
import KontrollpanelView from './KontrollpanelView';

const KontrollpanelPage = (props) => {
  const {
    kontrollpanel,
    selectItem,
    setAvvikStatus,
    statiskeData,
    history,
    changeDate,
    match,
    updateFilter,
    initStoredFilter,
    updateSelectedItemEndringslogg,
  } = props;
  const [shouldInvalidate, setShouldInvalidate] = useState(false);
  const [selectSortBy, setSelectSortBy] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);

  useEffect(() => {
    initStoredFilter();
  }, []);

  const datofra =
    match && match.params && match.params.datofra
      ? getNorwegianLocalTime(match.params.datofra)
      : getStartDay();

  const datotil =
    match && match.params && match.params.datotil
      ? getNorwegianLocalTime(match.params.datotil)
      : getEndDay();

  useEffect(() => {
    const id = match && match.params && match.params.avvikId;
    const dotoFra = getStartDay(match.params.datofra);
    const datoTil = getEndDay(match.params.datotil);
    if (!(match.params.datofra && match.params.datotil)) {
      history.replace(
        `/kontrollpanel/${getFilterFormattedDateTime(
          dotoFra
        )}/${getFilterFormattedDateTime(datoTil)}`
      );
    } else {
      changeDate(match.params.datofra, match.params.datotil, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);

  useEffect(() => {
    if (
      kontrollpanel.selectedItem &&
      kontrollpanel.selectedItem.latitude &&
      kontrollpanel.selectedItem.longitude
    ) {
      setMapCenter([
        kontrollpanel.selectedItem.latitude,
        kontrollpanel.selectedItem.longitude,
      ]);
      setShouldInvalidate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kontrollpanel.selectedItem]);

  const onDateChanged = (fra, til) => {
    history.push(
      clientUrls.kontrollPanel(
        getFilterFormattedDateTime(fra),
        getFilterFormattedDateTime(til),
        ''
      )
    );

    if (changeDate) {
      changeDate(fra, til);
    }
  };

  const onItemSelected = (itemId) => {
    if (
      !kontrollpanel.selectedItem ||
      kontrollpanel.selectedItem.id !== itemId
    ) {
      const item = find(kontrollpanel.avvikListe, (a) => {
        return a.id === itemId;
      });
      history.push(
        clientUrls.kontrollPanel(
          getFilterFormattedDateTime(datofra),
          getFilterFormattedDateTime(datotil),
          (item && item.id) || ''
        )
      );
      selectItem(item);
      // setMapCenter(
      //   item && item.latitude && item.longitude
      //     ? [item.latitude, item.longitude]
      //     : null
      // );
      setShouldInvalidate(true);
    }
  };

  const closeAvvikBlade = () => {
    selectItem();
    setShouldInvalidate(true);
    history.push(
      `/kontrollpanel/${getFilterFormattedDateTime(
        match.params.datofra
      )}/${getFilterFormattedDateTime(match.params.datotil)}`
    );
  };

  const filteredAvvikliste = kontrollpanel.avvikListe.filter((item) =>
    inkluderAvvik(item, kontrollpanel.filter)
  );

  const getMarkers = () =>
    filteredAvvikliste
      .filter((item) => item && item.latitude && item.longitude)
      .map((item) => {
        return {
          position: [item.latitude, item.longitude],
          title: item.adresse,
          key: item.id,
          icon: getAvvikIcon(item),
          erAktivMarker:
            kontrollpanel.selectedItem &&
            kontrollpanel.selectedItem.id &&
            kontrollpanel.selectedItem.id === item.id,
          onClick: () => {
            onItemSelected(item.id);
          },
        };
      });

  const setStatus = (status) => {
    setAvvikStatus(kontrollpanel.selectedItem, status.id, status.kommentar);
  };

  const onFilterChanged = (filter) => {
    updateFilter({ ...kontrollpanel.filter, ...filter });
  };

  const filterIsActive = (filter) =>
    (filter.kategorier && filter.kategorier.length !== 0) ||
    (filter.kontrolltyper && filter.kontrolltyper.length !== 0) ||
    (filter.statuskoder && filter.statuskoder.length !== 0) ||
    (filter.seksjoner && filter.seksjoner.length !== 0) ||
    (filter.omrader && filter.omrader.length !== 0) ||
    (filter.avviktyper && filter.avviktyper.length !== 0) ||
    (filter.entreprenorselskaper && filter.entreprenorselskaper.length !== 0) ||
    (filter.kontrakter && filter.kontrakter.length !== 0) ||
    (filter.feiltyper && filter.feiltyper.length !== 0) ||
    (filter.alvorlig && filter.alvorlig.length !== 0);

  return (
    <KontrollpanelView
      kontrollpanel={kontrollpanel}
      updateFilter={updateFilter}
      filterIsActive={filterIsActive}
      history={history}
      statiskeData={statiskeData}
      onDateChanged={onDateChanged}
      onFilterChanged={onFilterChanged}
      filteredAvvikliste={filteredAvvikliste}
      selectSortBy={selectSortBy}
      setSelectSortBy={setSelectSortBy}
      closeAvvikBlade={closeAvvikBlade}
      setStatus={setStatus}
      updateSelectedItemEndringslogg={updateSelectedItemEndringslogg}
      onItemSelected={onItemSelected}
      mapCenter={mapCenter}
      getMarkers={getMarkers}
      shouldInvalidate={shouldInvalidate}
      setShouldInvalidate={setShouldInvalidate}
    />
  );
};

KontrollpanelPage.propTypes = {
  kontrollpanel: PropTypes.shape({
    avvikListe: PropTypes.arrayOf(PropTypes.shape({})),
    date: PropTypes.shape({}),
    loading: PropTypes.bool,
    selectedItem: PropTypes.shape({}),
    filter: PropTypes.shape({
      sok: PropTypes.string,
      kategorier: PropTypes.string,
      kontrolltyper: PropTypes.string,
      statuskoder: PropTypes.string,
      seksjoner: PropTypes.string,
      omrade: PropTypes.string,
      alvorlig: PropTypes.string,
      avviktyper: PropTypes.string,
      entreprenorselskaper: PropTypes.string,
      kontrakter: PropTypes.string,
      omrader: PropTypes.string,
      feiltyper: PropTypes.string,
    }),
  }).isRequired,
  selectItem: PropTypes.func.isRequired,
  setAvvikStatus: PropTypes.func.isRequired,
  statiskeData: PropTypes.shape({ statuskoder: PropTypes.shape({}) })
    .isRequired,
  changeDate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      datofra: PropTypes.string,
      datotil: PropTypes.string,
      avvikId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func })
    .isRequired,
  updateFilter: PropTypes.func.isRequired,
  initStoredFilter: PropTypes.func.isRequired,
  updateSelectedItemEndringslogg: PropTypes.func.isRequired,
};

export default KontrollpanelPage;
