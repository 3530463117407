import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { orderBy } from 'lodash';
import {
  FaList,
  FaMapMarkerAlt,
  FaAlignLeft,
  FaSlidersH,
} from 'react-icons/fa';
import { use100vh } from 'react-div-100vh';
import { UserContext } from '../../User/UserProvider.tsx';
import AvvikListe from '../AvvikListe/AvvikListe';
import AntallKontroller from '../AntallKontroller/AntallKontrollerContainer';
import KalenderPeriodeVelger from '../KalenderPeriodeVelger/KalenderPeriodeVelger';
import Listeheader from '../AvvikListe/Listeheader';
import Shortcutbar from '../Navigation/Shortcutbar';
import Filter from './Filter';
import Translate from '../../Locale/Translate';
import SearchElement from './SearchElement';
import AvvikBlade from '../AvvikBlade/AvvikBlade';
import BymMap from '../Common/Map/BymMap';

const KontrollpanelView = ({
  kontrollpanel,
  updateFilter,
  filterIsActive,
  history,
  statiskeData,
  onDateChanged,
  onFilterChanged,
  filteredAvvikliste,
  selectSortBy,
  setSelectSortBy,
  closeAvvikBlade,
  setStatus,
  updateSelectedItemEndringslogg,
  onItemSelected,
  mapCenter,
  getMarkers,
  shouldInvalidate,
  setShouldInvalidate,
}) => {
  const user = useContext(UserContext);

  const views = { kart: 0, liste: 1, blade: 2 };
  const [valgtView, setValgtView] = useState(views.liste);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [avviksfilterVisible, setAvviksfilterVisible] = useState(false);
  const setMapView = () => {
    setValgtView(views.kart);
  };
  const toggleFilter = () => {
    closeAvvikBlade();
    // onFilterChanged([]);
    setAvviksfilterVisible(!avviksfilterVisible);
  };

  const height = use100vh();

  return (
    <>
      <Row
        className={`kontrollpanel-header  ${
          kontrollpanel.selectedItem ? ' hide-on-mobile' : ' '
        }`}
      >
        <h1 className="hide-on-mobile">
          {/* // Todo!! */}
          <Translate id="kontrollpanel.header.buttons.header" />
        </h1>
        <div className="header-buttons">
          <div className="hide-on-mobile">
            <SearchElement
              filter={kontrollpanel.filter}
              updateFilter={updateFilter}
            />
          </div>
          <Button
            color="secondary"
            className={`hide-on-mobile kontrollpanel-btn kontrollpanel-white-btn ${
              filterIsActive(kontrollpanel.filter)
                ? 'btn-secondary-selected'
                : ''
            }`}
            onClick={toggleFilter}
          >
            <FaSlidersH />
            <Translate id="kontrollpanel.header.buttons.filter" />
          </Button>
          <Button
            color="secondary"
            className="kontrollpanel-btn kontrollpanel-white-btn"
            onClick={() => history.push('/statistikk/avvik')}
          >
            <FaAlignLeft />
            <Translate id="kontrollpanel.header.buttons.statistics" />
          </Button>
          {(user.isKontrollor() || user.isForvalter() || user.isEnkAdmin()) && (
            <AntallKontroller />
          )}
          <Button
            color="primary"
            className="kontrollpanel-btn kontrollpanel-btn-innmelding hide-on-mobile"
            onClick={() => history.push('/innmelding/ny')}
          >
            {/* TODO */}
            {user.isEntreprenor() ? (
              <Translate id="kontrollpanel.header.buttons.register" />
            ) : (
              <Translate id="kontrollpanel.header.buttons.register" />
            )}
          </Button>
        </div>
      </Row>
      <Row
        aria-hidden={!avviksfilterVisible}
        className={`kontrollpanel-filter-row hide-on-mobile ${
          avviksfilterVisible ? 'filter-open' : 'filter-closed'
        }`}
      >
        <Col>
          <Filter
            statiskeData={statiskeData}
            // statiskeStatuskoder={statiskeData.statuskoder}
            onFilterChanged={onFilterChanged}
            filter={kontrollpanel.filter}
          />
        </Col>
      </Row>
      <Row className="kontrollpanel-liste">
        <Col
          xl={{ size: kontrollpanel.selectedItem ? 4 : 4 }}
          lg={{ size: kontrollpanel.selectedItem ? 5 : 5 }}
          md={{ size: 6 }}
          sm={{ size: 12 }}
          className="kontrollpanel-liste-col"
        >
          <div className="kontrollpanel-kontroller">
            <div className="kontrollpanel-panel">
              <div
                className={`kontrollpanel-panel-sub ${
                  kontrollpanel.selectedItem ? ' hide-on-mobile' : ' '
                }`}
              >
                <KalenderPeriodeVelger
                  fra={kontrollpanel.date.fra}
                  til={kontrollpanel.date.til}
                  onChange={(fra, til) => onDateChanged(fra, til)}
                  displayDayMonthYearButtons
                />
                <div className="kontrollpanel-view-velger">
                  <Button
                    color="link"
                    className={`vinsnings-velger ${
                      valgtView === views.liste ? ' valgt ' : ' '
                    }`}
                    onClick={() => {
                      setValgtView(views.liste);
                    }}
                  >
                    <FaList />
                    Listevisning
                  </Button>
                  <Button
                    color="link"
                    className={`vinsnings-velger ${
                      valgtView === views.kart ? ' valgt ' : ' '
                    }`}
                    onClick={() => {
                      setShouldInvalidate(true);
                      // map.current.invalidateMap();
                      setMapView();
                    }}
                  >
                    <FaMapMarkerAlt />
                    Kartvisning
                  </Button>
                </div>
              </div>
              <div
                className={`kontrollpanel-panel-avviks-liste ${
                  valgtView === views.kart || kontrollpanel.selectedItem
                    ? ' hide-on-mobile'
                    : ' '
                }`}
              >
                <Listeheader
                  itemCount={filteredAvvikliste.length}
                  sortBy={selectSortBy && selectSortBy.label}
                  setSortBy={setSelectSortBy}
                />
                <div
                  style={{
                    height: height ? height - 364.94 : '100%',
                  }}
                >
                  <AvvikListe
                    date={kontrollpanel.date}
                    items={
                      selectSortBy
                        ? orderBy(
                            filteredAvvikliste,
                            selectSortBy.value,
                            selectSortBy.order
                          )
                        : filteredAvvikliste
                    }
                    onItemSelected={onItemSelected}
                    selectedItem={kontrollpanel.selectedItem}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>

        {kontrollpanel.selectedItem && (
          <Col
            xl={{ size: 3 }}
            lg={{ size: 3 }}
            md={{ size: 6 }}
            sm={{ size: 12 }}
            className="kontrollpanel-avvik-detaljer"
          >
            <AvvikBlade
              item={kontrollpanel.selectedItem}
              onClose={closeAvvikBlade}
              onSetStatus={setStatus}
              onUpdateEndringslogg={updateSelectedItemEndringslogg}
              statiskeData={statiskeData}
              loading={kontrollpanel.loading}
            />
          </Col>
        )}
        <Col
          className="kontrollpanel-liste-col"
          xl={{ size: kontrollpanel.selectedItem ? 5 : 8 }}
          lg={{ size: kontrollpanel.selectedItem ? 4 : 7 }}
          md={{ size: 6 }}
          sm={{ size: 12 }}
        >
          <div
            className={`kontrollpanel-kart ${
              valgtView === views.liste || kontrollpanel.selectedItem
                ? ' hide-on-mobile'
                : ' '
            }${kontrollpanel.selectedItem ? ' hide-on-medium' : ' '}`}
          >
            <div
              style={{
                height: height ? height - 306.94 : '100%',
              }}
            >
              <BymMap
                defaultCenter={[59.911111, 10.733333]}
                mapCenter={mapCenter}
                defaultZoom={15}
                markers={getMarkers()}
                shouldInvalidate={shouldInvalidate}
                setShouldInvalidate={setShouldInvalidate}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Shortcutbar
        menuComponent={
          <Filter
            statiskeData={statiskeData}
            // statuskoder={statiskeData.statuskoder}
            onFilterChanged={onFilterChanged}
            filter={kontrollpanel.filter}
          />
        }
        isOpen={avviksfilterVisible}
        setIsOpen={setAvviksfilterVisible}
        searchComponent={
          <div className="mobil-kontrollpanel-btn-sok">
            <SearchElement
              filter={kontrollpanel.filter}
              updateFilter={updateFilter}
            />
          </div>
        }
        searchIsOpen={searchIsOpen}
        setSearchIsOpen={setSearchIsOpen}
        searchIsActive={
          kontrollpanel.filter &&
          kontrollpanel.filter.sok !== undefined &&
          kontrollpanel.filter.sok.length > 0
        }
        filterIsActive={filterIsActive(kontrollpanel.filter)}
      />
    </>
  );
};

KontrollpanelView.defaultProps = {
  filterIsActive: false,
  statiskeData: {
    statuskoder: {},
  },
  selectSortBy: {},
  filteredAvvikliste: [],
  kontrollpanel: {},
  mapCenter: [59.911111, 10.733333],
  shouldInvalidate: false,
};

KontrollpanelView.propTypes = {
  onDateChanged: PropTypes.func.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  closeAvvikBlade: PropTypes.func.isRequired,
  setSelectSortBy: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  getMarkers: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      datofra: PropTypes.string,
      datotil: PropTypes.string,
      avvikId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func })
    .isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateSelectedItemEndringslogg: PropTypes.func.isRequired,
  filterIsActive: PropTypes.bool,
  setShouldInvalidate: PropTypes.func.isRequired,
  statiskeData: PropTypes.shape({ statuskoder: PropTypes.shape({}) }),
  selectSortBy: PropTypes.shape({
    label: PropTypes.element,
    value: PropTypes.string,
    order: PropTypes.string,
    statuskoder: PropTypes.shape({}),
  }),
  filteredAvvikliste: PropTypes.arrayOf(PropTypes.shape({})),
  mapCenter: PropTypes.arrayOf(PropTypes.number),
  shouldInvalidate: PropTypes.bool,
  kontrollpanel: PropTypes.shape({
    avvikListe: PropTypes.arrayOf(PropTypes.shape({})),
    date: PropTypes.shape({
      fra: PropTypes.shape({}),
      til: PropTypes.shape({}),
    }),
    loading: PropTypes.bool,
    selectedItem: PropTypes.shape({}),
    filter: PropTypes.shape({
      sok: PropTypes.string,
      seksjoner: PropTypes.string,
      kategorier: PropTypes.string,
      kontrolltyper: PropTypes.string,
      statuskoder: PropTypes.string,
      alvorlig: PropTypes.string,
      omrader: PropTypes.string,
      avviktyper: PropTypes.string,
      entreprenorselskaper: PropTypes.string,
      kontrakter: PropTypes.string,
      feiltyper: PropTypes.string,
    }),
  }),
};

export default KontrollpanelView;
