import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const IfMediaQueryMatch = (props) => {
  const { mediaQuery, children } = props;
  const [isMatch, setIsMatch] = useState(false);

  const checkMatch = useCallback(() => {
    // (max-width: 992px)
    setIsMatch(window.matchMedia(`${mediaQuery}`).matches);
  }, [mediaQuery]);

  useEffect(() => {
    checkMatch();
    window.addEventListener('resize', checkMatch);
    return () => window.removeEventListener('resize', checkMatch);
  }, [mediaQuery, checkMatch]);

  return <>{isMatch && children}</>;
};

IfMediaQueryMatch.propTypes = {
  mediaQuery: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default IfMediaQueryMatch;
