import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import FilterCheckbox from './FilterCheckbox';
import Translate from '../../Locale/Translate';

function OmradeFilterDropdown(props) {
  const {
    tittel,
    resetLabel,
    elementer,
    onChangeElement,
    addMultipleFilters,
    onResetAll,
    isElementAddedToFilter,
    removeMultipleFilters,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const isAllChildrenChecked = (id) => {
    const children = elementer.filter((f) => f.parent && f.parent === id);
    const selectedChildren = children.filter((m) =>
      isElementAddedToFilter(m.id)
    );
    return selectedChildren.length === children.length;
  };

  const toggleAllChildren = (id) => {
    const children = elementer
      .map((el) => {
        if (el.parent === id) return el.id;
        return null;
      })
      .filter((b) => b);
    if (!isAllChildrenChecked(id)) {
      addMultipleFilters(children);
    } else {
      removeMultipleFilters(children);
    }
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle caret>{tittel}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem toggle={false} onClick={onResetAll}>
          {resetLabel}
        </DropdownItem>
        <DropdownItem divider />
        {elementer.map((item) => {
          return (
            <DropdownItem
              key={Math.random()}
              toggle={false}
              onClick={
                item.parent
                  ? () => onChangeElement(item.id)
                  : () => {
                      toggleAllChildren(item.id);
                    }
              }
              style={{ marginLeft: item.parent ? '15px' : 0 }}
            >
              <FilterCheckbox
                key={Math.random()}
                title={item.navn}
                value={item.id}
                checked={
                  item.parent
                    ? isElementAddedToFilter(item.id)
                    : isAllChildrenChecked(item.id)
                }
                onChange={
                  item.parent
                    ? () => onChangeElement(item.id)
                    : () => {
                        toggleAllChildren(item.id);
                      }
                }
              />
            </DropdownItem>
          );
        })}
        {elementer.length === 0 && (
          <DropdownItem
            key={Math.random()}
            className="kontrollpanel-filter-tomt"
            disabled
          >
            <Translate id="kontrollpanel.filterdropdown.tom" />
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

OmradeFilterDropdown.propTypes = {
  tittel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  resetLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  elementer: PropTypes.arrayOf(PropTypes.shape),
  onChangeElement: PropTypes.func.isRequired,
  onResetAll: PropTypes.func.isRequired,
  addMultipleFilters: PropTypes.func.isRequired,
  removeMultipleFilters: PropTypes.func.isRequired,
  isElementAddedToFilter: PropTypes.func.isRequired,
};

OmradeFilterDropdown.defaultProps = {
  tittel: 'Velg..',
  resetLabel: 'Nullstill filter',
  elementer: [],
};

export default OmradeFilterDropdown;
