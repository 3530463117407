import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import KontrollpanelPage from './KontrollpanelPage';
import {
  changeDate,
  selectItem,
  setAvvikStatus,
  initStoredFilter,
  updateFilter,
  updateSelectedItemEndringslogg,
} from '../../state/kontrollpanel';

const mapStateToProps = (state) => ({
  kontrollpanel: state.kontrollpanel,
  statiskeData: state.statiskeData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeDate,
      selectItem,
      setAvvikStatus,
      updateFilter,
      initStoredFilter,
      updateSelectedItemEndringslogg,
    },

    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(KontrollpanelPage);
