import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'reactstrap';
import moment from 'moment';
import AvvikListeItem from './AvikkListeItem';
import { getFormattedAddress } from '../../utils/avvikUtils';
import { LocaleContext } from '../../Locale/LocaleProvider';

const AvvikListe = (props) => {
  const { items, onItemSelected, selectedItem } = props;
  const localeContext = useContext(LocaleContext);

  const formatDateTime = (dt) => moment(dt).format('DD.MM.YYYY - [Kl.]HH:mm');

  const formatType = (kategoriId, arbeidstypeId, feiltypeId) => {
    const kategori = !kategoriId
      ? ''
      : localeContext.currentLocale.messages[`kategori.${kategoriId}`] ||
        `${kategoriId}`;
    const arbeidstype = !arbeidstypeId
      ? ''
      : localeContext.currentLocale.messages[`kontrolltype.${arbeidstypeId}`] ||
        `${arbeidstypeId}`;
    const feiltype = !feiltypeId
      ? ''
      : localeContext.currentLocale.messages[`feiltype.${feiltypeId}`] ||
        `${feiltypeId}`;

    const formattert = `${kategori}${
      kategori && arbeidstype ? ', ' : ''
    }${arbeidstype}${kategori || arbeidstype ? ' - ' : ''}${feiltype}`;

    return formattert !== ''
      ? formattert
      : localeContext.currentLocale.messages[
          'kontrollpanel.udefinert-kategori'
        ];
  };
  return (
    <div className="avviks-liste">
      <ListGroup flush>
        {items.map((item) => {
          return (
            <AvvikListeItem
              key={item.id}
              id={item.id}
              meldingsnummer={item.nummer}
              status={item.status}
              kategori={item.kategoriId}
              type={formatType(
                item.kategoriId,
                item.arbeidstypeId,
                item.feiltypeId
              )}
              avviktypeId={item.avviktypeId}
              tidspunkt={formatDateTime(item.utfort)}
              adresse={getFormattedAddress(item.adresse)}
              botgrunnlag={item.botgrunnlag}
              behandlet={item.behandlet}
              onItemSelected={onItemSelected}
              selected={selectedItem && item.id === selectedItem.id}
            />
          );
        })}
      </ListGroup>
    </div>
  );
};

AvvikListe.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onItemSelected: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({}),
};
AvvikListe.defaultProps = {
  selectedItem: undefined,
};

export default AvvikListe;
