import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import FilterCheckbox from './FilterCheckbox';
import Translate from '../../Locale/Translate';

function FilterDropdown(props) {
  const { tittel, resetLabel, elementer, onChangeElement, onResetAll } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle caret>{tittel}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="kontrollpanel-filter-dropdown-reset"
          toggle={false}
          onClick={onResetAll}
        >
          {resetLabel}
        </DropdownItem>
        <DropdownItem divider />

        {elementer.map((item) => {
          return (
            <DropdownItem
              key={Math.random()}
              toggle={false}
              onClick={() => onChangeElement(item.element.id)}
            >
              <FilterCheckbox
                title={item.element.navn}
                value={item.element.id}
                checked={item.checked}
                onChange={() => onChangeElement(item.element.id)}
              />
            </DropdownItem>
          );
        })}
        {elementer.length === 0 && (
          <DropdownItem className="kontrollpanel-filter-tomt" disabled>
            <Translate id="kontrollpanel.filterdropdown.tom" />
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

FilterDropdown.propTypes = {
  tittel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  resetLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  elementer: PropTypes.arrayOf(PropTypes.shape),
  onChangeElement: PropTypes.func.isRequired,
  onResetAll: PropTypes.func.isRequired,
};

FilterDropdown.defaultProps = {
  tittel: 'Velg..',
  resetLabel: 'Nullstill filter',
  elementer: [],
};

export default FilterDropdown;
