import { sortBy } from 'lodash';
import httpClient from '../api/httpClient';

import { toastError, toastSuccess } from './toast';

const initKontrollpanel = {
  kontroller: [],
  dato: new Date(),
  valgtKontroller: null,
  loading: false,
};

const KONTROLLERDATECHANGE = 'kontroller/DATE_CHANGE';

const GETKONTROLLERREQUEST = 'kontroller/GET/REQUEST';
const GETKONTROLLERSUCCESS = 'kontroller/GET/SUCCESS';
const GETKONTROLLERERROR = 'kontroller/GET/ERROR';

const SET_LOADING_TRUE = 'kontroller/SET_LOADING_TRUE';
const SET_LOADING_FALSE = 'kontroller/SET_LOADING_FALSE';

export default function reducer(state = initKontrollpanel, action = {}) {
  switch (action.type) {
    case GETKONTROLLERREQUEST:
      return {
        ...state,
        kontroller: [],
        loading: true,
      };
    case GETKONTROLLERSUCCESS: {
      return {
        ...state,
        kontroller: sortBy(action.payload, ['dato', 'antallKontrollpunkter']),
        loading: false,
      };
    }
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };

    case KONTROLLERDATECHANGE: {
      return {
        ...state,
        dato: action.payload,
      };
    }

    default:
      return state;
  }
}

export function getKontroller() {
  return async (dispatch) => {
    dispatch({ type: GETKONTROLLERREQUEST });
    try {
      const res = await httpClient.getKontroller();

      dispatch({
        type: GETKONTROLLERSUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({ type: GETKONTROLLERERROR, payload: error });
      dispatch(toastError(error));
    }
  };
}

export function postKontroller(dato, antall) {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_TRUE });
    try {
      await httpClient.postKontroller(dato, antall);
      const res = await httpClient.getKontroller();
      dispatch({
        type: GETKONTROLLERSUCCESS,
        payload: res,
      });
      // dispatch(getKontroller);
      dispatch(
        toastSuccess({
          id: 'toast.successPostKontroller',
          defaultMessage: 'Antall kontroller ble lagret',
        })
      );
    } catch (error) {
      dispatch({ type: SET_LOADING_FALSE });
      dispatch(toastError(error));
    }
  };
}

export function updateKontroller(dato, antall, kontrollId) {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_TRUE });
    try {
      await httpClient.updateKontroller(dato, antall, kontrollId);
      // dispatch(getKontroller);
      const res = await httpClient.getKontroller();
      dispatch({
        type: GETKONTROLLERSUCCESS,
        payload: res,
      });
      dispatch(
        toastSuccess({
          id: 'toast.successPostKontroller',
          defaultMessage: 'Antall kontroller ble lagret',
        })
      );
    } catch (error) {
      dispatch({ type: SET_LOADING_FALSE });
      dispatch(toastError(error));
    }
  };
}

export function dateChange(date) {
  return (dispatch) => {
    dispatch({ type: KONTROLLERDATECHANGE, payload: date });
  };
}

// dispatch(
//   toastSuccess({
//     id: 'toast.successUpdateStatus',
//     defaultMessage: 'Status ble oppdatert',
//   })
// );
