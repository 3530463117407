import React from 'react';
import PropTypes from 'prop-types';
import { GeoJSON, Pane } from 'react-leaflet';
import { map } from 'lodash';

import { getSporFarge } from '../../utils/sporUtils';

const SporDataPane = (props) => {
  const {
    tracks,
    // style,
    // outlineStyle,
    setSpordetaljer,
    setSelectedTrack,
    selectedTrack,
    zoomLevel,
  } = props;

  const getTrackWidth = (featureSporId) => {
    if (featureSporId === selectedTrack.sporId) return 7;
    if (zoomLevel >= 17) return 7;
    if (zoomLevel <= 15) return 2;
    return 3;
  };

  const outlineStyle = (feature) => {
    const style = {
      weight: getTrackWidth(feature.properties.sporId) + 2,
      color: 'white',
    };
    return style;
  };

  const getSporFargeForDobbeltKode = (feature) => {
    if (
      feature.properties.dobbeltkode === undefined ||
      feature.properties.dobbeltkode === -1
    ) {
      return getSporFarge(feature.properties.utstyrLogg);
    }
    const ret = feature.properties.utstyrLogg.split(',');
    return getSporFarge(ret[feature.properties.dobbeltkode]);
  };

  const style = (feature) => {
    const s = {
      weight: getTrackWidth(feature.properties.sporId),
      border: 'black',
      borderWidth: 0.1,
    };

    if (feature.properties.sporId === selectedTrack.sporId) {
      s.opacity = 1.0;
    }

    if (
      feature.properties.dobbeltkode !== undefined &&
      feature.properties.dobbeltkode !== -1
    ) {
      s.color = getSporFargeForDobbeltKode(feature);
      s.fill = false;
      s.lineCap = 'butt';
      if (feature.properties.dobbeltkode === 1) {
        s.dashArray = '25 50';
      } else if (feature.properties.dobbeltkode === 2) {
        s.dashArray = '50 25';
      }
    } else {
      s.color = getSporFarge(feature.properties.utstyrLogg);
    }

    return s;
  };

  const onEachFeature = (feature, layer) => {
    if (layer.options.pane === 'overlayPane') {
      layer.on('add', (e) => {
        if (
          e.target.feature.properties.sporId === selectedTrack.sporId &&
          e.target.featre.properties.dobbeltkode !== 0
        ) {
          e.target.bringToFront();
        } else {
          e.target.bringToBack();
        }
      });
    }
    layer.on('mouseover', (e) => {
      e.target.setStyle({
        stroke: true,
        opacity: 1,
        color: '#78D3FF',
      });
      if (e.target.feature.properties.dobbeltkode !== 0) {
        e.target.bringToFront();
      }
    });

    layer.on('mouseout', (e) => {
      e.target.setStyle(style(e.target.feature));
      if (e.target.feature.properties.dobbeltkode !== 0) {
        e.target.bringToFront();
      }
    });

    layer.on('click', (e) => {
      setSpordetaljer(undefined);
      setSelectedTrack({
        kilde: e.target.options.data.kilde,
        sporId: e.target.feature.properties.sporId,
        latlng: e.latlng,
        lat: e.latlng.lat, // layer._latlngs[0].lat,
        lng: e.latlng.lng, // layer._latlngs[0].lng,
        featureId: feature.id,
      });
    });
  };

  return (
    <Pane name="custom-tracks">
      {selectedTrack.length > 0 ? (
        <>
          {map(selectedTrack, (item) => (
            <>
              <GeoJSON data={item} style={outlineStyle} key={Math.random()} />
              <GeoJSON
                data={item}
                style={style}
                key={Math.random()}
                onEachFeature={onEachFeature}
              />
            </>
          ))}
        </>
      ) : (
        <>
          {map(tracks, (item) => (
            <GeoJSON
              data={item}
              style={style}
              key={Math.random()}
              onEachFeature={onEachFeature}
            />
          ))}
        </>
      )}
    </Pane>
  );
};

SporDataPane.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  selectedTrack: PropTypes.arrayOf(PropTypes.shape({})),
  setSpordetaljer: PropTypes.func.isRequired,
  setSelectedTrack: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number,
};

SporDataPane.defaultProps = {
  tracks: [],
  selectedTrack: [],
  zoomLevel: 15,
};

export default SporDataPane;
