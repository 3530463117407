import React from 'react';
import moment from 'moment';
import Translate from '../Locale/Translate';

export const createAvvikFormData = (innmelding) => {
  const form = new FormData();
  if (innmelding.status) form.set('status', innmelding.status);
  if (innmelding.kommentar) form.set('kommentar', innmelding.kommentar);
  if (innmelding.utfort) form.set('utfort', innmelding.utfort);
  if (innmelding.seksjonId) form.set('seksjonId', innmelding.seksjonId);
  if (innmelding.kategoriId) form.set('kategoriId', innmelding.kategoriId);
  if (innmelding.arbeidstypeId)
    form.set('arbeidstypeId', innmelding.arbeidstypeId);
  if (innmelding.feiltypeId) form.set('feiltypeId', innmelding.feiltypeId);
  if (innmelding.veitypeId) form.set('veitypeId', innmelding.veitypeId);
  if (innmelding.kontraktId) form.set('kontraktId', innmelding.kontraktId);
  if (innmelding.latitude) form.set('latitude', innmelding.latitude);
  if (innmelding.longitude) form.set('longitude', innmelding.longitude);
  if (innmelding.omrade) form.set('omrade', innmelding.omrade); // TODO: Benytte id i stedet for navn
  if (innmelding.bydel) form.set('bydel', innmelding.bydel); // TODO: Benytte id i stedet for navn
  if (innmelding.adresse) form.set('adresse', innmelding.adresse);
  if (innmelding.botgrunnlag) form.set('botgrunnlag', innmelding.botgrunnlag);
  if (innmelding.bilder)
    innmelding.bilder.forEach((bilde) => form.append('bilder', bilde));
  if (innmelding.slettedeBilder)
    innmelding.slettedeBilder.forEach((sBilde) =>
      form.append('slettedeBilder', sBilde)
    );
  return form;
};

// eslint-disable-next-line import/prefer-default-export
export const isNullOrEmpty = (e) => !e || e === '' || e === {};

export const validateAvvik = (avvik, user) => {
  const validationState = { valid: true };
  if (isNullOrEmpty(avvik.utfort)) {
    validationState.valid = false;
    validationState.utfort = (
      <Translate
        defaultMessage="Tidspunkt for registreringen må fylles ut."
        id="innmelding-form.datetime-error"
      />
    );
  } else if (moment(avvik.utfort).isAfter(moment())) {
    validationState.valid = false;
    validationState.utfort = (
      <Translate
        defaultMessage="Tidspunkt for registreringen kan ikke være frem i tid."
        id="innmelding-form.datetime-future"
      />
    );
  }
  if (isNullOrEmpty(avvik.seksjonId)) {
    validationState.valid = false;
    validationState.seksjon = (
      <Translate
        defaultMessage="Seksjon må fylles ut."
        id="innmelding-form.seksjon-error"
      />
    );
  }
  if (isNullOrEmpty(avvik.kategoriId)) {
    validationState.valid = false;
    validationState.kategori = (
      <Translate
        defaultMessage="Kategori må fylles ut."
        id="innmelding-form.kategori-error"
      />
    );
  }
  if (isNullOrEmpty(avvik.arbeidstypeId)) {
    validationState.valid = false;
    validationState.arbeidstype = (
      <Translate
        defaultMessage="Arbeidstype må fylles ut."
        id="innmelding-form.type-arbeid-error"
      />
    );
  }
  if (isNullOrEmpty(avvik.feiltypeId)) {
    validationState.valid = false;
    validationState.feiltype = (
      <Translate
        defaultMessage="Feiltype må fylles ut."
        id="innmelding-form.type-feil-error"
      />
    );
  }
  if (isNullOrEmpty(avvik.veitypeId)) {
    validationState.valid = false;
    validationState.veitype = (
      <Translate
        defaultMessage="Veitype må fylles ut."
        id="innmelding-form.type-vei-error"
      />
    );
  }
  if (
    isNullOrEmpty(avvik.latitude) ||
    isNullOrEmpty(avvik.longitude) ||
    isNullOrEmpty(avvik.adresse)
  ) {
    validationState.valid = false;
    validationState.sted = (
      <Translate
        defaultMessage="Sted må fylles ut."
        id="innmelding-form.sted-feil-error"
      />
    );
  }
  if (isNullOrEmpty(avvik.bilder) || avvik.bilder.length === 0) {
    validationState.valid = false;
    validationState.bilder = (
      <Translate
        defaultMessage="Bilde(r) må vedlegges."
        id="innmelding-form.bilder-error"
      />
    );
  }
  if (user && !user.isEntreprenor() && isNullOrEmpty(avvik.kontraktId)) {
    validationState.valid = false;
    validationState.kontraktId = (
      <Translate
        defaultMessage="Entreprenør må angis"
        id="innmelding-form.entreprenorselskapId-error"
      />
    );
  }
  return validationState;
};

export const validateKladd = (avvik) => {
  const validationState = { valid: true };
  if (isNullOrEmpty(avvik.utfort)) {
    validationState.valid = false;
    validationState.utfort = (
      <Translate
        defaultMessage="Tidspunkt for registreringen må fylles ut."
        id="innmelding-form.datetime-error"
      />
    );
  } else if (moment(avvik.utfort).isAfter(moment())) {
    validationState.valid = false;
    validationState.utfort = (
      <Translate
        defaultMessage="Tidspunkt for registreringen kan ikke være frem i tid."
        id="innmelding-form.datetime-future"
      />
    );
  }
  if (isNullOrEmpty(avvik.bilder) || avvik.bilder.length === 0) {
    validationState.valid = false;
    validationState.bilder = (
      <Translate
        defaultMessage="Bilde(r) må vedlegges."
        id="innmelding-form.bilder-error"
      />
    );
  }

  return validationState;
};
