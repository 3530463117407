import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import httpClient from '../../api/httpClient';
import Translate from '../../Locale/Translate';

const Endpoints = () => {
  const [loading, setLoading] = useState(false);
  const [endpoints, setEndpoints] = useState([]);

  const sorterEndpoints = (endpoints) => {
    if (!endpoints || endpoints === null) return [];
    return endpoints.sort((a, b) => {
      if (a.path > b.path) return 1;
      if (a.path < b.path) return -1;
      return 0;
    });
  };

  const getEndpoints = async () => {
    try {
      setLoading(true);
      const resp = await httpClient.getEndpoints();
      setEndpoints(resp);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEndpoints();
  }, []);

  return (
    <div>
      <PulseLoader loading={loading} />
      <Table striped>
        <thead>
          <tr>
            <th>Method</th>
            <th>Path</th>
            <th>AllowAnonymous</th>
            <th>Roller</th>
          </tr>
        </thead>
        <tbody>
          {endpoints.map((endpoint) => (
            <tr>
              <td>{endpoint.method}</td>
              <td>{endpoint.path}</td>
              <td>{endpoint.allowAnonymous ? 'X' : ''}</td>
              <td>
                {sorterEndpoints(endpoint.allowedRoles).map((rolleId) => (
                  <>
                    <Translate id={`rolle.${rolleId}`} />
                    <br />
                  </>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Endpoints;
