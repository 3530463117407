import React from 'react';

const Healthcheck = () => (
  <>
    <h1>Healthcheck</h1>
    <p>Kjører som normalt</p>
    <p>Versjon : 0.0.2</p>
  </>
);

export default Healthcheck;
