import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { LocaleContext } from './LocaleProvider';

const Translate = ({ ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const localeContext = useContext(LocaleContext);
  return <FormattedMessage {...props} />;
};

export default Translate;
