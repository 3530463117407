import avvikKontrollorIcon from '../images/map_pin_avvik_fra_kontrollør.svg';
import alvorligAvvikKontrollorIcon from '../images/map_pin_alvorlig_avvik_fra_kontrollør.svg';
import ferdigbehandletAvvikKontrollorIcon from '../images/map_pin_ferdigbehandlet_avvik_fra_kontrollør.svg';
import ferdigbehandletAlvorligAvvikKontrollorIcon from '../images/map_pin_ferdigbehandlet_alvorlig_avvik_fra_kontrollør.svg';
import ubehandletBymeldingFraPrivatpersonIcon from '../images/map_pin_bymelding_fra_publikum_ikke_behandlet.svg';
import underBehandlingBymeldingFraPrivatpersonIcon from '../images/map_pin_bymelding_fra_publikum_under_behandling.svg';
import avvikEntreprenorIcon from '../images/map_pin_varsel_fra_entreprenør.svg';
import valgtAlvorligAvvikFraKontrollor from '../images/valgt_alvorlig_avvik_fra_kontrollør.svg';
import valgtAvvikFraKontrollor from '../images/valgt_avvik_fra_kontrollør.svg';

export const AVVIKTYPE_KONTROLLOR = '080693ae-e078-4e41-9045-39eabf96f2f6';
export const AVVIKTYPE_ENTREPRENOR = 'c9d0d4ec-bcd2-4ffb-bfda-ff958b54129b';
export const AVVIKTYPE_PUBLIKUM = 'ce8b4b51-6a91-47a5-9aa8-993f0ef5da1b';

export const AVVIKSTATUS_BEHANDLET = 'f2c91a82-0bdc-4072-84f7-5a2d15f97ab3';
export const AVVIKSTATUS_IKKE_BEHANDLET =
  '52584cb4-b9b4-4978-be38-d87ccf149efc';
export const AVVIKSTATUS_KLADD = 'b2b1de1b-4fa5-44a3-a593-4d2294efa3bf';

export const getValgtAvvikIcon = (item) => {
  if (item.botgrunnlag) {
    return valgtAlvorligAvvikFraKontrollor;
  }
  return valgtAvvikFraKontrollor;
};

export const getAvvikIcon = (item) => {
  switch (item.avviktypeId.toLowerCase()) {
    case AVVIKTYPE_KONTROLLOR:
      if (item.botgrunnlag) {
        if (item.status === AVVIKSTATUS_BEHANDLET) {
          return ferdigbehandletAlvorligAvvikKontrollorIcon;
        }
        return alvorligAvvikKontrollorIcon;
      }
      if (item.status === AVVIKSTATUS_BEHANDLET) {
        return ferdigbehandletAvvikKontrollorIcon;
      }
      return avvikKontrollorIcon;
    case AVVIKTYPE_ENTREPRENOR:
      return avvikEntreprenorIcon;
    case AVVIKTYPE_PUBLIKUM:
      if (item.status === 'under behandling') {
        // TODO: Fiks nå status er ferdig
        return underBehandlingBymeldingFraPrivatpersonIcon;
      }
      return ubehandletBymeldingFraPrivatpersonIcon;
    default:
      return avvikKontrollorIcon;
  }
};

export const getPublikummeldingIcon = (item) => {
  if (item.meldingsstatusId === 4) {
    return underBehandlingBymeldingFraPrivatpersonIcon;
  }
  return ubehandletBymeldingFraPrivatpersonIcon;
};

export const getFormattedAddress = (adresse) => {
  const commaIndex = adresse ? adresse.indexOf(',') : 0;
  return commaIndex > 0 ? adresse.substring(0, commaIndex) : adresse;
};
