import httpClient from '../api/httpClient';
import { toastError } from './toast';

const MELDINGERSUCCESS = 'dashboard/meldinger/SUCCESS';
const MELDINGERERROR = 'dashboard/meldinger/ERROR';
const MELDINGERREQUEST = 'dashboard/meldinger/REQUEST';

export default function reducer(
  state = {
    meldinger: {},
    loading: false,
  },
  action = {}
) {
  switch (action.type) {
    case MELDINGERSUCCESS:
      return {
        ...state,
        meldinger: action.payload,
        loading: false,
      };
    case MELDINGERERROR:
      return {
        ...state,
        meldinger: {},
        loading: false,
      };
    case MELDINGERREQUEST:
      return {
        ...state,
        meldinger: {},
        loading: true,
      };

    default:
      return state;
  }
}

const getDashboardMeldingerSuccess = (res) => ({
  type: MELDINGERSUCCESS,
  payload: res,
});

const getDashboardMeldingerError = (error) => ({
  type: MELDINGERERROR,
  payload: error,
});

export function getDashboardMeldinger(fra = null, til = null) {
  return async (dispatch) => {
    dispatch({
      type: MELDINGERREQUEST,
    });
    try {
      const data = await httpClient.getMeldingsstatistikk(fra, til);
      dispatch(getDashboardMeldingerSuccess({ ...data, fra, til }));
    } catch (error) {
      dispatch(getDashboardMeldingerError(error));
      dispatch(toastError(error));
    }
  };
}
