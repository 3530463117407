import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import moment from 'moment';
import locale_nb from 'moment/locale/nb'; // eslint-disable-line
import httpClient from '../../api/httpClient';
import { StatiskeDataContext } from '../Common/StatiskeDataProvider';
import { LocaleContext } from '../../Locale/LocaleProvider';
import Translate from '../../Locale/Translate';

const ForbrukSpor = (props) => {
  const { kilde, sporId, onClose } = props;
  const [forbruk, setForbruk] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [vaerdata, setVaerdata] = useState(undefined);
  const [vaericon, setVaericon] = useState(undefined);
  const { statiskeData } = useContext(StatiskeDataContext);
  const localeContext = useContext(LocaleContext);

  const getFormattedDateTime = (dato) => {
    moment.updateLocale(localeContext.currentLocale.locale, locale_nb);
    return moment(dato, moment.ISO_8601).format('DD.MM.YYYY [kl.] HH:mm');
  };

  const getFormattedDate = (dato) => {
    moment.updateLocale(localeContext.currentLocale.locale, locale_nb);
    return moment(dato, moment.ISO_8601).format('D. MMMM YYYY');
  };

  const getFormattedWeekDay = (dato) => {
    moment.updateLocale(localeContext.currentLocale.locale, locale_nb);
    return moment(dato, moment.ISO_8601).format('dddd');
  };

  const summerRessursbruk = (keyVal) => {
    if (keyVal) {
      const keys = Object.keys(keyVal);
      if (keys.length > 0) {
        return keys
          .map((key) => keyVal[key].lengde)
          .reduce((total, current) => total + current);
      }
    }
    return 0;
  };

  const fetchSporinfo = async (k, sId) => {
    if (k && sId) {
      setLoading(true);

      const resp = await httpClient.getForbrukForSpor(k, sId);
      setLoading(false);
      setForbruk(resp[0]);
    }
  };

  const fetchVaerInfo = async (dateTimeString) => {
    if (dateTimeString) {
      const resp = await httpClient.getHistoriskeVaerdata(dateTimeString);
      setVaerdata(resp);
      if (resp.vaertypeSymbolNavn != null) {
        const importedIcon = await import(
          `../../images/weathericons/${resp.vaertypeSymbolNavn}.svg`
        );
        setVaericon(importedIcon.default);
      }
    }
  };

  const getFormattedEntreprenor = (sporingId) => {
    if (!statiskeData || !statiskeData.entreprenorselskaper) return sporingId;
    const selskap = statiskeData.entreprenorselskaper.find((s) =>
      s?.sporingId?.includes(sporingId)
    );
    return (selskap && `${selskap.navn} (${sporingId})`) || sporingId;
  };

  useEffect(() => {
    if (kilde && sporId) {
      setForbruk(undefined);
      setVaerdata(undefined);

      fetchSporinfo(kilde, sporId);
    }
  }, [kilde, sporId]);

  useEffect(() => {
    if (forbruk) {
      fetchVaerInfo(forbruk.fra);
    }
  }, [forbruk]);

  const tiltak =
    forbruk &&
    forbruk.ressursbruk &&
    forbruk.ressursbruk.tiltak &&
    Object.keys(forbruk.ressursbruk.tiltak)
      .map(
        (tiltakId) =>
          localeContext.currentLocale.messages[`tiltaktype.${tiltakId}`] ||
          tiltakId
      )
      .join(', ');
  const entreprenor =
    forbruk &&
    forbruk.entreprenor &&
    getFormattedEntreprenor(forbruk.entreprenor);
  const totalTid =
    forbruk && forbruk.ressursbruk && forbruk.ressursbruk.arbeidstid;
  const start = forbruk && getFormattedDateTime(forbruk.fra);
  const stopp = forbruk && getFormattedDateTime(forbruk.til);
  const maskinId =
    forbruk &&
    forbruk.ressursbrukPrMaskin[0] &&
    forbruk.ressursbrukPrMaskin[0].maskinId;
  const antallKm =
    forbruk &&
    forbruk.ressursbruk &&
    forbruk.ressursbruk.lengde &&
    `${(forbruk.ressursbruk.lengde / 1000).toFixed(2)} km`;
  const gjennomsnittHastighet =
    forbruk &&
    forbruk.ressursbruk &&
    forbruk.ressursbruk.lengde &&
    forbruk.ressursbruk.arbeidstid &&
    `${(
      (forbruk.ressursbruk.lengde /
        moment.duration(forbruk.ressursbruk.arbeidstid).asSeconds()) *
      3.6
    ).toFixed(0)} km/t`;

  const ukedag = forbruk && getFormattedWeekDay(forbruk.fra);
  const dato = forbruk && getFormattedDate(forbruk.fra);

  return (
    <div id="forbruk-spor">
      <div className="header-row">
        <h3>{tiltak}</h3>
        <PulseLoader sizeUnit="px" size={10} color="#000" loading={loading} />
        <Button color="link" onClick={() => onClose()}>
          <Translate id="forbrukSpor.lukkeknapp.tekst" />
        </Button>
      </div>
      <div className="forbruk-spor-row">
        <div id="forbruk-spor-oppsummering">
          <table>
            <tbody>
              <tr>
                <th>
                  <Translate id="forbrukSpor.entreprenor" />
                </th>
                <td>{entreprenor}</td>
              </tr>
              <tr>
                <th>
                  <Translate id="forbrukSpor.tidsforbruk" />
                </th>
                <td>{totalTid}</td>
              </tr>
              <tr>
                <th>
                  <Translate id="forbrukSpor.startTidspunkt" />
                </th>
                <td>{start}</td>
              </tr>
              <tr>
                <th>
                  <Translate id="forbrukSpor.stoppTidspunkt" />
                </th>
                <td>{stopp}</td>
              </tr>
              <tr>
                <th>
                  <Translate id="forbrukSpor.maskinId" />
                </th>
                <td>{maskinId}</td>
              </tr>
              <tr>
                <th>
                  <Translate id="forbrukSpor.kjortLengde" />
                </th>
                <td>{antallKm}</td>
              </tr>
              <tr>
                <th>
                  <Translate id="forbrukSpor.gjennomsnittshastighet" />
                </th>
                <td>{gjennomsnittHastighet}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="forbruk-spor-vaer">
          <div className="dato-container">
            <div className="ukedag">{ukedag}</div>
            <div className="dato">{dato}</div>
          </div>
          {vaerdata && vaericon && (
            <div className="vaer-container">
              <img
                src={vaericon || ''}
                alt={vaerdata.vaertypeBeskrivelse || ''}
                title={vaerdata.vaertypeBeskrivelse || ''}
              />
              <div>
                <div>
                  <span>{vaerdata.temperaturC} &deg;C</span>
                </div>
                <div>
                  <span>{vaerdata.nedboerMm} mm</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="forbruk-spor-row">
        <div id="forbruk-spor-ressursforbruk">
          <h3>
            <Translate id="forbrukSpor.heading.ressursforbruk" />
          </h3>
          <table>
            <tbody>
              {forbruk &&
                forbruk.ressursbruk &&
                forbruk.ressursbruk.tort &&
                Object.keys(forbruk.ressursbruk.tort).map((key) => {
                  return (
                    <tr key={key}>
                      <th>{key}</th>
                      <td>
                        {forbruk.ressursbruk.tort[key].material.toFixed(2)} kg
                      </td>
                    </tr>
                  );
                })}
              {forbruk &&
                forbruk.ressursbruk &&
                forbruk.ressursbruk.vaeske &&
                Object.keys(forbruk.ressursbruk.vaeske).map((key) => {
                  return (
                    <tr key={key}>
                      <th>{key}</th>
                      <td>
                        {forbruk.ressursbruk.vaeske[key].material.toFixed(2)} l
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {forbruk &&
            forbruk.ressursbruk &&
            forbruk.ressursbruk.tort &&
            forbruk.ressursbruk.lengde && (
              <SimpleGraph
                label={<Translate id="forbrukSpor.ressursbruk.heading.tort" />}
                max={forbruk.ressursbruk.lengde}
                value={summerRessursbruk(forbruk.ressursbruk.tort)}
              />
            )}

          {forbruk &&
            forbruk.ressursbruk &&
            forbruk.ressursbruk.vaeske &&
            forbruk.ressursbruk.lengde && (
              <SimpleGraph
                label={
                  <Translate id="forbrukSpor.ressursbruk.heading.vaeske" />
                }
                max={forbruk.ressursbruk.lengde}
                value={summerRessursbruk(forbruk.ressursbruk.vaeske)}
              />
            )}
        </div>
      </div>
    </div>
  );
};

const SimpleGraph = (props) => {
  const { label, max, value } = props;

  const percentage = (value / max) * 100;
  return (
    <div className="simple-graph-container">
      <div className="simple-graph-header">
        <div>{label}</div>
        <div>
          {`${(value / 1000).toFixed(2)} km / ${(max / 1000).toFixed(2)} km`}
        </div>
      </div>
      <div className="simple-graph" style={{ width: '100%' }}>
        <div
          className="simple-graph-value"
          style={{
            width: `${percentage}%`,
          }}
        />
      </div>
    </div>
  );
};

export default ForbrukSpor;
