/* eslint-disable no-lone-blocks */
import React from 'react';
import PropTypes from 'prop-types';
import { FaSync, FaCheck } from 'react-icons/fa';
import { Button } from 'reactstrap';
import Translate from '../../Locale/Translate';
import { ReactComponent as LoadingIcon } from '../../images/glyphicons-loading.svg';

const MapIsDirtyIndikator = ({ isDirty, laster, onClick }) => {
  if (laster) {
    return (
      <Translate id="mapIsDirty.tooltip.loading">
        {(msg) => (
          <Button
            alt={msg}
            title={msg}
            color="secondary"
            className="isdirty-btn isdirty-loading"
            disabled
          >
            <LoadingIcon />
          </Button>
        )}
      </Translate>
    );
  }
  if (isDirty) {
    return (
      <Translate id="mapIsDirty.tooltip.dirty">
        {(msg) => (
          <Button
            className="isdirty-btn isdirty-dirty"
            onClick={onClick}
            alt={msg}
            title={msg}
            color="primary"
          >
            <FaSync color="#fff" size="1.5em" />
          </Button>
        )}
      </Translate>
    );
  }
  return (
    <Translate id="mapIsDirty.tooltip.success">
      {(msg) => (
        <Button
          color="success"
          className="isdirty-btn isdirty-success"
          alt={msg}
          title={msg}
          disabled
        >
          <FaCheck color="#fff" size="1.5em" />
        </Button>
      )}
    </Translate>
  );
};

MapIsDirtyIndikator.propTypes = {
  isDirty: PropTypes.bool,
  laster: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
MapIsDirtyIndikator.defaultProps = {
  isDirty: true,
  laster: false,
};

export default MapIsDirtyIndikator;
