import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { find, isEmpty } from 'lodash';
import { connect } from 'react-redux';
// import moment from 'moment';
import EntrepenoerkontrollMap from './EntrepenoerkontrollMap';
import EntrepenoerkontrollFilter from '../Filter/FilterContaniner';
import Shortcutbar from '../Navigation/Shortcutbar';

// import { layerInfoUrl } from '../../utils/mapUtils';
import { filtrerKartData } from '../../utils/kartdataFilterUtils';
import {
  getKartdata as getKartdataAction,
  onMapViewportChanged as onMapViewportChangedAction,
} from '../../state/kartdata';
import { getSpordetaljer as getSpordetaljerAction } from '../../state/spordetaljer';
import {
  getDriftsomradeKartlagsnavn,
  getBydelKartlagsnavn,
} from '../../state/filterOmradeOptions';
import MapIsDirtyIndikator from './MapIsDirtyIndikator';
import Translate from '../../Locale/Translate';
import SporingsdataInfo from './SporingsdataInfo.tsx';

class MapPage extends React.Component {
  constructor(props) {
    super(props);
    // this.filterRef = React.createRef();
    this.state = {
      isMobileFilerOpen: false,
      selectedTrack: {
        kilde: undefined,
        sporId: undefined,
        lat: undefined,
        lng: undefined,
        featureId: undefined,
      },
    };
  }

  setSelectedTrack = (state) => {
    this.setState({ selectedTrack: state });
  };

  setisMobileFilerOpen = (isOpen) => {
    this.setState({ isMobileFilerOpen: isOpen });
  };

  getBounds = () => {
    const { filter, statiskeData } = this.props;
    let bounds;
    if (
      !filter ||
      !statiskeData ||
      !statiskeData.bydeler ||
      !statiskeData.driftsomrader
    ) {
      return undefined;
    }

    if (filter.bydel && filter.bydel.length > 0) {
      const kartlagsnavn = getBydelKartlagsnavn(filter.bydel);
      const kartlag = statiskeData.bydeler.features.filter(
        (item) => item.properties.bydelsnavn === kartlagsnavn
      );

      if (!kartlag || kartlag.length === 0) return undefined;

      bounds = kartlag
        .map((item) => item.geometry.coordinates[0])[0]
        .map((pos) => [pos[1], pos[0]]);
    } else if (filter.driftsomrade && filter.driftsomrade.length > 0) {
      const kartlagsnavn = getDriftsomradeKartlagsnavn(filter.driftsomrade);
      const kartlag = statiskeData.driftsomrader.features.filter(
        (item) => item.properties.STRENG === kartlagsnavn
      );

      if (!kartlag || kartlag.length === 0) return undefined;

      bounds = kartlag
        .map((item) => item.geometry.coordinates[0])[0]
        .map((pos) => [pos[1], pos[0]]);
    }
    return bounds;
  };

  onGetKartdata = () => {
    const { getKartdata, filter } = this.props;
    this.setSelectedTrack({
      kilde: undefined,
      sporId: undefined,
      lat: undefined,
      lng: undefined,
      featureId: undefined,
    });
    getKartdata(filter);
    this.setisMobileFilerOpen(false);
  };

  render() {
    const {
      getKartdata,
      onMapViewportChanged,
      kartData,
      filter,
      getSpordetaljer,
      spordetaljer,
      shouldUpdateHeader,
    } = this.props;
    const {
      layers,
      isMobileFilerOpen,
      selectedTrack,
      // sisteSporTimestamp,
    } = this.state;

    return (
      <>
        <div
          className={`kartdata-isdirty-mobile medium-minus-only ${
            isMobileFilerOpen
              ? 'isdirty-mobile-filter-open'
              : 'isdirty-mobile-filter-closed'
          }`}
        >
          <MapIsDirtyIndikator
            isDirty={
              filter.isDirty ||
              !find(kartData.geoJSON, (a) => !isEmpty(a.features))
            }
            laster={kartData.loading}
            onClick={this.onGetKartdata}
          />
        </div>
        <Row className="mappage-container">
          <Col
            xl={{ size: 3 }}
            lg={{ size: 4 }}
            md={{ size: 5 }}
            className="filterContainer medium-plus-only"
          >
            <div className="driftskart-header-container">
              <div className="driftskart-header">
                <h1>
                  <Translate id="driftskart.heading" />
                </h1>
                <MapIsDirtyIndikator
                  isDirty={
                    filter.isDirty ||
                    !find(kartData.geoJSON, (a) => !isEmpty(a.features))
                  }
                  laster={kartData.loading}
                  onClick={this.onGetKartdata}
                />
              </div>

              <div className="driftskart-sub-header">
                <div className="driftskart-sub-header">
                  {!kartData.loading && (
                    <SporingsdataInfo shouldUpdate={shouldUpdateHeader} />
                  )}
                </div>
              </div>
            </div>

            <EntrepenoerkontrollFilter calendarId="driftskart-filterCalendar" />
          </Col>
          <Col
            xl={{ size: 9 }}
            lg={{ size: 8 }}
            md={{ size: 7 }}
            className="entrepenoerkonrtroll-map"
          >
            <EntrepenoerkontrollMap
              layers={layers}
              onMapViewportChanged={onMapViewportChanged}
              kartData={filtrerKartData(kartData, filter)}
              getSpordetaljer={getSpordetaljer}
              spordetaljer={spordetaljer}
              defaultCenter={
                filter &&
                filter.latitude &&
                filter.longitude && [filter.latitude, filter.longitude]
              }
              bounds={this.getBounds()}
              showTooltip
              autoBounds
              onRefreshRequested={() => getKartdata(filter)}
              filter={filter}
              selectedTrack={selectedTrack}
              setSelectedTrack={this.setSelectedTrack}
            />
          </Col>
        </Row>

        <Col xs={{ size: 12, order: 3 }} className="layersContainer" />
        <Shortcutbar
          menuComponent={
            <EntrepenoerkontrollFilter
              // onGetKartdata={this.onGetKartdata}
              calendarId="shortcutbar-filterCalendar"
              shouldUpdateHeader={shouldUpdateHeader}
            />
          }
          // ref={this.filterRef}
          isOpen={isMobileFilerOpen}
          setIsOpen={this.setisMobileFilerOpen}
        />
      </>
    );
  }
}

MapPage.propTypes = {
  getKartdata: PropTypes.func.isRequired,
  getSpordetaljer: PropTypes.func.isRequired,
  onMapViewportChanged: PropTypes.func.isRequired,
  kartData: PropTypes.shape({}).isRequired,
  filter: PropTypes.shape({}).isRequired,
  spordetaljer: PropTypes.shape({}),
  statiskeData: PropTypes.shape({}).isRequired,
  shouldUpdateHeader: PropTypes.bool,
};

MapPage.defaultProps = {
  spordetaljer: null,
  shouldUpdateHeader: true,
};

const mapStateToProps = (state) => ({
  kartData: state.kartdata,
  filter: state.filter.filter,
  spordetaljer: state.spordetaljer,
  statiskeData: state.statiskeData,
});

const mapDispatchToProps = (dispatch) => ({
  getKartdata: (filter) => dispatch(getKartdataAction(filter)),
  getSpordetaljer: (punktinfo) => dispatch(getSpordetaljerAction(punktinfo)),
  onMapViewportChanged: (viewport) =>
    dispatch(onMapViewportChangedAction(viewport)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
