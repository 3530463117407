import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
// import intlNB from 'react-intl/locale-data/nb';
// import intlEN from 'react-intl/locale-data/en';
import enkStore from '../utils/localStoreUtils';
import nb from './nb';
import en from './en';

// addLocaleData([...intlEN, ...intlNB]);

export const LocaleContext = React.createContext();

const noConfig = {
  locale: 'nb',
  messages: nb,
  available: ['en'],
  lCode: 'nb-NO',
};

const enConfig = {
  locale: 'en',
  messages: en,
  available: ['nb'],
  lCode: 'en-US',
};

const LocaleProvider = ({ defaultConfig, children }) => {
  const [config, setConfig] = useState(defaultConfig);

  useEffect(() => {
    enkStore
      .getItem('locale')
      .then((res) => {
        if (res === 'en') {
          enkStore.setItem('locale', 'en');
          setConfig(enConfig);
        } else {
          enkStore.setItem('locale', 'nb');
          setConfig(defaultConfig);
        }
      })
      .catch(() => setConfig(defaultConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeLanguage = async (lang) => {
    try {
      if (lang === 'en') {
        enkStore.setItem('locale', 'en');
        setConfig(enConfig);
      } else {
        enkStore.setItem('locale', 'nb');
        setConfig(defaultConfig);
      }
    } catch (err) {
      setConfig(defaultConfig);
    }
  };

  return (
    <IntlProvider locale={config.locale} messages={config.messages}>
      <LocaleContext.Provider
        value={{ onChangeLanguage, currentLocale: config }}
      >
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultConfig: PropTypes.shape({}),
};

LocaleProvider.defaultProps = {
  defaultConfig: noConfig,
};

export default LocaleProvider;
