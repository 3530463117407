import React, { useContext } from 'react';
import { arrayOf, shape, func } from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import FilterElement from './FilterElement';
import FilterDropdown from './FilterDropdown';
import OmradeFilterDropdown from './OmradeFilterDropdown';
import Translate from '../../Locale/Translate';
import { sortByString } from '../../utils/sortUtils';
import { LocaleContext } from '../../Locale/LocaleProvider';
import { UserContext } from '../../User/UserProvider.tsx';
import { omradeFlatList } from '../../state/filterOmradeOptions';
import { initFilter } from '../../state/kontrollpanel';

function Filter(props) {
  const { statiskeData, onFilterChanged, filter } = props;
  const localeContext = useContext(LocaleContext);
  const user = useContext(UserContext);
  const hierarki = statiskeData && statiskeData.feiltypeHierarki;

  const selectedFilters = {
    seksjoner: filter.seksjoner,
    kategorier: filter.kategorier,
    kontrolltyper: filter.kontrolltyper,
    statuskoder: filter.statuskoder,
    alvorlig: filter.alvorlig,
    omrader: filter.omrader,
    avviktyper: filter.avviktyper,
    entreprenorselskaper: filter.entreprenorselskaper,
    kontrakter: filter.kontrakter,
    feiltyper: filter.feiltyper,
  };

  const omradelist = omradeFlatList();

  const avvikStatuskoder =
    statiskeData.statuskoder &&
    statiskeData.statuskoder.Avvik &&
    statiskeData.statuskoder.Avvik.map((status) => {
      return {
        id: status.id,
        navn: status.navn,
      };
    }).sort((a, b) => {
      return sortByString(a.navn, b.navn);
    });

  const alvorlighetsgrader = [
    {
      id: true,
      navn: localeContext.currentLocale.messages[
        'kontrollpanel.avvik-status.alvorlighetsgrad.alvorlig'
      ],
    },
    {
      id: false,
      navn: localeContext.currentLocale.messages[
        'kontrollpanel.avvik-status.alvorlighetsgrad.ikke-alvorlig'
      ],
    },
  ];

  const isElementAddedToFilter = (type, id) => {
    return (
      filter[type] &&
      filter[type].findIndex((element) => {
        return element.id === id;
      }) >= 0
    );
  };

  const generateFilterElementer = (type, kilde, translateKeyGroup) => {
    const elementerMedCheckedStatus = kilde
      .map((element) => {
        return {
          element: {
            ...element,
            navn: !translateKeyGroup
              ? element.navn
              : localeContext.currentLocale.messages[
                  `${translateKeyGroup}.${element.id}`
                ] || element.navn,
          },
          checked: isElementAddedToFilter(type, element.id),
        };
      })
      .sort((a, b) => {
        return sortByString(a.element.navn, b.element.navn);
      });

    return elementerMedCheckedStatus;
  };

  const fjernFilter = (type, id) => {
    const oppdatertFilter = { ...filter };
    oppdatertFilter[type] = filter[type].filter((element) => element.id !== id);

    // // setFilterelementer(oppdatertFilter);
    onFilterChanged(oppdatertFilter);
  };

  const addMultipleFilters = (type, filterIdListe, filterSource) => {
    filterSource.sort((a, b) => {
      return sortByString(a.navn, b.navn);
    });

    const itemListe = filterIdListe
      .filter((f) => !isElementAddedToFilter(type, f))
      .map((id) => filterSource.find((el) => el.id === id));
    const oppdatertFiltertype = [...filter[type], ...itemListe];
    const oppdatertFilter = { ...filter };
    oppdatertFilter[type] = oppdatertFiltertype;
    // setFilterelementer(oppdatertFilter);
    onFilterChanged(oppdatertFilter);
  };

  const removeMultipleFilters = (type, filterIdListe) => {
    const oppdatertFilter = { ...filter };
    oppdatertFilter[type] = filter[type].filter(
      (element) => filterIdListe.find((f) => element.id === f.id) !== undefined
    );

    // setFilterelementer(oppdatertFilter);
    onFilterChanged(oppdatertFilter);
  };

  const updateFilter = (type, filterId, filterSource, translateKeyGroup) => {
    filterSource.sort((a, b) => {
      return sortByString(a.navn, b.navn);
    });

    if (isElementAddedToFilter(type, filterId)) {
      fjernFilter(type, filterId);
      return;
    }

    // oversett
    const element = filterSource.find((e) => e.id === filterId);
    element.navn = !translateKeyGroup
      ? element.navn
      : localeContext.currentLocale.messages[
          `${translateKeyGroup}.${element.id}`
        ] || element.navn;

    const oppdatertFiltertype = [...filter[type], element];
    const oppdatertFilter = { ...filter };
    oppdatertFilter[type] = oppdatertFiltertype;

    // setFilterelementer(oppdatertFilter);
    onFilterChanged(oppdatertFilter);
  };

  const resetFilter = (type, filterSource) => {
    filterSource.sort((a, b) => {
      return sortByString(a.navn, b.navn);
    });

    const oppdatertFiltertype = filter[type].filter((element) => {
      return (
        filterSource.findIndex((item) => {
          return item.id === element.id;
        }) < 0
      );
    });

    const oppdatertFilter = { ...filter };
    oppdatertFilter[type] = oppdatertFiltertype;

    // setFilterelementer(oppdatertFilter);
    onFilterChanged(oppdatertFilter);
  };

  const resetAlleFilter = () => {
    // setFilterelementer(emptyFilter);
    onFilterChanged(initFilter);
  };

  const filterHasValues = () => {
    return (
      Object.keys(selectedFilters).filter((key) => {
        return selectedFilters[key] && selectedFilters[key].length > 0;
      }).length > 0
    );
  };

  return (
    <>
      {hierarki && (
        <>
          <Row className="kontrollpanel-filter">
            <div className="kontrollpanel-filter-tittel">
              <Translate
                id="kontrollpanel.filterdropdown.tittel"
                defaultMessage="Filter"
              />
            </div>

            <Col className="kontrollpanel-filter-filters">
              {hierarki.seksjoner && (
                <FilterDropdown
                  tittel={
                    <Translate id="kontrollpanel.filterdropdown.seksjoner.tittel" />
                  }
                  resetLabel={
                    <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                  }
                  elementer={generateFilterElementer(
                    'seksjoner',
                    hierarki.seksjoner,
                    'seksjon'
                  )}
                  onChangeElement={(id) =>
                    updateFilter('seksjoner', id, hierarki.seksjoner)
                  }
                  onResetAll={() =>
                    resetFilter('seksjoner', hierarki.seksjoner)
                  }
                />
              )}
              {hierarki.kategorier && (
                <FilterDropdown
                  tittel={
                    <Translate id="kontrollpanel.filterdropdown.kategorier.tittel" />
                  }
                  resetLabel={
                    <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                  }
                  elementer={generateFilterElementer(
                    'kategorier',
                    hierarki.kategorier,
                    'kategori'
                  )}
                  onChangeElement={(id) =>
                    updateFilter(
                      'kategorier',
                      id,
                      hierarki.kategorier,
                      'kategori'
                    )
                  }
                  onResetAll={() =>
                    resetFilter('kategorier', hierarki.kategorier)
                  }
                />
              )}
              {hierarki.kontrolltyper && (
                <FilterDropdown
                  tittel={
                    <Translate id="kontrollpanel.filterdropdown.kontrolltyper.tittel" />
                  }
                  resetLabel={
                    <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                  }
                  elementer={generateFilterElementer(
                    'kontrolltyper',
                    hierarki.kontrolltyper,
                    'kontrolltype'
                  )}
                  onChangeElement={(id) =>
                    updateFilter(
                      'kontrolltyper',
                      id,
                      hierarki.kontrolltyper,
                      'kontrolltype'
                    )
                  }
                  onResetAll={() =>
                    resetFilter('kontrolltyper', hierarki.kontrolltyper)
                  }
                />
              )}
              {hierarki.feiltyper && (
                <FilterDropdown
                  tittel={
                    <Translate id="kontrollpanel.filterdropdown.feiltyper.tittel" />
                  }
                  resetLabel={
                    <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                  }
                  elementer={generateFilterElementer(
                    'feiltyper',
                    hierarki.feiltyper,
                    'feiltype'
                  )}
                  onChangeElement={(id) =>
                    updateFilter(
                      'feiltyper',
                      id,
                      hierarki.feiltyper,
                      'feiltype'
                    )
                  }
                  onResetAll={() =>
                    resetFilter('feiltyper', hierarki.feiltyper)
                  }
                />
              )}
              {user.isInternBymbruker() && hierarki.avviktyper && (
                <FilterDropdown
                  tittel={
                    <Translate id="kontrollpanel.filterdropdown.avviktyper.tittel" />
                  }
                  resetLabel={
                    <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                  }
                  elementer={generateFilterElementer(
                    'avviktyper',
                    hierarki.avviktyper,
                    'avviktype'
                  )}
                  onChangeElement={(id) =>
                    updateFilter(
                      'avviktyper',
                      id,
                      hierarki.avviktyper,
                      'avviktype'
                    )
                  }
                  onResetAll={() =>
                    resetFilter('avviktyper', hierarki.avviktyper)
                  }
                />
              )}
              {user.isInternBymbruker() &&
                statiskeData &&
                statiskeData.entreprenorselskaper && (
                  <FilterDropdown
                    tittel={
                      <Translate id="kontrollpanel.filterdropdown.entreprenorselskaper.tittel" />
                    }
                    resetLabel={
                      <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                    }
                    elementer={generateFilterElementer(
                      'entreprenorselskaper',
                      statiskeData.entreprenorselskaper,
                      'kontrakt.selskap'
                    )}
                    onChangeElement={(id) =>
                      updateFilter(
                        'entreprenorselskaper',
                        id,
                        statiskeData.entreprenorselskaper,
                        'kontrakt.selskap'
                      )
                    }
                    onResetAll={() =>
                      resetFilter(
                        'entreprenorselskaper',
                        statiskeData.entreprenorselskaper
                      )
                    }
                  />
                )}
              {user.isInternBymbruker() &&
                statiskeData &&
                statiskeData.kontrakter && (
                  <FilterDropdown
                    tittel={
                      <Translate id="kontrollpanel.filterdropdown.kontrakter.tittel" />
                    }
                    resetLabel={
                      <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                    }
                    elementer={generateFilterElementer(
                      'kontrakter',
                      statiskeData.kontrakter,
                      'kontrakt'
                    )}
                    onChangeElement={(id) =>
                      updateFilter(
                        'kontrakter',
                        id,
                        statiskeData.kontrakter,
                        'kontrakt'
                      )
                    }
                    onResetAll={() =>
                      resetFilter('kontrakter', statiskeData.kontrakter)
                    }
                  />
                )}
              {avvikStatuskoder && (
                <FilterDropdown
                  tittel={
                    <Translate id="kontrollpanel.filterdropdown.status.tittel" />
                  }
                  resetLabel={
                    <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                  }
                  elementer={generateFilterElementer(
                    'statuskoder',
                    avvikStatuskoder,
                    'kontrollpanel.avvik-status'
                  )}
                  onChangeElement={(id) =>
                    updateFilter(
                      'statuskoder',
                      id,
                      avvikStatuskoder,
                      'kontrollpanel.avvik-status'
                    )
                  }
                  onResetAll={() =>
                    resetFilter('statuskoder', avvikStatuskoder)
                  }
                />
              )}

              <FilterDropdown
                tittel={
                  <Translate id="kontrollpanel.filterdropdown.alvorlig.tittel" />
                }
                resetLabel={
                  <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                }
                elementer={generateFilterElementer(
                  'alvorlig',
                  alvorlighetsgrader
                )}
                onChangeElement={(id) =>
                  updateFilter('alvorlig', id, alvorlighetsgrader)
                }
                onResetAll={() => resetFilter('alvorlig', alvorlighetsgrader)}
              />

              <OmradeFilterDropdown
                tittel={
                  <Translate id="kontrollpanel.filterdropdown.omrader.tittel" />
                }
                resetLabel={
                  <Translate id="kontrollpanel.filterdropdown.resetLabel" />
                }
                elementer={omradelist}
                onChangeElement={(id) =>
                  updateFilter('omrader', id, omradelist)
                }
                addMultipleFilters={(idListe) =>
                  addMultipleFilters('omrader', idListe, omradelist)
                }
                removeMultipleFilters={(idListe) =>
                  removeMultipleFilters('omrader', idListe, omradelist)
                }
                onResetAll={() => resetFilter('omrader', omradelist)}
                isElementAddedToFilter={(id) =>
                  isElementAddedToFilter('omrader', id)
                }
              />

              {filterHasValues() && (
                <Button
                  color="link"
                  className="dropdown-reset-all"
                  onClick={resetAlleFilter}
                >
                  <Translate id="kontrollpanel.filterdropdown.reset-alle-filtre-button" />
                </Button>
              )}
            </Col>
          </Row>
          <Row className="kontrollpanel-filter-aktive-filter">
            <Col>
              {Object.keys(selectedFilters).map((key) => {
                return selectedFilters[key]
                  ? selectedFilters[key].map((item) => (
                      <FilterElement
                        id={item.id}
                        key={Math.random()}
                        label={item.navn}
                        onCloseClick={(id) => fjernFilter(key, id)}
                      />
                    ))
                  : '';
              })}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

Filter.propTypes = {
  statiskeData: shape({
    entreprenorselskaper: arrayOf(shape({})),
    kontrakter: arrayOf(shape({})),
  }),
  statuskoder: shape({
    Avvik: arrayOf(shape({})),
  }),
  filter: shape({}),
  onFilterChanged: func.isRequired,
};

Filter.defaultProps = {
  statiskeData: {
    entreprenorselskaper: [],
    kontrakter: [],
  },
  statuskoder: {},
  filter: {
    seksjoner: [],
    kategorier: [],
    kontrolltyper: [],
    statuskoder: [],
    alvorlig: [],
    omrader: [],
    avviktyper: [],
    kontrakter: [],
    entreprenorselskaper: [],
    feiltyper: [],
  },
};

export default Filter;
