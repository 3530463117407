import { find, isEmpty } from 'lodash';
import httpClient from '../api/httpClient';
import { toastError, toastSuccess, toastWarning } from './toast';
import { buildOptions as buildQueryOptions } from '../utils/kartdataFilterUtils';
import {
  getBydelKartlagsnavn,
  getDriftsomradeKartlagsnavn,
} from './filterOmradeOptions';

export const VIEWPORTCHANGED = 'kartdata/VIEWPORTCHANGED';
export const TRACKDATASUCCESS = 'kartdata/SUCCESS';
const TRACKDATAERROR = 'kartdata/ERROR';
const TRACKDATAREQUEST = 'kartdata/REQUEST';

const AVVIKLISTEREQUEST = 'kartdata/avvikliste/REQUEST';
const AVVIKLISTEERROR = 'kartdata/avvikliste/ERROR';
const AVVIKLISTESUCCESS = 'kartdata/avvikliste/SUCCESS';

const PUBLIKUMMELDINGERREQUEST = 'kartdata/publikummeldinger/REQUEST';
const PUBLIKUMMELDINGERERROR = 'kartdata/publikummeldinger/ERROR';
const PUBLIKUMMELDINGERSUCCESS = 'kartdata/publikummeldinger/SUCCESS';

export default function reducer(
  state = {
    avvik: [],
    publikummeldinger: [],
    geoJSON: [],
    markers: {},
    loading: false,
    nodata: true,
    viewport: {
      center: [],
      zoom: 0,
      bounds: {},
    },
  },
  action = {}
) {
  switch (action.type) {
    case TRACKDATASUCCESS:
      return {
        ...state,
        geoJSON: action.payload,
        loading: false,
      };
    case TRACKDATAERROR:
      return {
        ...state,
        geoJSON: [],
        loading: false,
      };
    case TRACKDATAREQUEST:
      return {
        ...state,
        geoJSON: [],
        loading: true,
      };
    case VIEWPORTCHANGED:
      return {
        ...state,
        viewport: action.payload,
      };

    case AVVIKLISTEREQUEST:
      return { ...state, avvik: [] };

    case AVVIKLISTESUCCESS:
      return {
        ...state,
        avvik: action.payload,
      };

    case AVVIKLISTEERROR:
      return {
        ...state,
        avvik: [],
      };

    case PUBLIKUMMELDINGERREQUEST:
      return { ...state, publikummeldinger: [] };

    case PUBLIKUMMELDINGERSUCCESS:
      return {
        ...state,
        publikummeldinger: action.payload,
      };

    case PUBLIKUMMELDINGERERROR:
      return {
        ...state,
        publikummeldinger: [],
      };

    default:
      return state;
  }
}

const getsporingsDataSuccess = (res) => ({
  type: TRACKDATASUCCESS,
  payload: res,
});

const getsporingsDataSuccessNoGeoData = (res) => ({
  type: TRACKDATASUCCESS,
  payload: res,
});

const getsporingsDataError = (error) => ({
  type: TRACKDATAERROR,
  payload: error,
});

export const onMapViewportChanged = ({ ...viewport }) => {
  return async (dispatch) => {
    dispatch({ type: VIEWPORTCHANGED, payload: viewport });
  };
};

export function getSporingsdata(filter) {
  return async (dispatch) => {
    dispatch({
      type: TRACKDATAREQUEST,
    });
    try {
      const queryOptions = buildQueryOptions(filter);
      // Benytter inntil videre ikke kontraktnummere inn i spørringen mot backend
      const data = await httpClient.getKartData({
        ...queryOptions,
        kontraktnummere: [],
      });
      if (find(data, (a) => !isEmpty(a.features))) {
        dispatch(getsporingsDataSuccess(data));
        dispatch(toastSuccess({ id: 'toast.successGetKartData' }));
      } else {
        dispatch(getsporingsDataSuccessNoGeoData(data));
        dispatch(toastWarning({ id: 'toast.warningNoKartData' }));
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400 &&
        error.response.data.errorMessage === 'RecordCountLimitException'
      ) {
        dispatch(getsporingsDataError(error));
        dispatch(toastWarning({ id: 'toast.warningRecordLimit' }));
      } else {
        dispatch(getsporingsDataError(error));
        dispatch(toastError(error));
      }
    }
  };
}

const getAvvikSuccess = (res) => ({
  type: AVVIKLISTESUCCESS,
  payload: res,
});

const getAvvikError = (error) => ({
  type: AVVIKLISTEERROR,
  payload: error,
});

export function getAvvik(filter) {
  return async (dispatch) => {
    dispatch({
      type: AVVIKLISTEREQUEST,
    });
    try {
      const data = await httpClient.getAvvikListeforPeriode(
        filter.datoFra,
        filter.datoTil,
        getDriftsomradeKartlagsnavn(filter.driftsomrade),
        getBydelKartlagsnavn(filter.bydel)
      );

      dispatch(getAvvikSuccess(data.result));
    } catch (error) {
      dispatch(getAvvikError(error));
      dispatch(toastError(error));
    }
  };
}

const getPublikummeldingerSuccess = (res) => ({
  type: PUBLIKUMMELDINGERSUCCESS,
  payload: res,
});

const getPublikummeldingerError = (error) => ({
  type: PUBLIKUMMELDINGERERROR,
  payload: error,
});

export function getPublikummeldinger(filter) {
  return async (dispatch) => {
    dispatch({
      type: PUBLIKUMMELDINGERREQUEST,
    });
    try {
      const data = await httpClient.getPublikummeldingerforPeriode(
        filter.datoFra,
        filter.datoTil,
        getDriftsomradeKartlagsnavn(filter.driftsomrade),
        getBydelKartlagsnavn(filter.bydel)
      );

      dispatch(getPublikummeldingerSuccess(data.result));
    } catch (error) {
      dispatch(getPublikummeldingerError(error));
      dispatch(toastError(error));
    }
  };
}

export function getKartdata(filter) {
  if (!filter.isValid) return async () => Promise.resolve();

  return async (dispatch) =>
    Promise.all([
      dispatch(getSporingsdata(filter)),
      dispatch(getAvvik(filter)),
      dispatch(getPublikummeldinger(filter)),
    ]);
}
