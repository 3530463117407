import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

function FilterCheckbox(props) {
  const { title, name, value, onChange, checked } = props;

  const onClick = () => {
    onChange(value);
  };

  const cursorPointerStyle = { cursor: 'pointer' };

  return (
    <Label>
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onClick}
        style={cursorPointerStyle}
      />{' '}
      <Label style={cursorPointerStyle} onClick={onClick}>
        {title}
      </Label>
    </Label>
  );
}

FilterCheckbox.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

FilterCheckbox.defaultProps = {
  name: '',
  checked: false,
};

export default FilterCheckbox;
