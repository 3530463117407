// avvik
// eslint-disable-next-line import/prefer-default-export
export const avvikStatuskoder = [
  // nb: 'key' er bare for å enklere referere til hver kode
  { id: '00000000-0000-0000-0000-000000000000', key: 'IkkeDefinert' },
  { id: 'b2b1de1b-4fa5-44a3-a593-4d2294efa3bf', key: 'Kladd' },
  { id: '52584cb4-b9b4-4978-be38-d87ccf149efc', key: 'IkkeBehandlet' },
  { id: 'f2c91a82-0bdc-4072-84f7-5a2d15f97ab3', key: 'FerdigBehandlet' },
  { id: 'a5205cf3-cdce-452b-91cb-6637fe737305', key: 'AvvikMottatt' }, // Entreprenørs avvik er mottatt av BYM
  { id: 'f4d8052a-0e27-4277-b90c-64d6dcdc498b', key: 'Avvist' },
  { id: '134ef90e-aee7-4b22-9fd2-9c098c532340', key: 'Slettet' },
];
