import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { FaTimes } from 'react-icons/fa';

import Translate from '../../../Locale/Translate';
import ConfirmModal from '../../Common/ConfirmModal';
import { avvikStatuskoder } from '../../../constants/status';

const InnmeldingFooter = ({
  nextLink,
  prevLink,
  onSave,
  saveButton,
  saving,
  history,
  validState,
}) => {
  const onSaveDraftClick = () => {
    onSave(avvikStatuskoder.find((status) => status.key === 'Kladd').id);
  };

  const onSaveClick = () => {
    onSave();
  };

  const [bekreftAvbrytSynlig, setBekreftAvbrytSynlig] = useState(false);

  return (
    <div className="innmelding-form-mobil-footer">
      <div className="innmelding-form-mobil-footer-spinner">
        <PulseLoader loading={saving} size="10" sizeUnit="px" color="#000" />
      </div>
      {validState && !validState.valid && (
        <div className="valid-form-feedback">
          <p />
        </div>
      )}
      <div className="innmelding-form-mobil-footer-buttons">
        <Button onClick={onSaveDraftClick} color="secondary" disabled={saving}>
          <Translate id="innmelding.submitKladdButton" />
        </Button>
        {prevLink && (
          <Button
            onClick={() => history.push(prevLink)}
            color="primary"
            disabled={saving}
          >
            <Translate
              id="innmelding.mobil_fortsett_innmelding"
              defaultMessage="tilbake"
            />
          </Button>
        )}
        {nextLink && (
          <Button
            onClick={() => history.push(nextLink)}
            color="primary"
            disabled={saving}
          >
            <Translate id="innmelding.fortsettInnmeldingButton" />
          </Button>
        )}
        {saveButton && (
          <Button onClick={onSaveClick} color="primary" disabled={saving}>
            <Translate id="innmelding.submitButton" />
          </Button>
        )}

        <Button
          onClick={() => setBekreftAvbrytSynlig(true)}
          color="link"
          disabled={saving}
        >
          <FaTimes />
          <Translate id="innmelding.avbrytButton" />
        </Button>

        <ConfirmModal
          tittel={<Translate id="innmelding-avbryt-bekreft.tittel" />}
          tekst={<Translate id="innmelding-avbryt-bekreft.text" />}
          bekreftKnappetekst={
            <Translate id="innmelding-avbryt-bekreft.confirmButton" />
          }
          avbrytKnappetekst={
            <Translate id="innmelding-avbryt-bekreft.cancelButton" />
          }
          show={bekreftAvbrytSynlig}
          onConfirm={() => history.push('/kontrollpanel')}
          onCancel={() => setBekreftAvbrytSynlig(false)}
        />
      </div>
    </div>
  );
};

InnmeldingFooter.propTypes = {
  nextLink: PropTypes.string,
  prevLink: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  saveButton: PropTypes.bool,
  saving: PropTypes.bool.isRequired,
  validState: PropTypes.shape({}),
};

InnmeldingFooter.defaultProps = {
  nextLink: null,
  prevLink: null,
  validState: null,
  saveButton: false,
};

export default withRouter(InnmeldingFooter);
