import React, { useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import { FaCaretDown } from 'react-icons/fa';
import { Button } from 'reactstrap';

const KartlagInfo = ({ ...props }) => {
  const { kartlag, id } = props;
  const [open, toggleOpen] = useState(false);

  const modifyKartSymbolNames = (label) => {
    switch (label) {
      case 'E':
        return 'Europavei';
      case 'K':
        return 'Kommunal vei';
      case 'P':
        return 'Privat vei';
      case 'R':
        return 'Riksvei';
      case 'S':
        return 'Skogsvei';
      case 'Armatur infrastruktur - VTP':
        return 'VPT';
      case 'Tilstand ukjent':
        return 'Gatelysskap - Tilstand ukjent';
      case 'Byttes snarest':
        return 'Gatelysskap - Byttes snarest';
      case 'Gammel OK':
        return 'Gatelysskap - Gammel OK';
      case 'OK':
        return 'Gatelysskap - OK';
      case 'Armatur infrastruktur - Kabel':
        return 'Kabel';
      case 'Armatur infrastruktur - Gatelyslinjer':
        return 'Gatelyslinjer';
      case 'Armatur infrastruktur - Wirestrekk':
        return 'Wirestrekk';
      default:
        return label;
    }
  };

  const lag = open ? find(kartlag, (k) => k.layerName === id) : null;
  return (
    <>
      <Button
        onClick={() => toggleOpen(!open)}
        className="kartlag-info-button"
        color="link"
        key={`kartlagInfo-button-${id}`}
      >
        <FaCaretDown
          className={`kartlag-body-caret ${open ? ' open' : ' closed'}`}
        />
      </Button>

      {open && (
        <ul
          className="kartlag-beskrivelse-liste"
          key={`kartlagInfo-${id}-liste`}
        >
          {map(lag ? lag.legend : [], (i) => {
            const baseString64 = `data:${i.contentType};base64,${i.imageData}`;
            return (
              <li key={`kartlagInfo-${id}-${i.label}`}>
                <img
                  alt={i.label}
                  src={baseString64}
                  height={i.height}
                  width={i.width}
                />
                {modifyKartSymbolNames(i.label)}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

KartlagInfo.propTypes = {
  kartlag: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  id: PropTypes.string.isRequired,
};

export default KartlagInfo;
