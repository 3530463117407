import Cookies from 'js-cookie';

const featureIsEnabled = (featureKey) => {
  const value = Cookies.get(featureKey);
  return value === 'true';
};

export const isAnyFeatureEnabled = (features) => {
  return features.filter((f) => featureIsEnabled(f)).length > 0;
};

// Features
export const FEATURE_DASHBOARD_STATISTIKK_ENDRINGSTYPER =
  'FEATURE_DASHBOARD_STATISTIKK_ENDRINGSTYPER';
export const FEATURE_DASHBOARD_STATISTIKK_AVVISTE_MELDINGSTYPER =
  'FEATURE_DASHBOARD_STATISTIKK_AVVISTE_MELDINGSTYPER';
export const FEATURE_NOTIFICATION_VARSEL = 'FEATURE_NOTIFICATION_VARSEL';

export default featureIsEnabled;
