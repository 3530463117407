import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { UserContext } from '../../User/UserProvider.tsx';
import Translate from '../../Locale/Translate';

function Gruppevelger(props) {
  const { gruppe, onGruppeChange } = props;
  const user = useContext(UserContext);

  const grupper = [
    {
      value: 'avvik',
      label: <Translate id="statistikk.header.gruppe.avvik" />,
    },
    {
      value: 'drift',
      label: <Translate id="statistikk.header.gruppe.drift" />,
    },
  ];

  if (user.isInternBymbruker()) {
    grupper.push({
      value: 'kontroller',
      label: <Translate id="statistikk.header.gruppe.kontroller" />,
    });
  }

  const nyGruppe = (valgtGruppe) => {
    onGruppeChange(valgtGruppe.value);
  };

  return (
    <div className="statistikk-gruppe">
      <Select
        className={!gruppe ? '' : 'isSelected'}
        placeholder={<Translate id="statistikk.header.gruppe.default" />}
        options={grupper}
        value={gruppe && grupper.find((g) => g.value === gruppe)}
        onChange={nyGruppe}
      />
    </div>
  );
}

Gruppevelger.propTypes = {
  gruppe: PropTypes.string,
  onGruppeChange: PropTypes.func.isRequired,
};

Gruppevelger.defaultProps = {
  gruppe: null,
};

export default Gruppevelger;
