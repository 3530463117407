import httpClient from '../api/httpClient';
import { toastError } from './toast';

const TRACKDATADETAILSREQUEST = 'spordetaljer/TRACKDATADETAILSREQUEST';
const TRACKDATADETAILSSUCCESS = 'spordetaljer/TRACKDATADETAILSSUCCESS';
const TRACKDATADETAILSERROR = 'spordetaljer/TRACKDATADETAILSERROR';

export default function reducer(
  state = {
    detaljer: {},
    loading: false,
  },
  action = {}
) {
  switch (action.type) {
    case TRACKDATADETAILSSUCCESS:
      return {
        ...state,
        detaljer: action.payload,
        loading: false,
      };
    case TRACKDATADETAILSERROR:
      return {
        ...state,
        detaljer: {},
        loading: false,
      };
    case TRACKDATADETAILSREQUEST:
      return {
        ...state,
        detaljer: {},
        loading: true,
      };

    default:
      return state;
  }
}

const getSpordetaljerSuccess = (res) => ({
  type: TRACKDATADETAILSSUCCESS,
  payload: res,
});

const getSpordetaljerError = (error) => ({
  type: TRACKDATADETAILSERROR,
  payload: error,
});

export function getSpordetaljer(punktinfo) {
  return async (dispatch) => {
    dispatch({
      type: TRACKDATADETAILSREQUEST,
    });
    try {
      const data = await httpClient.getSpordetaljer(
        punktinfo.kilde,
        punktinfo.sporId,
        punktinfo.latitude,
        punktinfo.longitude
      );
      dispatch(getSpordetaljerSuccess(data));
    } catch (error) {
      dispatch(getSpordetaljerError(error));
      dispatch(toastError(error));
    }
  };
}
