import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import {
  Row,
  Col,
  Button,
  Label,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { saveSvgAsPng } from 'save-svg-as-png';
import { STANDARD_DATE_FORMAT } from '../../utils/dateUtils';
import grafIcon from '../../images/statistikk-graf-ikon.svg';
import tabellIcon from '../../images/table.svg';
import bildeIcon from '../../images/bildefil_hvit.svg';
import lastNedIcon from '../../images/last-ned.svg';
import lastNedHvitIcon from '../../images/last-ned_hvit.svg';
import Translate from '../../Locale/Translate';
import './DiagramContainer.scss';
import KalenderPeriodeVelger from '../KalenderPeriodeVelger/KalenderPeriodeVelger';

const PERIODEVALGFORMAT_DATO_FRA_TIL = 'DatoFraTil';
const PERIODEVALGFORMAT_24_TIMER = '24t';

const DiagramContainer = React.forwardRef((props, ref) => {
  const {
    loading,
    tittel,
    undertittel,
    periodevalgformat,
    datoFra,
    onDatoFra,
    datoTil,
    onDatoTil,
    onRefreshData,
    onCsvExport,
    grafdiagram,
    tabelldiagram,
  } = props;

  const [visibleDiagram, setVisibleDiagram] = useState('graf');

  const toggleVisibleDiagram = (type) => {
    setVisibleDiagram(type);
  };

  const exportDiagram = (format) => {
    const valgtElement = ref.current.children[0].children[0].children[0];
    if (!valgtElement) return;

    const filnavn = `${tittel} ${moment(datoFra).format(
      STANDARD_DATE_FORMAT
    )}-${moment(datoTil).format(STANDARD_DATE_FORMAT)}.${format}`;

    if (format === 'svg') {
      const svg = new XMLSerializer().serializeToString(valgtElement);
      const svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
      saveAs(svgBlob, filnavn);
    } else if (format === 'png') {
      saveSvgAsPng(valgtElement, filnavn);
    } else if (format === 'csv') {
      onCsvExport(filnavn);
    }
  };

  const setFraTilDato = (fra, til) => {
    onDatoFra(fra);
    onDatoTil(til);
    onRefreshData(fra, til);
  };

  const setFraDatoMedTid = (fra) => {
    const autojustertTilDato = moment(fra).add(1, 'day');
    onDatoFra(fra);
    onDatoTil(autojustertTilDato);
    onRefreshData(fra, autojustertTilDato);
  };

  return (
    <>
      <Row>
        <Col>
          <div className="diagram-container-header-tittel">
            <h4>{tittel}</h4>
          </div>
          <div className="diagram-container-header-meny">
            <UncontrolledDropdown direction="left">
              <DropdownToggle>
                <img src={lastNedIcon} alt="meny" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => exportDiagram('png')}>
                  <img src={bildeIcon} alt="meny" />{' '}
                  <Translate id="diagram-container.download-as-png" />
                </DropdownItem>
                <DropdownItem onClick={() => exportDiagram('svg')}>
                  <img src={bildeIcon} alt="meny" />{' '}
                  <Translate id="diagram-container.download-as-svg" />
                </DropdownItem>
                <DropdownItem onClick={() => exportDiagram('csv')}>
                  <img src={lastNedHvitIcon} alt="meny" />{' '}
                  <Translate id="diagram-container.download-as-csv" />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3} className="diagram-container-diagramtype-knapper">
          <ButtonGroup>
            {grafdiagram && (
              <Button
                value="Graf view"
                onClick={() => toggleVisibleDiagram('graf')}
                active={visibleDiagram === 'graf'}
              >
                <img src={grafIcon} alt="graf" />
              </Button>
            )}
            {tabelldiagram && (
              <Button
                value="Table view"
                onClick={() => toggleVisibleDiagram('tabell')}
                active={visibleDiagram === 'tabell'}
              >
                <img src={tabellIcon} alt="tabell" />
              </Button>
            )}
          </ButtonGroup>{' '}
        </Col>
        <Col xs={9} className="diagram-container-datovelgere">
          {periodevalgformat === PERIODEVALGFORMAT_DATO_FRA_TIL && (
            <KalenderPeriodeVelger
              calendarId={tittel.replace(/ /g, '')}
              fra={datoFra}
              til={datoTil}
              onChange={(fra, til) => setFraTilDato(fra, til)}
              displayDayMonthYearButtons
            />
          )}

          {periodevalgformat === PERIODEVALGFORMAT_24_TIMER && (
            <KalenderPeriodeVelger
              calendarId={tittel.replace(/ /g, '')}
              fra={datoFra}
              velgPeriode={false}
              showTimepicker
              onChange={(fra) => setFraDatoMedTid(fra)}
              displayDayMonthYearButtons={false}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>{undertittel}</Label>
        </Col>
      </Row>
      <Row className="h-100">
        <Col>
          <div className="map-spinner">
            <PulseLoader
              sizeUnit="px"
              size={10}
              color="#123abc"
              loading={loading}
            />
          </div>
          <div className="diagram-container-diagram" ref={ref}>
            {!loading && visibleDiagram === 'graf' && <>{grafdiagram}</>}
            {!loading && visibleDiagram === 'tabell' && <>{tabelldiagram}</>}
          </div>
        </Col>
      </Row>
    </>
  );
});

DiagramContainer.propTypes = {
  loading: PropTypes.bool,
  tittel: PropTypes.string.isRequired,
  undertittel: PropTypes.string,
  periodevalgformat: PropTypes.oneOf([
    PERIODEVALGFORMAT_DATO_FRA_TIL,
    PERIODEVALGFORMAT_24_TIMER,
  ]),
  datoFra: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  onDatoFra: PropTypes.func.isRequired,
  datoTil: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onDatoTil: PropTypes.func.isRequired,
  onRefreshData: PropTypes.func.isRequired,
  onCsvExport: PropTypes.func.isRequired,
  grafdiagram: PropTypes.node,
  tabelldiagram: PropTypes.node,
};
DiagramContainer.defaultProps = {
  loading: false,
  undertittel: '',
  periodevalgformat: PERIODEVALGFORMAT_DATO_FRA_TIL,
  datoTil: undefined,
  grafdiagram: undefined,
  tabelldiagram: undefined,
};

export default DiagramContainer;
