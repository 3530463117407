import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { UserContext } from '../../User/UserProvider.tsx';

const PrivateRoute = ({ component, loggedIn, location, ...rest }) => {
  const user = useContext(UserContext);
  if (
    user.isLoggedIn() &&
    (user.isEntreprenor() ||
      user.isKontrollor() ||
      user.isForvalter() ||
      user.isEnkAdmin())
  ) {
    return <Route {...rest} component={component} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};
function mapStateToProps(state) {
  return {
    loggedIn: state.login != null,
  };
}

PrivateRoute.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])
    .isRequired,
  location: PropTypes.shape({}),
};

PrivateRoute.defaultProps = {
  location: {},
};

export default connect(mapStateToProps)(PrivateRoute);
