import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from 'reactstrap';
import Datetime from 'react-datetime';
import { FaClock, FaCalendar } from 'react-icons/fa';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import Translate from '../../Locale/Translate';

import {
  STANDARD_DATE_FORMAT,
  STANDARD_TIME_FORMAT,
  STANDARD_DATETIME_FORMAT,
  getNorwegianLocalTime,
} from '../../utils/dateUtils';

import 'react-datetime/css/react-datetime.css';

const DatetimeInput = (props) => {
  const {
    handleChange,
    value,
    datoLabel,
    tidLabel,
    title,
    isValidDate,
    validState,
    onIsValidChange,
  } = props;

  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [datoError, setDatoError] = useState(false);
  const [tidError, setTidError] = useState(false);

  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');
  const autoCorrectedTimePipe = createAutoCorrectedDatePipe('HH:MM');

  useEffect(() => {
    if (moment(value).isValid()) {
      setDate(moment(value).format(STANDARD_DATE_FORMAT));
      setTime(moment(value).format(STANDARD_TIME_FORMAT));
    }
  }, [value]);

  const changeTidError = (isValid) => {
    setTidError(isValid);
    if (onIsValidChange)
      onIsValidChange(datoError === false && isValid === false);
  };

  const changeDatoError = (isValid) => {
    setDatoError(isValid);
    if (onIsValidChange)
      onIsValidChange(isValid === false && tidError === false);
  };

  const handleTimeChange = (e) => {
    setTime(e);
    if (typeof e === 'object' && e.isValid()) {
      changeTidError(false);
      const v = moment(
        `${date}_${e.format(STANDARD_TIME_FORMAT)}`,
        STANDARD_DATETIME_FORMAT
      );
      if (v.isValid()) handleChange(v.format());
    } else changeTidError(true);
  };

  const handleDateChange = (e) => {
    setDate(e);
    if (typeof e === 'object' && e.isValid()) {
      changeDatoError(false);
      const v = moment(
        `${e.format(STANDARD_DATE_FORMAT)}_${time}`,
        STANDARD_DATETIME_FORMAT
      );
      if (v.isValid()) handleChange(v.format());
    } else changeDatoError(true);
  };

  const onTimeInputKeyPress = (e) => {
    const isValidKey = () => {
      return (
        e.key.match(/[0-9,:]/g) &&
        !(
          e.key === ':' &&
          (e.target.value.length !== 2 ||
            e.target.value[e.target.value.length - 1] === ':')
        )
      );
    };
    const resultsInValidLength = () => {
      const isInvalidLength =
        e.target.value.length >= STANDARD_TIME_FORMAT.length &&
        typeof e.target.selectionStart === 'number' &&
        e.target.selectionStart >= STANDARD_TIME_FORMAT.length;

      return !isInvalidLength;
    };
    const autoInsertSeparator = () => {
      if (
        e.target.value.length === 2 &&
        e.target.value[e.target.value.length - 1] !== ':' &&
        e.key !== ':'
      ) {
        e.target.value += ':';
      }
    };

    if (!isValidKey() || !resultsInValidLength()) {
      e.preventDefault();
    }
    autoInsertSeparator();
  };

  return (
    <>
      {title && (
        <h3>
          <span>{title}</span>
        </h3>
      )}
      <div className="datetime-input-container">
        <FormGroup>
          <span className="innmelding-form-support-text">{datoLabel}</span>
          <Datetime
            locale="nb"
            inputProps={{ id: 'gyldigFraDag' }}
            dateFormat={STANDARD_DATE_FORMAT}
            timeFormat={false}
            value={date}
            onChange={(e) => handleDateChange(e)}
            className={`datetime-input datetime-input-date ${
              (datoError || validState) && ' invalid'
            }`}
            closeOnSelect
            isValidDate={isValidDate}
            renderInput={(p) => (
              <InputGroup>
                <Label htmlFor="gyldigFraDag">
                  {/* <Translate id="antallKontroller.date" defaultMessage="Dato" /> */}
                </Label>
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  pipe={autoCorrectedDatePipe}
                  keepCharPositions
                  {...p}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FaCalendar />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            )}
          />
          {datoError && (
            <div className="invalid-datetime-feedback">
              <Translate
                id="dateTime.dateInput.validation"
                defaultMessage="Dato må være i formatet {format}"
                values={{ format: STANDARD_DATE_FORMAT }}
              />
            </div>
          )}
          {validState && (
            <div className="invalid-datetime-feedback">{validState}</div>
          )}
        </FormGroup>
        <FormGroup>
          <span className="innmelding-form-support-text">{tidLabel}</span>
          <Datetime
            locale="nb"
            inputProps={{ id: 'gyldigFraTid' }}
            dateFormat={false}
            timeFormat={STANDARD_TIME_FORMAT}
            value={time}
            onChange={(e) => handleTimeChange(e)}
            className={`datetime-input datetime-input-time ${
              (tidError || validState) && ' invalid'
            }`}
            closeOnSelect
            isValidDate={isValidDate}
            renderInput={(p) => (
              <InputGroup className="antall-kontroller-kalender">
                <Label htmlFor="antall-kontoller-dato">
                  {/* <Translate id="antallKontroller.date" defaultMessage="Dato" /> */}
                </Label>
                <MaskedInput
                  mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                  pipe={autoCorrectedTimePipe}
                  {...p}
                  onKeyPress={(e) => onTimeInputKeyPress(e)}
                />

                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FaClock />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            )}
          />
          {tidError && (
            <div className="invalid-datetime-feedback">
              <Translate
                id="dateTime.timeInput.validation"
                defaultMessage="Tid må være i formatet {format}"
                values={{ format: STANDARD_TIME_FORMAT }}
              />
            </div>
          )}
          {validState && (
            <div className="invalid-datetime-feedback">{validState}</div>
          )}
        </FormGroup>
      </div>
    </>
  );
};

DatetimeInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  datoLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  tidLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  isValidDate: PropTypes.func,
  onIsValidChange: PropTypes.func,
};

DatetimeInput.defaultProps = {
  value: getNorwegianLocalTime(),
  datoLabel: 'Velg dato',
  tidLabel: 'Velg tid',
  title: null,
  isValidDate: () => true,
  onIsValidChange: () => {},
};

export default DatetimeInput;
