export function sortByString(str1, str2) {
  const nameA = str1 === null ? '' : str1.toUpperCase(); // ignore upper and lowercase
  const nameB = str2 === null ? '' : str2.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export function sortByStringDesc(str1, str2) {
  return sortByString(str2, str1);
}
