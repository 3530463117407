export const wmslayers = [
  {
    type: 'header',
    label: 'Vei',
    labelId: 'vei ',
    id: 'vei',
  },
  {
    type: 'node',
    label: 'Bratt bakke',
    labelId: 23,
    layerId: 'Bratt_bakke11874',
    id: 'Bratt bakke',
  },
  {
    type: 'node',
    label: 'Kommunale hovedveier',
    labelId: 26,
    layerId: 'Kommunale_hovedveier64903',
    id: 'Kommunale hovedveier',
  },
  {
    type: 'node',
    label: 'Kommunale sykkelveier',
    labelId: 13,
    layerId: 'Kommunale_sykkelveier26380',
    id: 'Kommunale sykkelveier',
  },
  {
    type: 'node',
    id: 'Prioritert veinett - ekstra løvfeiing',
    layerId: 'Prioritert_veinett_-_ekstra_løvfeiing33747',
    label: 'Prioritert veinett - ekstra løvfeiing',
    labelId: 31,
  },
  {
    type: 'node',
    id: 'Prioritert veinett - sommerdrift',
    layerId: 'Prioritert_veinett_-_sommerdrift32807',
    label: 'Prioritert veinett - sommerdrift',
    labelId: 32,
  },
  {
    type: 'node',
    label: 'Turveier',
    labelId: 2,
    id: 'Turveier',
  },
  {
    type: 'node',
    label: 'Velvei',
    labelId: 0,
    id: 'Velvei',
  },
  {
    type: 'node',
    label: 'Veier',
    layerId: 'Veier',
    labelId: 27,
    id: 'Veier',
  },
  {
    type: 'node',
    id: 'Vinterdrift sykkel - Park',
    layerId: 'Vinterdrift_sykkel_-_Park36652',
    label: 'Vinterdrift sykkel - Park',
    labelId: 28,
  },
  {
    type: 'node',
    id: 'Vinterdrift sykkel - Prioritert veinett',
    layerId: 'Vinterdrift_sykkel_-_Prioritert_veinett37509',
    label: 'Vinterdrift-sykkel - Prioritert veinett',
    labelId: 29,
  },
  {
    type: 'node',
    id: 'Vinterdrift sykkel - Statens vegvesen',
    layerId: 'Vinterdrift_sykkel_-_Statens_vegvesen58065',
    label: 'Vinterdrift sykkel - Statens vegvesen',
    labelId: 30,
  },
  {
    type: 'header',
    label: 'Grenser',
    labelId: 'grenser',
    id: 'grenser',
  },
  {
    type: 'node',
    label: 'Eiendomsoversikt',
    labelId: 19,
    id: 'Eiendomsoversikt',
    opacity: 0.5,
  },
  {
    type: 'node',
    label: 'Indre by - ring 1',
    labelId: 15,
    id: 'IndreByRing1',
  },
  {
    type: 'node',
    label: 'Driftsområder',
    labelId: 20,
    layerId: 'Driftsområder_vei61671',
    id: 'Driftsområder vei',
    opacity: 0.5,
  },
  {
    type: 'node',
    label: 'Bydelsgrenser',
    labelId: 21,
    id: 'Bydelsgrenser',
  },
  {
    type: 'header',
    label: 'Annet',
    labelId: 'annet',
    id: 'annet',
  },
  {
    type: 'node',
    label: 'Treforvalterregioner',
    labelId: 3,
    id: 'Treforvalterregioner',
  },
  {
    type: 'node',
    label: 'Trekontraktregioner',
    labelId: 14,
    id: 'Trekontraktregioner',
  },
  {
    type: 'node',
    label: 'Tredata',
    labelId: 5,
    id: 'Tredata',
  },
  {
    type: 'node',
    label: 'Treanlegg_BYM',
    labelId: 6,
    id: 'Treanlegg_BYM',
    opacity: 0.5,
  },
  {
    type: 'node',
    label: 'Trapper',
    labelId: 7,
    id: 'Trapper',
  },
  {
    type: 'node',
    label: 'Slukredigering',
    labelId: 8,
    layerId: 'Slukredigering_BYM37120',
    id: 'Slukredigering BYM',
  },
  {
    type: 'node',
    label: 'Signalanlegg',
    labelId: 9,
    id: 'Signalanlegg',
    opacity: 0.5,
  },
  {
    type: 'node',
    label: 'Parkregioner',
    labelId: 10,
    id: 'Parkregioner',
  },
  {
    type: 'node',
    label: 'Parkomriss',
    labelId: 11,
    id: 'Parkanlegg_Omriss',
  },
  {
    type: 'node',
    label: 'Parkeringsareal utenfor gategrunn',
    labelId: 12,
    layerId: 'Parkeringsareal_utenfor_gategrunn55228',
    id: 'Parkeringsareal utenfor gategrunn',
  },
  {
    type: 'node',
    label: 'Idrettsregioner',
    labelId: 16,
    id: 'Idrettsregioner',
  },
  {
    type: 'node',
    label: 'Idrettsomriss',
    labelId: 17,
    id: 'Idrettsomriss',
  },
  {
    type: 'node',
    label: 'Fremmede arter',
    labelId: 18,
    layerId: 'Fremmede_arter624',
    id: 'Fremmede arter',
  },
  {
    type: 'node',
    label: 'Broer',
    labelId: 22,
    id: 'Broer',
  },
  {
    type: 'node',
    id: 'Armatur',
    label: 'Armatur',
    labelId: 4,
  },
  {
    type: 'node',
    id: 'HC-parkering',
    label: 'HC-parkering',
    labelId: 25,
  },
];

export const openStreetMapLayer =
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const bymOsloMapBaseLayer =
  'https://geodata.bymoslo.no/arcgis/rest/services/geodata/Oslomap_epsg3857/MapServer/WMTS?';

export const WMSlayerServiceUrl =
  'https://geodata.bymoslo.no/arcgis/services/geodata/bymelding/MapServer/WmsServer?';
export const layerInfoUrl =
  'https://geodata.bymoslo.no/arcgis/rest/services/geodata/bymelding/MapServer/legend?f=pjson';

export const osmBaseMap = {
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
};

export const sateliteBaseMap = {
  url: 'https://opencache.statkart.no/gatekeeper/gk/gk.open_nib_web_mercator_wmts_v2?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topo4&STYLE=default&FORMAT=image/jpgpng&TILEMATRIXSET=GoogleMapsCompatible&tilematrix={getMatrix}&TileRow={y}&TileCol={x}',
  getMatrix: (data) => `${`0${data.z}`.slice(-2)}`,
  attribution:
    '&copy; <a href="https://www.norgeibilder.no/">Kartverket, NIBIO og Statens vegvesen</a>',
};

export const kartverketBaseMap = {
  url: `https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}`,
  attribution: '&copy; <a href="href="http://www.kartverket.no">Kartverket</a>',
};

export const getIsKartverketTilgjengelig = async () => {
  try {
    const url =
      'https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/0/0/0';
    await fetch(url, {
      method: 'GET',
      headers: { 'Cache-Control': 'no-cache' },
    });
    return true;
  } catch (error) {
    return false;
  }
};
