import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';
import Translate from '../../../Locale/Translate';
import {
  errorSelector,
  warningSelector,
  resetToast,
  successSelector,
} from '../../../state/toast';

export function Toast({ error, warning, success, toastDismiss }) {
  if (success) {
    setTimeout(toastDismiss, 3500);
    return (
      <Alert
        className="toast-top-right"
        color="success"
        isOpen={!!success}
        toggle={toastDismiss}
      >
        <Translate
          {...success}
          defaultMessage={success.defaultMessage || 'Handlingen var vellykket'}
        />
      </Alert>
    );
  }
  if (warning) {
    setTimeout(toastDismiss, 6000);
    return (
      <Alert
        className="toast-top-right"
        color="warning"
        isOpen={!!warning}
        toggle={toastDismiss}
      >
        <Translate {...warning} />
      </Alert>
    );
  }

  let messageToUser = '';
  if (error) {
    setTimeout(toastDismiss, 6000);
    messageToUser = error.message;
    if (error.message === 'Network Error') {
      messageToUser = (
        <Translate
          id="toast.errorNetwork"
          defaultMessage="Klarte ikke å opprette kontakt med drift-tjenesten"
        />
      );
    }

    if (error.response) {
      const { status, data, statusText } = error.response;
      switch (status) {
        case 401:
          if (statusText === 'Unauthorized') {
            messageToUser = (
              <Translate
                id="toast.error401"
                defaultMessage="Ugyldig token, logg inn på nytt."
              />
            );
          }
          break;
        case 404:
          if (data === 'token er ugyldig') {
            messageToUser = (
              <Translate
                id="toast.error404"
                defaultMessage="Ugyldig token, logg inn på nytt."
              />
            );
          }
          break;
        case 400:
          messageToUser = (
            <Translate
              id="toast.error400"
              defaultMessage="Kall på {url} gikk galt"
              values={{
                url: error.config.url,
                errorMessage: error.response.data.errorMessage,
              }}
            />
          );
          break;
        case 403:
          messageToUser = (
            <Translate
              id="toast.error500"
              defaultMessage="Kall på {url} gikk galt: Din bruker har ikke tilgang"
              values={{
                url: error.config.url,
              }}
            />
          );
          break;
        case 500:
          messageToUser = (
            <Translate
              id="toast.error500"
              defaultMessage="Kall på {url} gikk galt på serveren"
              values={{
                url: error.config.url,
                errorMessage: error.response.data.errorMessage,
              }}
            />
          );
          break;
        default:
          messageToUser = (
            <Translate
              id="toast.errorDefault"
              defaultMessage="Kall på {url} gikk galt på serveren"
              values={{
                url: error.config.url,
                errorMessage: error.response.data.errorMessage,
              }}
            />
          );
      }
    }
  }

  return (
    <Alert
      color="danger"
      className="toast-top-right"
      isOpen={!!error}
      toggle={toastDismiss}
    >
      {messageToUser}
    </Alert>
  );
}

Toast.propTypes = {
  error: PropTypes.shape({}),
  warning: PropTypes.shape({}),
  success: PropTypes.shape({}),
  toastDismiss: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  error: null,
  warning: null,
  success: null,
};

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  warning: warningSelector(state),
  success: successSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  toastDismiss: () => dispatch(resetToast()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <>
    {(props.success || props.warning || props.error) && (
      <CSSTransition classNames="fade" timeout={300}>
        <Toast {...props} />
      </CSSTransition>
    )}
  </>
));
